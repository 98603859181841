import { ChecklistaListDTO } from 'generated-models/anstallningsportalen';
import { FC } from 'react';

interface IAntalKvarvarandeAktiviteter {
  readonly checklistaItems: ChecklistaListDTO[];
}

export const AntalKvarvarandeAktiviteter: FC<IAntalKvarvarandeAktiviteter> = ({ checklistaItems }) => (
  <p className="flex justify-end pr-6 w-full fb-paragraph-lvl-2" style={{ color: 'inherit' }}>
    {checklistaItems.filter(i => !i.StatusVO || !i.StatusVO.AerendeAktivitetStatus).length} kvar
  </p>
);
