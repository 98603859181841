import { FbFormCheckbox } from '@decernointernal/fb-interna-komponenter';
import { FC } from 'react';
import ISkickatTillHRSystemetState from './ISkickatTillHRSystemetState';

interface IFilterSkickatTillHRSystemet {
  state: ISkickatTillHRSystemetState;
  setState: (value: React.SetStateAction<ISkickatTillHRSystemetState>) => void;
  idPrefix: string;
}

export const FilterSkickatTillHRSystemet: FC<IFilterSkickatTillHRSystemet> = ({ idPrefix, state, setState }) => {
  return (
    <>
      <FbFormCheckbox
        id={idPrefix + 'Ja'}
        label="Skickade till HR-systemet"
        defaultChecked={state.skickat}
        onChange={(_, checked) => {
          setState(prevState => {
            return { ...prevState, skickat: checked };
          });
        }}
        className="mr-4"
      />
      <FbFormCheckbox
        id={idPrefix + 'Nej'}
        label="Ej skickade till HR-systemet"
        defaultChecked={state.ejSkickat}
        onChange={(_, checked) => {
          setState(prevState => {
            return { ...prevState, ejSkickat: checked };
          });
        }}
      />
    </>
  );
};
