import { FbAlert, FbCollapsible, FbSpinner } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import aerendemallTypEnum from 'domain/aerendemallTyp';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { aerendeQryAction, selectAerendeQry } from '../qryAerende';
import IInformationProps, { IInformationSektionProps } from './IInformationProps';
import { KontaktuppgifterArbete, KontaktuppgifterPrivat, Medarbetartexter, Personuppgifter } from './sektioner/';
import { Instruktion } from './sektioner/Instruktion';
import { Om } from './sektioner/Om';
import { Anstaellning } from './sektioner/aerendeMallTyper/Anstaellning';

interface ISektion {
  rubrik: string;
  element: JSX.Element;
}

export const Information: FC<IInformationProps> = ({ aerendePK }) => {
  const dispatch = useAppDispatch();
  const aerende = useSelector(selectAerendeQry(aerendePK));

  useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(aerende) || RemoteDataFunctions.isStale(aerende)) {
      dispatch(aerendeQryAction(aerendePK));
    }
  }, [aerende, aerendePK, dispatch]);

  if (!RemoteDataFunctions.hasData(aerende)) {
    return (
      <div className="w-full p-8 text-center">
        <FbSpinner size="large" />
      </div>
    );
  }

  const getSektioner = () => {
    const aerendeMallTyp = aerende.data.AerendemallTypVO;
    const sektionProps: IInformationSektionProps = {
      aerendePK,
      aerendemallTypVO: aerendeMallTyp,
    };
    const sektionerElement: ISektion[] = [];
    sektionerElement.push({ rubrik: 'Instruktion', element: <Instruktion aerendemallTyp={aerendeMallTyp} /> });
    sektionerElement.push({ rubrik: 'Personuppgifter', element: <Personuppgifter {...sektionProps} /> });
    sektionerElement.push({ rubrik: 'Anställning', element: <Anstaellning {...sektionProps} /> });

    if (aerendeMallTyp.AerendemallTyp === aerendemallTypEnum.NY) {
      sektionerElement.push({
        rubrik: 'Kontaktuppgifter arbete',
        element: <KontaktuppgifterArbete {...sektionProps} />,
      });
      sektionerElement.push({
        rubrik: 'Kontaktuppgifter privat',
        element: <KontaktuppgifterPrivat {...sektionProps} />,
      });
      sektionerElement.push({ rubrik: 'På webben', element: <Medarbetartexter {...sektionProps} /> });
    }

    return sektionerElement;
  };

  return (
    <div className="overflow-y-scroll h-[calc(100vh-160px)]">
      {RemoteDataFunctions.hasData(aerende) && (
        <div data-testid="information-test-id">
          {aerende.data.OeverfoerdTillMA ? (
            <div className="m-4">
              <FbAlert type="info">
                <span className="text-xs-increased text-fb-color-typography">
                  Informationen från detta ärende har skickats till HR-systemet och kan därför inte längre ändras här.
                  Vänligen kontakta <a href="mailto:hrsupport@fastighetsbyran.se">HR-support</a> vid behov av ändringar.
                </span>
              </FbAlert>
            </div>
          ) : (
            getSektioner().map((sektion, index) => (
              <div key={index} className="px-4 pt-4 last:pb-4">
                <FbCollapsible headingTitle={sektion.rubrik} isDefaultCollapsed={false} isCollapsible={false}>
                  {sektion.element}
                </FbCollapsible>
              </div>
            ))
          )}
          <div className="px-4 pt-4 last:pb-4">
            <FbCollapsible headingTitle={'Om ärendet'} isDefaultCollapsed={false} isCollapsible={false}>
              <Om aerendePK={aerendePK} aerendemallTypVO={aerende.data.AerendemallTypVO} />
            </FbCollapsible>
          </div>
        </div>
      )}
    </div>
  );
};

export default Information;
