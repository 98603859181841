/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AerendeAktivitetStatusPK
 */
export interface AerendeAktivitetStatusPK {
  /**
   *
   * @type {number}
   * @memberof AerendeAktivitetStatusPK
   */
  AerendeId?: number | null;
  /**
   *
   * @type {number}
   * @memberof AerendeAktivitetStatusPK
   */
  AerendeAktivitetsMallId?: number | null;
}

export function AerendeAktivitetStatusPKFromJSON(json: any): AerendeAktivitetStatusPK {
  return AerendeAktivitetStatusPKFromJSONTyped(json, false);
}

export function AerendeAktivitetStatusPKFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AerendeAktivitetStatusPK {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendeId: !exists(json, 'AerendeId') ? undefined : json['AerendeId'],
    AerendeAktivitetsMallId: !exists(json, 'AerendeAktivitetsMallId') ? undefined : json['AerendeAktivitetsMallId'],
  };
}

export function AerendeAktivitetStatusPKToJSON(value?: AerendeAktivitetStatusPK | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendeId: value.AerendeId,
    AerendeAktivitetsMallId: value.AerendeAktivitetsMallId,
  };
}
