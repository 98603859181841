import { FbDatepicker } from '@decernointernal/fb-interna-komponenter';
import { FC } from 'react';
import FormInputWrapper from '../aerendeMallTyper/FormInputWrapper';
import { NyttAerendeContext } from '../context/NyttAerendeContext';
import { Header } from './Header';

export const PlaneradFoerstaArbetsdag: FC = () => {
  return (
    <NyttAerendeContext.Consumer>
      {context =>
        context && (
          <>
            <Header rubrik="Planerad första arbetsdag" className="mt-2" />
            <FormInputWrapper className="-mt-5">
              <FbDatepicker
                id="planeradFoerstaArbetsdag"
                label="Datum"
                startDate={context.nyttAerendeState.planeradFoerstaArbetsdag}
                validationFailedTooltip="åååå-mm-dd"
                required={true}
                onChange={date => {
                  context.setNyttAerendeState(prevState => {
                    return { ...prevState, planeradFoerstaArbetsdag: date ?? undefined };
                  });
                }}
                onInstantChange={(_, isValid: boolean) => {
                  if (!isValid) {
                    context.setNyttAerendeState(prevState => {
                      return { ...prevState, planeradFoerstaArbetsdag: undefined };
                    });
                  }
                }}
              />
            </FormInputWrapper>
          </>
        )
      }
    </NyttAerendeContext.Consumer>
  );
};
