import { FbHtmlText } from '@decernointernal/fb-interna-komponenter';

interface IAktivitetInnehaallProps {
  readonly done: boolean;
  readonly innehaall: string;
}

export const AktivitetInnehaall: React.FC<IAktivitetInnehaallProps> = ({ done, innehaall }) => {
  return (
    <>
      <FbHtmlText className={`max-w-xl text-sm ${done ? 'text-fb-grey-dark-2' : ''}`} text={innehaall} />
    </>
  );
};
