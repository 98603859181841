import {
  dateToAAAAMMDD,
  FbButton,
  FbCollapsible,
  FbSpinner,
  FbTable,
  FbTableCell,
  FbTableColDef,
  FbTableRow,
} from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import aerendemallTypEnum from 'domain/aerendemallTyp';
import { AerendeListDTO } from 'generated-models/anstallningsportalen/models/AerendeListDTO';
import { AerendePK } from 'generated-models/anstallningsportalen/models/AerendePK';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { aerendeInformationRoute } from 'store/location';
import { useAppDispatch } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { FilterSkickatTillHRSystemet } from './FilterSkickatTillHRSystemet';
import ISkickatTillHRSystemetState from './ISkickatTillHRSystemetState';
import { aerendeListQryAction, selectAerendeListQry } from './qryAerendeList';
import SkapaNyttAerende from './skapaNyttAerende/SkapaNyttAerende';

interface AerendeListRow extends FbTableRow {
  identifier: AerendePK;
  colList: {
    namn: FbTableCell;
    antalKvar: FbTableCell;
    roll: FbTableCell;
    kontor: FbTableCell;
    skapatAv: FbTableCell;
    skapatDen: FbTableCell;
    skickatTillHRSystemetDatum: FbTableCell;
    dagPaaKontoret: FbTableCell;
  };
}

const colDefList: FbTableColDef[] = [
  { field: 'namn', headerName: 'Namn', cssClass: 'w-5/12' },
  {
    field: 'antalKvar',
    headerName: 'Status uppgifter',
    cssClass: 'w-2/12',
    sortId: 1,
    sortFunction: (a: FbTableRow, b: FbTableRow) => {
      return a.colList.antalKvar.text > b.colList.antalKvar.text ? 1 : -1;
    },
    displayFunction: (a: any) => {
      return a === 0 ? 'Klar' : a + ' kvar';
    },
  },
  { field: 'roll', headerName: 'Roll', cssClass: 'w-3/12' },
  { field: 'kontor', headerName: 'Kontor', cssClass: 'w-3/12' },
  { field: 'skapatAv', headerName: 'Skapat av', cssClass: 'w-5/12' },
  {
    field: 'skapatDen',
    headerName: 'Skapat den',
    cssClass: 'w-2/12',
    sortId: 3,
    sortFunction: (a: FbTableRow, b: FbTableRow) => {
      return new Date(a.colList.skapatDen.text) > new Date(b.colList.skapatDen.text) ? 1 : -1;
    },
    displayFunction: (a: any) => {
      return a ? dateToAAAAMMDD(new Date(a)) : '';
    },
  },
  {
    field: 'skickatTillHRSystemetDatum',
    headerName: 'Skickat till HR-systemet',
    cssClass: 'w-3/12',
    sortId: 4,
    sortFunction: (a: FbTableRow, b: FbTableRow) => {
      return !a.colList.skickatTillHRSystemetDatum.text && !b.colList.skickatTillHRSystemetDatum.text
        ? 0
        : !b.colList.skickatTillHRSystemetDatum.text ||
          new Date(a.colList.skickatTillHRSystemetDatum.text) > new Date(b.colList.skickatTillHRSystemetDatum.text)
        ? 1
        : -1;
    },
    displayFunction: (a: any) => {
      return a ? dateToAAAAMMDD(new Date(a)) : '';
    },
  },
];

const nyaAerendenColDefList: FbTableColDef[] = colDefList.concat({
  field: 'dagPaaKontoret',
  headerName: 'Första dag på kontoret',
  cssClass: 'w-2/12',
  sortId: 2,
  sortFunction: (a: FbTableRow, b: FbTableRow) => {
    return new Date(a.colList.dagPaaKontoret.text) > new Date(b.colList.dagPaaKontoret.text) ? 1 : -1;
  },
  displayFunction: (a: any) => {
    return a ? dateToAAAAMMDD(new Date(a)) : '';
  },
});

const avslutAerendenColDefList: FbTableColDef[] = colDefList.concat({
  field: 'dagPaaKontoret',
  headerName: 'Sista dag på kontoret',
  cssClass: 'w-2/12',
  sortId: 2,
  sortFunction: (a: FbTableRow, b: FbTableRow) => {
    return new Date(a.colList.dagPaaKontoret.text) > new Date(b.colList.dagPaaKontoret.text) ? 1 : -1;
  },
  displayFunction: (a: any) => {
    return a ? dateToAAAAMMDD(new Date(a)) : '';
  },
});

const mapListRow = (
  aerendeList: AerendeListDTO[],
  aerendemallTyp: aerendemallTypEnum,
  skickatTillHRSystemet: ISkickatTillHRSystemetState
): AerendeListRow[] | undefined => {
  const rows = aerendeList
    .filter(a => a.AerendemallTypVO.AerendemallTyp === aerendemallTyp)
    .filter(
      a =>
        (a.SkickatTillHRSystemetDatum && skickatTillHRSystemet.skickat) ||
        (!a.SkickatTillHRSystemetDatum && skickatTillHRSystemet.ejSkickat)
    )
    .map(a => ({
      identifier: a.AerendePK,
      colList: {
        namn: { text: a.Namn },
        antalKvar: { text: a.AntalAktiviteterKvar },
        roll: { text: a.RollNamn ?? '' },
        kontor: { text: a.Kontorsnamn },
        dagPaaKontoret: {
          text: a.Startdatum ? a.Startdatum.toString() : a.Slutdatum ? a.Slutdatum.toString() : '',
        },
        skapatAv: { text: a.BestaellareMejladress },
        skapatDen: { text: a.Skapatdatum.toString() },
        skickatTillHRSystemetDatum: {
          text: a.SkickatTillHRSystemetDatum ? a.SkickatTillHRSystemetDatum.toString() : '',
        },
      },
    }));

  return rows && rows.length > 0 ? rows : undefined;
};

const ingaAerenden = () => <p className="fb-paragraph-lvl-2">Inga pågående ärenden</p>;

export const AerendeList: React.FC = () => {
  const aerendeList = useSelector(selectAerendeListQry());
  const dispatch = useAppDispatch();

  const [skapaNyttAerendeModalState, setSkapaNyttAerendeModalState] = useState<boolean>(false);
  const [filterNyaMedarbetareSkickatTillHRSystemetState, setFilterNyaMedarbetareSkickatTillHRSystemetState] =
    useState<ISkickatTillHRSystemetState>({ skickat: true, ejSkickat: true });
  const [filterAvslutMedarbetareSkickatTillHRSystemetState, setFilterAvslutMedarbetareSkickatTillHRSystemetState] =
    useState<ISkickatTillHRSystemetState>({ skickat: true, ejSkickat: true });

  const nyaAerenden =
    RemoteDataFunctions.hasData(aerendeList) && aerendeList.data.length > 0
      ? mapListRow(aerendeList.data, aerendemallTypEnum.NY, filterNyaMedarbetareSkickatTillHRSystemetState)
      : undefined;

  const avslutAerenden =
    RemoteDataFunctions.hasData(aerendeList) && aerendeList.data.length > 0
      ? mapListRow(aerendeList.data, aerendemallTypEnum.AVSLUT, filterAvslutMedarbetareSkickatTillHRSystemetState)
      : undefined;

  // This use effect handles the first fetch
  // This use effect handles invalidation from backend based on integration events
  useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(aerendeList) || RemoteDataFunctions.isStale(aerendeList)) {
      dispatch(aerendeListQryAction());
    }
  }, [aerendeList, dispatch]);

  const postClientComponentError = (componentName?: string, message?: string, componentProps?: string) => {
    return new ApiClient().KibanaApi.postClientComponentError({
      clientComponentErrorMessage: {
        ComponentName: componentName ?? '',
        Message: message ?? '',
        ComponentProps: componentProps,
      },
    });
  };

  return (
    <div className="py-4">
      <FbCollapsible headingTitle="Mina pågående ärenden" isCollapsible={false}>
        <FbButton
          type="primary"
          text="Skapa nytt ärende"
          className="mb-4"
          onClick={() => setSkapaNyttAerendeModalState(true)}
        />
        {skapaNyttAerendeModalState && <SkapaNyttAerende onCancel={() => setSkapaNyttAerendeModalState(false)} />}
        <div className="pb-4">
          <div className="flex">
            <h3 className="pb-4">Nya medarbetare</h3>
            <div className="ml-8">
              <FilterSkickatTillHRSystemet
                idPrefix="filterNyaMedarbetareSkickatTillHRSystemet"
                state={filterNyaMedarbetareSkickatTillHRSystemetState}
                setState={setFilterNyaMedarbetareSkickatTillHRSystemetState}
              />
            </div>
          </div>
          {RemoteDataFunctions.hasData(aerendeList) && nyaAerenden && (
            <FbTable
              tableType="fixed"
              colDefList={nyaAerendenColDefList}
              rowList={nyaAerenden}
              onRowSelected={(selectedRow: FbTableRow) => dispatch(aerendeInformationRoute(selectedRow.identifier))}
              isSortable={true}
              postClientComponentError={(componentName, message, componentProps) =>
                postClientComponentError(componentName, message, componentProps)
              }
              className="w-full 2xl:w-2/3"
              maxRows={20}
              defaultSortId={3}
              defaultSortDescending={true}
            />
          )}
          {RemoteDataFunctions.hasData(aerendeList) && !nyaAerenden && ingaAerenden()}
          {RemoteDataFunctions.isLoading(aerendeList) && <FbSpinner size="medium" />}
        </div>

        <div className="pb-4">
          <div className="flex">
            <h3 className="pb-4">Medarbetare som slutar</h3>
            <div className="ml-8">
              <FilterSkickatTillHRSystemet
                idPrefix="filterAvslutMedarbetareSkickatTillHRSystemet"
                state={filterAvslutMedarbetareSkickatTillHRSystemetState}
                setState={setFilterAvslutMedarbetareSkickatTillHRSystemetState}
              />
            </div>
          </div>
          {RemoteDataFunctions.hasData(aerendeList) && avslutAerenden && (
            <FbTable
              tableType="fixed"
              colDefList={avslutAerendenColDefList}
              rowList={avslutAerenden}
              onRowSelected={(selectedRow: FbTableRow) => dispatch(aerendeInformationRoute(selectedRow.identifier))}
              isSortable={true}
              postClientComponentError={(componentName, message, componentProps) =>
                postClientComponentError(componentName, message, componentProps)
              }
              className="w-full 2xl:w-2/3"
              maxRows={20}
              defaultSortId={3}
              defaultSortDescending={true}
            />
          )}
          {RemoteDataFunctions.hasData(aerendeList) && !avslutAerenden && ingaAerenden()}
          {RemoteDataFunctions.isLoading(aerendeList) && <FbSpinner size="medium" />}
        </div>
      </FbCollapsible>
    </div>
  );
};
