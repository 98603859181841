/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  SaveAerendeAktivitetStatusServiceCmd,
  SaveAerendeAktivitetStatusServiceCmdFromJSON,
  SaveAerendeAktivitetStatusServiceCmdToJSON,
  SdIntegrationEvent,
  SdIntegrationEventFromJSON,
  SdIntegrationEventToJSON,
  UtfoerAktivitetServiceCmd,
  UtfoerAktivitetServiceCmdFromJSON,
  UtfoerAktivitetServiceCmdToJSON,
} from '../models';

export interface SaveAerendeAktivitetStatusRequest {
  saveAerendeAktivitetStatusServiceCmd?: SaveAerendeAktivitetStatusServiceCmd;
}

export interface UtfoerAktivitetRequest {
  utfoerAktivitetServiceCmd?: UtfoerAktivitetServiceCmd;
}

/**
 *
 */
export class AerendeAktivitetCmdApi extends runtime.BaseAPI {
  /**
   */
  async saveAerendeAktivitetStatusRaw(
    requestParameters: SaveAerendeAktivitetStatusRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/AerendeAktivitetCmd/SaveAerendeAktivitetStatus`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SaveAerendeAktivitetStatusServiceCmdToJSON(requestParameters.saveAerendeAktivitetStatusServiceCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async saveAerendeAktivitetStatus(
    requestParameters: SaveAerendeAktivitetStatusRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.saveAerendeAktivitetStatusRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async utfoerAktivitetRaw(
    requestParameters: UtfoerAktivitetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/AerendeAktivitetCmd/UtfoerAktivitet`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UtfoerAktivitetServiceCmdToJSON(requestParameters.utfoerAktivitetServiceCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async utfoerAktivitet(
    requestParameters: UtfoerAktivitetRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.utfoerAktivitetRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
