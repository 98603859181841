import App from 'App';
import { RenderWrapper } from 'RenderWrapper';
import { publicClientApplication } from 'azureAD/msalConfig';
import { NavigateAway } from 'components/aerende/navigateAway/NavigateAway';
import { FbOverlay } from 'components/overlay/FbOverlay';

import { createRoot } from 'react-dom/client';
import './tailwind.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <RenderWrapper>
    <FbOverlay />
    <NavigateAway />
    <App msalInstance={publicClientApplication} />
  </RenderWrapper>
);
