import {
  FbButton,
  FbDatepicker,
  FbFormDropdown,
  FbFormDropdownValue,
  FbFormInput,
} from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectAerendeQry } from 'components/aerende/qryAerende';
import { selectUserQry } from 'components/user/qryUser';
import { aerendeMedarbetareKontorPropTypEnum } from 'domain/aerendeMedarbetareKontorPropTypDomain';
import { CreateAerendeMedarbetareKontorCmd, KontorDTO } from 'generated-models/anstallningsportalen';
import { AerendeMedarbetareKontorPK } from 'generated-models/anstallningsportalen/models/AerendeMedarbetareKontorPK';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import { IInformationSektionProps } from '../../IInformationProps';
import { maeklarkedjaListQryAction, selectMaeklarkedjalistQry } from '../../qryMaeklarkedja';
import {
  createAerendeMedarbetareKontorCmdAction,
  removeAerendeMedarbetareKontorCmdAction,
} from '../cmdCreateAerendeMedarbetareKontor';
import { deleteAerendeMedarbetareKontorCmdAction } from '../cmdDeleteAerendeMedarbetareKontor';
import { sektionHoc } from '../sektionHoc';

export const NY: FC<IInformationSektionProps> = ({ aerendePK }) => {
  const aerende = useSelector(selectAerendeQry(aerendePK));
  const maeklarkedjaList = useSelector(selectMaeklarkedjalistQry());
  const user = useSelector(selectUserQry());
  const dispatch = useAppDispatch();

  const [valdaKontor, setValdaKontor] = useState<KontorDTO[]>(
    RemoteDataFunctions.hasData(aerende)
      ? aerende.data.AerendeMedarbetareKontorList.map(k => {
          return { KontorId: k.KontorId, KontorNamn: k.Kontorsnamn };
        })
      : []
  );
  const [laeggTillKontor, setLaeggTillKontor] = useState(false);

  const listInnehaall = useMemo((): FbFormDropdownValue[] => {
    if (RemoteDataFunctions.isNotAsked(maeklarkedjaList) || RemoteDataFunctions.isStale(maeklarkedjaList)) {
      dispatch(maeklarkedjaListQryAction());
    }
    let listInnehaall: FbFormDropdownValue[] = [
      { id: 11, value: 'Vet ej' },
      { id: 12, value: 'Högskola / universitet' },
      { id: 13, value: 'Övrigt' },
    ];

    RemoteDataFunctions.hasData(maeklarkedjaList) &&
      maeklarkedjaList.data.forEach(k => {
        listInnehaall.push({ id: k.MaeklarKedjaId!, value: k.MaeklarKedjaNamn! });
      });

    return listInnehaall;
  }, [maeklarkedjaList, dispatch]);

  const getTillgaengligaKontor = useMemo(() => {
    let tillgaengligaKontor: KontorDTO[] = [];

    if (RemoteDataFunctions.hasData(user) && RemoteDataFunctions.hasData(aerende)) {
      user.data.Kontor.reduce((previousValue, currentValue) => {
        if (
          !valdaKontor.find(vk => vk.KontorId === currentValue.KontorId) &&
          currentValue.KontorId !== aerende.data.KontorId
        ) {
          previousValue.push(currentValue);
        }
        return previousValue;
      }, tillgaengligaKontor);
    }

    return tillgaengligaKontor;
  }, [valdaKontor, user, aerende]);

  const getLaeggTillKontorDropdownValues = () => {
    return getTillgaengligaKontor.map(tk => {
      return { id: tk.KontorId ?? -1, value: tk.KontorNamn ?? '' };
    });
  };

  const onLaeggTillKontorVald = (valtKontor: FbFormDropdownValue | undefined) => {
    if (valtKontor && valtKontor.id) {
      const cmd: CreateAerendeMedarbetareKontorCmd = {
        PrimaryKey: VOCreate<AerendeMedarbetareKontorPK>({
          AerendeId: aerendePK.AerendeId,
          KontorId: valtKontor.id as number,
        }),
      };
      dispatch(createAerendeMedarbetareKontorCmdAction(cmd));

      dispatch(removeAerendeMedarbetareKontorCmdAction(cmd));

      setValdaKontor(previousValue => {
        return [...previousValue, { KontorId: valtKontor.id as number, KontorNamn: valtKontor.value }];
      });
      setLaeggTillKontor(false);
    }
  };

  const taBortValtKontor = (kontorId: number | undefined) => {
    if (kontorId) {
      const initialKontor: KontorDTO[] = [];
      setValdaKontor(previousState => {
        previousState.reduce((previousValue, currentValue) => {
          if (currentValue.KontorId !== kontorId) {
            previousValue.push(currentValue);
          }
          return previousValue;
        }, initialKontor);
        return [...initialKontor];
      });

      dispatch(
        deleteAerendeMedarbetareKontorCmdAction({
          PrimaryKey: VOCreate<AerendeMedarbetareKontorPK>({ AerendeId: aerendePK.AerendeId, KontorId: kontorId }),
        })
      );
    }
  };

  function GetTooltipText(getTillgaengligaKontor: KontorDTO[]): string | undefined {
    if (getTillgaengligaKontor.length === 0) {
      return 'Du är inte behörig att lägga till andra kontor. För att utöka din behörighet, vänligen kontakta HR-support.';
    } else {
      return '';
    }
  }

  return (
    <>
      {RemoteDataFunctions.hasData(aerende) && RemoteDataFunctions.hasData(user) && (
        <>
          <div className="w-96">
            <div className="pb-5">
              <FbFormInput id="roll" label="Roll" disabled={true} defaultValue={aerende.data.RollNamn} />
            </div>
            <div className="pb-4">
              <FbFormInput
                id="huvudarbetsplats"
                label="Kontor (Huvudarbetsplats)"
                disabled={true}
                defaultValue={aerende.data.Kontorsnamn}
              />
            </div>
            {valdaKontor.map(vk => (
              <div className="pb-4 flex items-baseline -mr-6" key={vk.KontorId}>
                <FbFormInput
                  id={vk.KontorId?.toString() ?? ''}
                  label="Kontor"
                  defaultValue={vk.KontorNamn ?? ''}
                  disabled={true}
                  className="grow"
                />
                <button
                  className="ml-2 border rounded-sm border-fb-color-secondary-border w-6 h-6"
                  onClick={() => taBortValtKontor(vk.KontorId)}
                >
                  <FontAwesomeIcon size="xs" icon={faTrashCan} />
                </button>
              </div>
            ))}
            {laeggTillKontor && (
              <div className="pb-4">
                <FbFormDropdown
                  id="laeggTillKontor"
                  label="Kontor"
                  values={getLaeggTillKontorDropdownValues()}
                  onChange={onLaeggTillKontorVald}
                  expandScroll={true}
                  useSelfContainedPositioning={true}
                />
              </div>
            )}
            <div>
              <FbButton
                type="secondary"
                text="Lägg till arbetsplats"
                disabled={getTillgaengligaKontor.length === 0}
                tooltipText={GetTooltipText(getTillgaengligaKontor)}
                tooltipMaxWidth={11.875 * 16}
                tooltipPosition="right"
                onClick={() => setLaeggTillKontor(true)}
              />
            </div>
          </div>
          <div className="pb-4 w-96">
            {sektionHoc(FbDatepicker, { aerendeId: aerendePK.AerendeId })({
              id: aerendeMedarbetareKontorPropTypEnum.STARTDATUM_ANSTAELLNING,
              label: 'Startdatum anställning',
              required: true,
              startDate: aerende.data.Startdatum ?? undefined,
              validationFailedTooltip: 'Felaktigt format. Ange datum på formatet 2025-12-24',
            })}
          </div>
          <div className="pb-4 w-96">
            {sektionHoc(FbFormDropdown, { aerendeId: aerendePK.AerendeId })({
              id: aerendeMedarbetareKontorPropTypEnum.REKRYTERADFRAAN,
              label: 'Varifrån har personen rekryterats?',
              values: listInnehaall,
              defaultId: aerende.data.RekryteradFraan
                ? listInnehaall.find(x => x.id.toString() === aerende.data.RekryteradFraan)?.id
                : listInnehaall[0].id,
            })}
          </div>
        </>
      )}
    </>
  );
};

export default NY;
