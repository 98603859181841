/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AerendeMedarbetareKontorListDTO,
  AerendeMedarbetareKontorListDTOFromJSON,
  AerendeMedarbetareKontorListDTOFromJSONTyped,
  AerendeMedarbetareKontorListDTOToJSON,
} from './AerendeMedarbetareKontorListDTO';
import { AerendePK, AerendePKFromJSON, AerendePKFromJSONTyped, AerendePKToJSON } from './AerendePK';
import {
  AerendeStatusVO,
  AerendeStatusVOFromJSON,
  AerendeStatusVOFromJSONTyped,
  AerendeStatusVOToJSON,
} from './AerendeStatusVO';
import {
  AerendemallTypVO,
  AerendemallTypVOFromJSON,
  AerendemallTypVOFromJSONTyped,
  AerendemallTypVOToJSON,
} from './AerendemallTypVO';
import { AerendemallVO, AerendemallVOFromJSON, AerendemallVOFromJSONTyped, AerendemallVOToJSON } from './AerendemallVO';
import {
  PersonnummerVO,
  PersonnummerVOFromJSON,
  PersonnummerVOFromJSONTyped,
  PersonnummerVOToJSON,
} from './PersonnummerVO';

/**
 *
 * @export
 * @interface AerendeDTO
 */
export interface AerendeDTO {
  /**
   *
   * @type {AerendePK}
   * @memberof AerendeDTO
   */
  AerendePK: AerendePK;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  Foernamn: string;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  Efternamn: string;
  /**
   *
   * @type {PersonnummerVO}
   * @memberof AerendeDTO
   */
  PersonnummerVO: PersonnummerVO;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  RollNamn: string;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  Kontorsnamn: string;
  /**
   *
   * @type {number}
   * @memberof AerendeDTO
   */
  KontorId: number;
  /**
   *
   * @type {Date}
   * @memberof AerendeDTO
   */
  Startdatum?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof AerendeDTO
   */
  Slutdatum?: Date | null;
  /**
   *
   * @type {AerendeStatusVO}
   * @memberof AerendeDTO
   */
  AerendeStatusVO: AerendeStatusVO;
  /**
   *
   * @type {AerendemallTypVO}
   * @memberof AerendeDTO
   */
  AerendemallTypVO: AerendemallTypVO;
  /**
   *
   * @type {AerendemallVO}
   * @memberof AerendeDTO
   */
  AerendemallVO: AerendemallVO;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  AerendemallNamn: string;
  /**
   *
   * @type {number}
   * @memberof AerendeDTO
   */
  AerendemallBostadsMarknadId: number;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  AerendemallRubrik: string;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  Titel?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  Mobiltelefonnummer?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AerendeDTO
   */
  MobiltelefonnummerAerExterntSynligt: boolean;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  Telefonnummer?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  MejlAdress?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  MobiltelefonnummerPrivat?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  MejladressPrivat?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  GatuAdress?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  Postnummer?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  Postort?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  PersonArbetsbeskrivning?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  Ingress?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  PersonligText?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AerendeDTO
   */
  VisaIntePaaKontoretsSida: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AerendeDTO
   */
  readonly OeverfoerdTillMA: boolean;
  /**
   *
   * @type {Date}
   * @memberof AerendeDTO
   */
  Skapatdatum: Date;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  BestaellareMejladress?: string | null;
  /**
   *
   * @type {Date}
   * @memberof AerendeDTO
   */
  SkickatTillHRSystemetDatum?: Date | null;
  /**
   *
   * @type {number}
   * @memberof AerendeDTO
   */
  NationalitetId: number;
  /**
   *
   * @type {Date}
   * @memberof AerendeDTO
   */
  Foedelsedatum?: Date | null;
  /**
   *
   * @type {Array<AerendeMedarbetareKontorListDTO>}
   * @memberof AerendeDTO
   */
  AerendeMedarbetareKontorList: Array<AerendeMedarbetareKontorListDTO>;
  /**
   *
   * @type {string}
   * @memberof AerendeDTO
   */
  RekryteradFraan: string;
}

export function AerendeDTOFromJSON(json: any): AerendeDTO {
  return AerendeDTOFromJSONTyped(json, false);
}

export function AerendeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AerendeDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendePK: AerendePKFromJSON(json['AerendePK']),
    Foernamn: json['Foernamn'],
    Efternamn: json['Efternamn'],
    PersonnummerVO: PersonnummerVOFromJSON(json['PersonnummerVO']),
    RollNamn: json['RollNamn'],
    Kontorsnamn: json['Kontorsnamn'],
    KontorId: json['KontorId'],
    Startdatum: !exists(json, 'Startdatum')
      ? undefined
      : json['Startdatum'] === null
      ? null
      : new Date(json['Startdatum']),
    Slutdatum: !exists(json, 'Slutdatum') ? undefined : json['Slutdatum'] === null ? null : new Date(json['Slutdatum']),
    AerendeStatusVO: AerendeStatusVOFromJSON(json['AerendeStatusVO']),
    AerendemallTypVO: AerendemallTypVOFromJSON(json['AerendemallTypVO']),
    AerendemallVO: AerendemallVOFromJSON(json['AerendemallVO']),
    AerendemallNamn: json['AerendemallNamn'],
    AerendemallBostadsMarknadId: json['AerendemallBostadsMarknadId'],
    AerendemallRubrik: json['AerendemallRubrik'],
    Titel: !exists(json, 'Titel') ? undefined : json['Titel'],
    Mobiltelefonnummer: !exists(json, 'Mobiltelefonnummer') ? undefined : json['Mobiltelefonnummer'],
    MobiltelefonnummerAerExterntSynligt: json['MobiltelefonnummerAerExterntSynligt'],
    Telefonnummer: !exists(json, 'Telefonnummer') ? undefined : json['Telefonnummer'],
    MejlAdress: !exists(json, 'MejlAdress') ? undefined : json['MejlAdress'],
    MobiltelefonnummerPrivat: !exists(json, 'MobiltelefonnummerPrivat') ? undefined : json['MobiltelefonnummerPrivat'],
    MejladressPrivat: !exists(json, 'MejladressPrivat') ? undefined : json['MejladressPrivat'],
    GatuAdress: !exists(json, 'GatuAdress') ? undefined : json['GatuAdress'],
    Postnummer: !exists(json, 'Postnummer') ? undefined : json['Postnummer'],
    Postort: !exists(json, 'Postort') ? undefined : json['Postort'],
    PersonArbetsbeskrivning: !exists(json, 'PersonArbetsbeskrivning') ? undefined : json['PersonArbetsbeskrivning'],
    Ingress: !exists(json, 'Ingress') ? undefined : json['Ingress'],
    PersonligText: !exists(json, 'PersonligText') ? undefined : json['PersonligText'],
    VisaIntePaaKontoretsSida: json['VisaIntePaaKontoretsSida'],
    OeverfoerdTillMA: json['OeverfoerdTillMA'],
    Skapatdatum: new Date(json['Skapatdatum']),
    BestaellareMejladress: !exists(json, 'BestaellareMejladress') ? undefined : json['BestaellareMejladress'],
    SkickatTillHRSystemetDatum: !exists(json, 'SkickatTillHRSystemetDatum')
      ? undefined
      : json['SkickatTillHRSystemetDatum'] === null
      ? null
      : new Date(json['SkickatTillHRSystemetDatum']),
    NationalitetId: json['NationalitetId'],
    Foedelsedatum: !exists(json, 'Foedelsedatum')
      ? undefined
      : json['Foedelsedatum'] === null
      ? null
      : new Date(json['Foedelsedatum']),
    AerendeMedarbetareKontorList: (json['AerendeMedarbetareKontorList'] as Array<any>).map(
      AerendeMedarbetareKontorListDTOFromJSON
    ),
    RekryteradFraan: json['RekryteradFraan'],
  };
}

export function AerendeDTOToJSON(value?: AerendeDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendePK: AerendePKToJSON(value.AerendePK),
    Foernamn: value.Foernamn,
    Efternamn: value.Efternamn,
    PersonnummerVO: PersonnummerVOToJSON(value.PersonnummerVO),
    RollNamn: value.RollNamn,
    Kontorsnamn: value.Kontorsnamn,
    KontorId: value.KontorId,
    Startdatum:
      value.Startdatum === undefined ? undefined : value.Startdatum === null ? null : value.Startdatum.toISOString(),
    Slutdatum:
      value.Slutdatum === undefined ? undefined : value.Slutdatum === null ? null : value.Slutdatum.toISOString(),
    AerendeStatusVO: AerendeStatusVOToJSON(value.AerendeStatusVO),
    AerendemallTypVO: AerendemallTypVOToJSON(value.AerendemallTypVO),
    AerendemallVO: AerendemallVOToJSON(value.AerendemallVO),
    AerendemallNamn: value.AerendemallNamn,
    AerendemallBostadsMarknadId: value.AerendemallBostadsMarknadId,
    AerendemallRubrik: value.AerendemallRubrik,
    Titel: value.Titel,
    Mobiltelefonnummer: value.Mobiltelefonnummer,
    MobiltelefonnummerAerExterntSynligt: value.MobiltelefonnummerAerExterntSynligt,
    Telefonnummer: value.Telefonnummer,
    MejlAdress: value.MejlAdress,
    MobiltelefonnummerPrivat: value.MobiltelefonnummerPrivat,
    MejladressPrivat: value.MejladressPrivat,
    GatuAdress: value.GatuAdress,
    Postnummer: value.Postnummer,
    Postort: value.Postort,
    PersonArbetsbeskrivning: value.PersonArbetsbeskrivning,
    Ingress: value.Ingress,
    PersonligText: value.PersonligText,
    VisaIntePaaKontoretsSida: value.VisaIntePaaKontoretsSida,
    Skapatdatum: value.Skapatdatum.toISOString(),
    BestaellareMejladress: value.BestaellareMejladress,
    SkickatTillHRSystemetDatum:
      value.SkickatTillHRSystemetDatum === undefined
        ? undefined
        : value.SkickatTillHRSystemetDatum === null
        ? null
        : value.SkickatTillHRSystemetDatum.toISOString(),
    NationalitetId: value.NationalitetId,
    Foedelsedatum:
      value.Foedelsedatum === undefined
        ? undefined
        : value.Foedelsedatum === null
        ? null
        : value.Foedelsedatum.toISOString(),
    AerendeMedarbetareKontorList: (value.AerendeMedarbetareKontorList as Array<any>).map(
      AerendeMedarbetareKontorListDTOToJSON
    ),
    RekryteradFraan: value.RekryteradFraan,
  };
}
