import React from 'react';

interface IAktivitetTitelProps {
  readonly done: boolean;
  readonly titel: string;
}

export const AktivitetTitel: React.FC<IAktivitetTitelProps> = ({ done, titel }) => {
  return (
    <>
      <h5 className={`${done ? 'text-fb-grey-dark-2' : ''}`}>{titel}</h5>
    </>
  );
};
