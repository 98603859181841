import { Aerende } from 'components/aerende/Aerende';
import { Home } from 'components/home/Home';
import { NotFound } from 'components/NotFound';
import React from 'react';
import { useSelector } from 'react-redux';
import { PageComponent, selectPage } from 'store/location';

export const SwitchPage: React.FC = () => {
  const page = useSelector(selectPage);

  switch (page) {
    case PageComponent.Home:
      return <Home />;
    case PageComponent.Aerende:
      return <Aerende />;
    case PageComponent.NotFound:
      return <NotFound />;
    default:
      return null;
  }
};
