import { createWebDataSingletonList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { MedarbetarListDTO } from 'generated-models/anstallningsportalen/models/MedarbetarListDTO';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
const ActionTypeMedarbetarList = '[medarbetare] Get medarbetarlist';

const medarbetarListQry = createWebDataSingletonList<typeof ActionTypeMedarbetarList, MedarbetarListDTO[]>(
  ActionTypeMedarbetarList,
  'medarbetarList'
);

const mapMedarbetarListQryState: MapState<WebDataListState<MedarbetarListDTO[]>> = rootState =>
  rootState.main.home.aerendeList.qryMedarbetarList;

export const selectMedarbetarListQry = () => (state: RootState) =>
  medarbetarListQry.get(mapMedarbetarListQryState(state));

export const medarbetarListQryAction = (): ThunkAction => async (dispatch, getState) => {
  const webData = medarbetarListQry.get(mapMedarbetarListQryState(getState()));
  if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
    return;
  }
  // Call api and handle result
  try {
    if (RemoteDataFunctions.hasData(webData)) {
      dispatch(medarbetarListQry.updating(webData.data));
    } else {
      dispatch(medarbetarListQry.loading());
    }

    const result = await new ApiClient().MedarbetarQryApi.getMedarbetarList({});
    dispatch(medarbetarListQry.success(result));
  } catch (error) {
    dispatch(medarbetarListQry.failure(await parseErrorResponse(error)));
  }
};

export const medarbetarListQryReducer = medarbetarListQry.reducer;
