import { CommandFunctions, createWebCommandList, WebCommandListState } from '@decernointernal/websd.shared';
import { AerendePK } from 'generated-models/anstallningsportalen';
import { UpdateAerendeMedarbetarePropCmd } from 'generated-models/anstallningsportalen/models/UpdateAerendeMedarbetarePropCmd';
import IUpdateAerendeMedarbetarePropCmdIntegrationEvent from 'models/IUpdateAerendeMedarbetarePropCmdIntegrationEvent';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import {
  createCustomIntegrationEvent,
  dispatchIntegrationEvents,
  IntegrationEventDataTypes,
  parseEventWithDTO,
} from 'utils/eventDispatcher';
const ActionType = '[Aerende] update AerendeMedarbetareProp';

interface IUpdateAerendeMedarbetarePropKey {
  AerendeId: number | null | undefined;
  PropTyp: string | null | undefined;
}

type UpdateAerendeMedarbetarePropCmdState = WebCommandListState<UpdateAerendeMedarbetarePropCmd>;

export const getKey = (
  aerendeId: number | null | undefined,
  propTyp: string | null | undefined
): IUpdateAerendeMedarbetarePropKey => {
  return { AerendeId: aerendeId, PropTyp: propTyp };
};

const updateAerendeMedarbetarePropCmd = createWebCommandList<typeof ActionType, UpdateAerendeMedarbetarePropCmd>(
  ActionType,
  k => getKey(k.PrimaryKey.AerendeId, k.PropVO.PropTyp)
);

const map: MapState<UpdateAerendeMedarbetarePropCmdState> = rootState =>
  rootState.main.aerende.aerendeMedarbetare.updateAerendeMedarbetareProp;

export const selectUpdateAerendeMedarbetarePropCmd =
  (primaryKey: IUpdateAerendeMedarbetarePropKey) => (state: RootState) =>
    updateAerendeMedarbetarePropCmd.get(map(state), primaryKey);

export const updateAerendeMedarbetarePropCmdAction =
  (cmd: UpdateAerendeMedarbetarePropCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = updateAerendeMedarbetarePropCmd.get(map(state), {
      AerendeId: cmd.PrimaryKey.AerendeId,
      PropTyp: cmd.PropVO.PropTyp,
    });
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(updateAerendeMedarbetarePropCmd.executing(cmd));

      const result = await new ApiClient().AerendeCmdApi.updateAerendeMedarbetareProp({
        updateAerendeMedarbetarePropCmd: cmd,
      });

      // Create a custom integration event that is based on the command propType and the updated aggregate.
      // We need the aggregate because it contains the correct datatype - propValue in the command is always a string.
      // For example a boolean is in the command sent as "true" or "false"
      const event = result.find(e => {
        return e.EventDataType === IntegrationEventDataTypes.AerendeMedarbetare ? e : undefined;
      });
      if (event && cmd.PropVO.PropTyp) {
        const parsedEvent = parseEventWithDTO<any, AerendePK>(event.EventDataType as IntegrationEventDataTypes, event);
        var savedPropValue = parsedEvent.eventData[cmd.PropVO.PropTyp];
        const prop: IUpdateAerendeMedarbetarePropCmdIntegrationEvent = {
          propTyp: cmd.PropVO.PropTyp,
          propValue: savedPropValue,
        };

        const customEvent = createCustomIntegrationEvent<IUpdateAerendeMedarbetarePropCmdIntegrationEvent>(
          IntegrationEventDataTypes.UpdateAerendeMedarbetareProp,
          prop,
          cmd.PrimaryKey,
          'AerendePK'
        );

        // Create custom integration event
        result.push(customEvent);
      }

      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(updateAerendeMedarbetarePropCmd.success(cmd));
      // Remove command from commandList
      dispatch(updateAerendeMedarbetarePropCmd.remove(cmd));
    } catch (err) {
      dispatch(updateAerendeMedarbetarePropCmd.failure(cmd, await parseErrorResponse(err)));
    }
  };

export const updateAerendeMedarbetarePropReducer = updateAerendeMedarbetarePropCmd.reducer;
