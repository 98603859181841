import { CommandFunctions, createWebCommandList, WebCommandListState } from '@decernointernal/websd.shared';
import { AerendeAktivitetStatusPK } from 'generated-models/anstallningsportalen';
import { UtfoerAktivitetServiceCmd } from 'generated-models/anstallningsportalen/models/UtfoerAktivitetServiceCmd';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';

const ActionType = '[Aerende] utfoer aktivitet';

type UtfoerAktivitetCmdState = WebCommandListState<UtfoerAktivitetServiceCmd>;

const utfoerAktivitetCmd = createWebCommandList<typeof ActionType, UtfoerAktivitetServiceCmd>(
  ActionType,
  k => k.AerendeAktivitetStatusPK
);

const map: MapState<UtfoerAktivitetCmdState> = rootState => rootState.main.aerende.aerendeaktivitet.utforAktivitet;

export const selectUtfoerAktivitetServiceCmd = (primaryKey: AerendeAktivitetStatusPK) => (state: RootState) =>
  utfoerAktivitetCmd.get(map(state), primaryKey);

export const resetErrorState =
  (primaryKey: AerendeAktivitetStatusPK): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = utfoerAktivitetCmd.get(map(state), primaryKey);

    if (CommandFunctions.isFailure(webCommand)) {
      dispatch(utfoerAktivitetCmd.remove(webCommand.data));
    }
  };

export const utfoerAktivitetAction =
  (cmd: UtfoerAktivitetServiceCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = utfoerAktivitetCmd.get(map(state), cmd.AerendeAktivitetStatusPK);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }

    // Call api and handle result
    try {
      // Set executing status
      dispatch(utfoerAktivitetCmd.executing(cmd));

      const result = await new ApiClient().AerendeAktivitetApi.utfoerAktivitet({
        utfoerAktivitetServiceCmd: cmd,
      });
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(utfoerAktivitetCmd.success(cmd));
      // Remove command from commandList
      dispatch(utfoerAktivitetCmd.remove(cmd));
    } catch (error) {
      dispatch(utfoerAktivitetCmd.failure(cmd, await parseErrorResponse(error)));
    }
  };

export const utfoerAktivitetCmdReducer = utfoerAktivitetCmd.reducer;
