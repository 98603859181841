import { isValidDate } from '@decernointernal/fb-interna-komponenter';
import {
  createWebDataList,
  RemoteDataFunctions,
  RemoteDataStatus,
  SdKeys,
  WebDataListState,
} from '@decernointernal/websd.shared';
import { AerendeDTO, AerendeMedarbetareKontorListDTO, AerendePK } from 'generated-models/anstallningsportalen/models';
import { Reducer } from 'react';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType, webDataSetStale } from 'utils/eventDispatcher';
import { VOCreate } from 'utils/ValueObjectHelpers';
const ActionType = '[aerende] Get aerende';

const aerendeQry = createWebDataList<typeof ActionType, AerendeDTO, AerendePK>(ActionType, x => x.AerendePK);

type AerendeQryState = WebDataListState<AerendeDTO>;

const mapAerendeQryState: MapState<AerendeQryState> = rootState => rootState.main.aerende.qryAerende;

export const selectAerendeQry = (aerendeId: AerendePK) => (state: RootState) => {
  return aerendeQry.get(mapAerendeQryState(state), aerendeId);
};
export const aerendeQryAction =
  (aerendeId: AerendePK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = aerendeQry.get(mapAerendeQryState(getState()), aerendeId);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(aerendeQry.updating(webData.data));
      } else {
        dispatch(aerendeQry.loading(aerendeId));
      }

      const result = await new ApiClient().AerendeQryApi.getAerende({ aerendeDTOQry: aerendeId });
      dispatch(aerendeQry.success(result));
    } catch (error) {
      dispatch(aerendeQry.failure(aerendeId, await parseErrorResponse(error)));
    }
  };

// React to integration events
export const aerendeQryReducer: Reducer<AerendeQryState, IntegrationEventAction> = (state = {}, action) => {
  switch (action.type) {
    case IntegrationEventActionType.Aerende: {
      const primaryPK = action.payload.eventDataKey;
      const operation = action.payload.operation;

      // Om ärendet har uppdaterats vill vi hämta det på nytt genom att sätta statusen till stale
      if (operation === 'Update') {
        const webData = aerendeQry.get(state, primaryPK);
        return { ...state, [SdKeys.keyToString(primaryPK)]: webDataSetStale(webData) };
      } else {
        return { ...state };
      }
    }
    case IntegrationEventActionType.UpdateAerendeMedarbetareProp: {
      // React to the event and update redux directly
      // However dates are retrieved as string values, so we need to convert them into a Date object
      const primaryPK = action.payload.eventDataKey;
      const eventData = action.payload.eventData;

      const webData = aerendeQry.get(state, primaryPK);
      if (RemoteDataFunctions.hasData(webData) && eventData.propTyp) {
        const propValue = isValidDate(eventData.propValue) ? new Date(eventData.propValue) : eventData.propValue;
        return {
          ...state,
          [SdKeys.keyToString(primaryPK)]: {
            data: { ...webData.data, [eventData.propTyp]: propValue },
            status: RemoteDataStatus.Success,
          },
        };
      }
      return { ...state };
    }
    case IntegrationEventActionType.CreateAerendeMedarbetareKontor: {
      // React to the event and update redux directly
      const primaryPK = action.payload.eventDataKey;
      const aerendePK = VOCreate<AerendePK>({ AerendeId: primaryPK.AerendeId });
      const webData = aerendeQry.get(state, aerendePK);
      if (RemoteDataFunctions.hasData(webData) && primaryPK.AerendeId && primaryPK.KontorId) {
        const newItem: AerendeMedarbetareKontorListDTO = {
          AerendePK: VOCreate<AerendePK>({ AerendeId: primaryPK.AerendeId }),
          KontorId: primaryPK.KontorId,
          Kontorsnamn: action.payload.eventData.kontorsnamn,
        };
        return {
          ...state,
          [SdKeys.keyToString(VOCreate<AerendePK>({ AerendeId: primaryPK.AerendeId }))]: {
            data: {
              ...webData.data,
              AerendeMedarbetareKontorList: webData.data.AerendeMedarbetareKontorList.concat(newItem),
            },
            status: RemoteDataStatus.Success,
          },
        };
      }
      break;
    }
    case IntegrationEventActionType.DeleteAerendeMedarbetareKontor: {
      // React to the event and update redux directly
      const primaryPK = action.payload.eventDataKey;
      const aerendePK = VOCreate<AerendePK>({ AerendeId: primaryPK.AerendeId });
      const webData = aerendeQry.get(state, aerendePK);
      if (RemoteDataFunctions.hasData(webData) && primaryPK.AerendeId && primaryPK.KontorId) {
        return {
          ...state,
          [SdKeys.keyToString(aerendePK)]: {
            data: {
              ...webData.data,
              AerendeMedarbetareKontorList: webData.data.AerendeMedarbetareKontorList.filter(
                k => k.KontorId !== primaryPK.KontorId
              ),
            },
            status: RemoteDataStatus.Success,
          },
        };
      }
      break;
    }
  }

  return aerendeQry.reducer(state, action);
};
