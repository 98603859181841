import { Command } from '@decernointernal/websd.shared';
import { CreateAerendeServiceCmd } from 'generated-models/anstallningsportalen';
import { CreateAvslutaAerendeServiceCmd } from 'generated-models/anstallningsportalen/models/CreateAvslutaAerendeServiceCmd';
import { Dispatch } from 'react';
import { ThunkAction } from 'store/store';

interface ISkapaNyttAerendeStateReducerActionSetCreateAerendeCommandStatusPayload {
  readonly payloadCommand?: Command<unknown, unknown>;
}

interface ISkapaNyttAerendeStateReducerActionSetCreateAerendePayload {
  readonly dispatch?: Dispatch<any>;
}

interface ISkapaNyttAerendeStateReducerActionSetCreateAerendeCommandAndActionPayload {
  readonly command?: CreateAerendeServiceCmd | CreateAvslutaAerendeServiceCmd;
  readonly commandAction?: (cmd: any) => ThunkAction;
}

type SkapaNyttAerendeStateReducerActionPayloadType =
  | ISkapaNyttAerendeStateReducerActionSetCreateAerendeCommandAndActionPayload
  | ISkapaNyttAerendeStateReducerActionSetCreateAerendePayload
  | ISkapaNyttAerendeStateReducerActionSetCreateAerendeCommandStatusPayload;

export interface ISkapaNyttAerendeStateReducerAction {
  readonly type: 'setCreateAerendeCommandStatus' | 'setCreateAerendeCommandAndAction' | 'createAerende' | 'forceAbort';
  readonly payload?: SkapaNyttAerendeStateReducerActionPayloadType;
}

export interface ISkapaNyttAerendeStateReducerState {
  isValid?: boolean;
  payloadCommand?: Command<unknown, unknown>;
  command?: CreateAerendeServiceCmd | CreateAvslutaAerendeServiceCmd;
  commandAction?: (cmd: CreateAerendeServiceCmd | CreateAvslutaAerendeServiceCmd) => ThunkAction;
  forceAbort?: boolean;
}

export const skapaNyttAerendeStateReducer = (
  state: ISkapaNyttAerendeStateReducerState,
  action: ISkapaNyttAerendeStateReducerAction
) => {
  const { type } = action;
  switch (type) {
    case 'setCreateAerendeCommandStatus': {
      const payload = action.payload as ISkapaNyttAerendeStateReducerActionSetCreateAerendeCommandStatusPayload;
      return { ...state, payloadCommand: payload.payloadCommand };
    }
    case 'setCreateAerendeCommandAndAction': {
      const payload = action.payload as ISkapaNyttAerendeStateReducerActionSetCreateAerendeCommandAndActionPayload;
      return { ...state, command: payload.command, commandAction: payload.commandAction, isValid: !!payload.command };
    }
    case 'forceAbort': {
      return { ...state, forceAbort: true };
    }
    case 'createAerende': {
      const payload = action.payload as ISkapaNyttAerendeStateReducerActionSetCreateAerendePayload;
      if (payload.dispatch && state.commandAction && state.command) {
        payload.dispatch(state.commandAction(state.command));
      }
    }
  }
  return { ...state };
};
