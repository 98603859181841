import aerendemallTypEnum from 'domain/aerendemallTyp';
import { FC } from 'react';
import { IInformationSektionProps } from '../../IInformationProps';
import Avsluta from './Avsluta';
import NY from './NY';

export const Anstaellning: FC<IInformationSektionProps> = props => {
  switch (props.aerendemallTypVO.AerendemallTyp) {
    case aerendemallTypEnum.NY:
      return <NY {...props} />;
    case aerendemallTypEnum.AVSLUT:
      return <Avsluta {...props} />;
    default:
      return <></>;
  }
};
