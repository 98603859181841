import { FbModal } from '@decernointernal/fb-interna-komponenter';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectNavigateAway } from './navigateAwayReducer';

export const NavigateAway: FC = () => {
  const navigateAway = useSelector(selectNavigateAway());

  useEffect(() => {
    const alertUser = (ev: BeforeUnloadEvent) => {
      if (navigateAway.fields.length > 0 || navigateAway.ongoingTransactionCount > 0) {
        ev.preventDefault();
        ev.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [navigateAway.fields, navigateAway.ongoingTransactionCount]);

  return navigateAway.showModal ? (
    <div className="fixed top-4 right-4" style={{ zIndex: 1001 }}>
      <FbModal
        buttonConfirmText="Rätta"
        buttonCancelText="Kasta"
        titel={navigateAway.title}
        onConfirm={() => navigateAway.canLeave && navigateAway.canLeave(false)}
        onCancel={() => navigateAway.canLeave && navigateAway.canLeave(true)}
      >
        {navigateAway.message}
      </FbModal>
    </div>
  ) : (
    <></>
  );
};
