import { AnyAction, ThunkAction as ReduxThunkAction, StoreEnhancer, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { connectRoutes } from 'redux-first-router';
import configureMockStore from 'redux-mock-store';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { createRootReducer } from './createRootReducer';
import { routesMap, routesOptions } from './location';
import { navigateAwayMiddleware } from './navigateAwayMiddleware';

const {
  reducer: routesReducer,
  middleware: locationMiddleware,
  enhancer: locationEnhancer,
} = connectRoutes(routesMap, routesOptions);
const rootReducer = createRootReducer(routesReducer);

const store = configureStore({
  reducer: rootReducer,
  middleware: [locationMiddleware, thunkMiddleware, navigateAwayMiddleware],
  enhancers: [locationEnhancer as StoreEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;

type AppAction = ReturnType<typeof store.dispatch>;
export type AppDispatch = ThunkDispatch<RootState, any, AppAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type ThunkAction = ReduxThunkAction<void, RootState, {}, AnyAction>;

export default store;

export const mockStore = configureMockStore<RootState, AppDispatch>([
  locationMiddleware,
  thunkMiddleware,
  navigateAwayMiddleware,
]);
