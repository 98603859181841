import {
  FbAlert,
  FbDatepicker,
  FbFormDropdown,
  FbFormDropdownValue,
  FbSpinner,
  scrollToElement,
} from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { getUserQryAction, selectUserQry } from 'components/user/qryUser';
import { aerendeMallAvslut } from 'domain/aerendeMall';
import { bostadsMarknadEnum } from 'domain/bostadsMarknad';
import { medarbetareRollEnum } from 'domain/medarbetareRoll';
import { AerendemallVO } from 'generated-models/anstallningsportalen';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import { createAvslutaAerendeAction, selectCreateAvslutaAerendeCmd } from '../../cmdCreateAvslutaAerende';
import { Header } from '../../shared-components/Header';
import FormInputWrapper from '../FormInputWrapper';
import ISkapaNyttAerendeStateDispatchProps from '../ISkapaNyttAerendeStateDispatchProps';
import { medarbetarListQryAction, selectMedarbetarListQry } from '../qryMedarbetarList';

interface IAvslutaAnstaellning extends ISkapaNyttAerendeStateDispatchProps {}

interface IAvslutaEnAnstaellningAerende {
  readonly aerendeMall: AerendemallVO;
  readonly planeradSistaArbetsdag?: Date;
  readonly personId?: number;
}

export const AvslutaEnAnstaellning: React.FC<IAvslutaAnstaellning> = ({ skapaNyttAerendeStateDispatch }) => {
  const [nyttAerendeState, setNyttAerendeState] = React.useState<IAvslutaEnAnstaellningAerende>({
    aerendeMall: VOCreate<AerendemallVO>({
      AerendemallId: aerendeMallAvslut.MEDARBETARE_SLUTAR,
    }),
  });
  const [jobbarPaaFleraKontor, setJobbarPaaFleraKontor] = useState<boolean>(false);
  const [jobbarPaaIckeBehoerigtKontor, setJobbarPaaIckeBehoerigtKontor] = useState<boolean>(false);
  var userHeader = useSelector(selectUserQry());
  var medarbetarList = useSelector(selectMedarbetarListQry());
  const createAvslutaAerende = useSelector(selectCreateAvslutaAerendeCmd);
  const dispatch = useAppDispatch();

  useEffect(() => {
    skapaNyttAerendeStateDispatch({
      type: 'setCreateAerendeCommandStatus',
      payload: { payloadCommand: createAvslutaAerende },
    });
  }, [skapaNyttAerendeStateDispatch, createAvslutaAerende]);

  useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(medarbetarList)) {
      dispatch(medarbetarListQryAction());
    }
  }, [medarbetarList, dispatch]);

  const medarbetare = useMemo((): FbFormDropdownValue[] => {
    var ap: FbFormDropdownValue[] = [];

    if (RemoteDataFunctions.hasData(medarbetarList)) {
      medarbetarList.data.forEach(ml => ap.push({ id: ml.PersonId, value: `${ml.Foernamn} ${ml.Efternamn}` }));
    }

    return ap.sort((a, b) => a.value.localeCompare(b.value));
  }, [medarbetarList]);

  useEffect(() => {
    skapaNyttAerendeStateDispatch({
      type: 'setCreateAerendeCommandAndAction',
      payload: {
        commandAction: createAvslutaAerendeAction,
        command:
          nyttAerendeState && nyttAerendeState.personId && nyttAerendeState.planeradSistaArbetsdag
            ? {
                AerendemallVO: nyttAerendeState.aerendeMall,
                PersonId: nyttAerendeState.personId,
                Slutdatum: nyttAerendeState.planeradSistaArbetsdag,
              }
            : undefined,
      },
    });
  }, [nyttAerendeState, skapaNyttAerendeStateDispatch, medarbetare]);

  useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(userHeader) || RemoteDataFunctions.isStale(userHeader)) {
      dispatch(getUserQryAction());
    }
  }, [userHeader, dispatch]);

  return (
    <>
      {!RemoteDataFunctions.hasData(userHeader) && <FbSpinner size="medium" />}
      {RemoteDataFunctions.hasData(userHeader) && (
        <div className="mb-8">
          <Header id="avsluta" rubrik="Avsluta anställning på Fastighetsbyrån" className="mt-3" />
          <div className="fb-paragraph-lvl-2 my-2">
            <FbAlert
              type="info"
              text={
                'Om medarbetaren byter kontor inom kedjan: avsluta inte anställningen här, utan kontakta HR-support.'
              }
            />
          </div>
          <FormInputWrapper>
            {RemoteDataFunctions.isLoading(medarbetarList) && <FbSpinner size="medium" />}
            {RemoteDataFunctions.isSuccess(medarbetarList) && (
              <FbFormDropdown
                id="medarbetare"
                label="Medarbetare"
                required={true}
                values={medarbetare}
                defaultId={medarbetare.length === 1 ? medarbetare[0].id : undefined}
                onFocus={() => {
                  if (!nyttAerendeState?.personId) {
                    scrollToElement('avsluta', 0, undefined, 0, 'modalScrollContainer');
                  }
                }}
                onBlur={(value?: FbFormDropdownValue) => {
                  if (RemoteDataFunctions.hasData(medarbetarList)) {
                    const medarbetare = medarbetarList.data.find(m => m.PersonId === value?.id);
                    let jobbarPaaFleraKontorNewState = false;
                    let jobbarPaaIckeBehoerigtKontorNewState = false;

                    if (medarbetare) {
                      if (medarbetare?.JobbarPaaIckeBehoerigtKontor) {
                        jobbarPaaIckeBehoerigtKontorNewState = true;
                      } else if (medarbetare?.JobbarPaaFleraKontor) {
                        jobbarPaaFleraKontorNewState = true;
                      }

                      setJobbarPaaFleraKontor(jobbarPaaFleraKontorNewState);
                      setJobbarPaaIckeBehoerigtKontor(jobbarPaaIckeBehoerigtKontorNewState);

                      // Får inte spara om medarbetare jobbar på ett kontor du saknar behörighet till
                      setNyttAerendeState(prevState => {
                        return {
                          ...prevState,
                          personId:
                            !jobbarPaaIckeBehoerigtKontorNewState && value?.id ? (value.id as number) : undefined,
                          aerendeMall: VOCreate<AerendemallVO>({
                            AerendemallId:
                              medarbetare.BostadsMarknadId === bostadsMarknadEnum.UTLAND
                                ? aerendeMallAvslut.UTLAND_SLUTAR
                                : medarbetare.MedarbetareRollVO?.MedarbetareRoll ===
                                  medarbetareRollEnum.FASTIGHETSMAEKLARE
                                ? aerendeMallAvslut.MEDARBETARE_SLUTAR
                                : aerendeMallAvslut.OEVRIGA_SLUTAR,
                          }),
                        };
                      });
                    }
                  }
                }}
                expandScroll={true}
              />
            )}
          </FormInputWrapper>
          {jobbarPaaFleraKontor && (
            <FbAlert
              type="info"
              text="Observera att medarbetare är kopplad till mer än ett kontor, anställning kommer att avslutas hos samtliga. Vänligen kontakta HR-support om du endast vill ta bort personen från ett kontor."
            />
          )}
          {jobbarPaaIckeBehoerigtKontor && (
            <FbAlert
              type="danger"
              text="Denna medarbetare är även knuten till kontor som du inte har behörighet att administrera. Vänligen kontakta HR-support."
            />
          )}
          <Header className="mt-2" rubrik="Planerad sista arbetsdag" />
          <FormInputWrapper className="-mt-5">
            <FbDatepicker
              id="planeradSistaArbetsdag"
              label="Datum"
              startDate={nyttAerendeState?.planeradSistaArbetsdag}
              validationFailedTooltip="åååå-mm-dd"
              required={true}
              onChange={date => {
                setNyttAerendeState(prevState => {
                  return { ...prevState, planeradSistaArbetsdag: date ?? undefined };
                });
              }}
              onInstantChange={(_, isValid: boolean) => {
                if (!isValid) {
                  setNyttAerendeState(prevState => {
                    return { ...prevState, planeradSistaArbetsdag: undefined };
                  });
                }
              }}
            />
          </FormInputWrapper>
        </div>
      )}
    </>
  );
};

export default AvslutaEnAnstaellning;
