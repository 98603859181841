/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ClientCrashStackframe
 */
export interface ClientCrashStackframe {
  /**
   *
   * @type {string}
   * @memberof ClientCrashStackframe
   */
  Source?: string | null;
}

export function ClientCrashStackframeFromJSON(json: any): ClientCrashStackframe {
  return ClientCrashStackframeFromJSONTyped(json, false);
}

export function ClientCrashStackframeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientCrashStackframe {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Source: !exists(json, 'Source') ? undefined : json['Source'],
  };
}

export function ClientCrashStackframeToJSON(value?: ClientCrashStackframe | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Source: value.Source,
  };
}
