import { FbAlert, FbFormCheckbox, FbFormInput } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { aerendeMedarbetareKontorPropTypEnum } from 'domain/aerendeMedarbetareKontorPropTypDomain';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectAerendeQry } from '../../qryAerende';
import { IInformationSektionProps } from '../IInformationProps';
import { sektionHoc } from './sektionHoc';

export const KontaktuppgifterArbete: FC<IInformationSektionProps> = ({ aerendePK }) => {
  const aerende = useSelector(selectAerendeQry(aerendePK));

  const epostHarLoepnummer = (): boolean => {
    try {
      if (aerende.status === 'Success') {
        if (!aerende.data.MejlAdress) return false;

        const atIndex = aerende.data.MejlAdress.indexOf('@');
        const lastNamnChar = aerende.data.MejlAdress.substring(atIndex - 1, atIndex);

        return !isNaN(Number(lastNamnChar));
      }
    } catch (error) {}
    return false;
  };

  const epostUtanLoepnummer = (): string => {
    try {
      if (aerende.status === 'Success') {
        const mejl = aerende.data.MejlAdress;

        if (!mejl) {
          return '';
        }

        const atIndex = mejl.indexOf('@');
        var firstDigitIndex = atIndex - 1;

        for (let i = firstDigitIndex; i >= 0; i--) {
          const currentChar = mejl.substring(i, i);
          if (!isNaN(Number(currentChar))) {
            break;
          }
          firstDigitIndex = i - 1;
        }

        return mejl.substring(0, firstDigitIndex) + mejl.substring(atIndex, mejl.length);
      }
    } catch (error) {}
    return '';
  };

  return (
    <>
      {RemoteDataFunctions.hasData(aerende) && (
        <>
          <div className="flex flex-wrap pb-2">
            <div className="w-48 pr-3">
              {sektionHoc(FbFormInput, { aerendeId: aerendePK.AerendeId })({
                id: aerendeMedarbetareKontorPropTypEnum.MOBILTELEFONNUMMER,
                label: 'Mobilnummer',
                type: 'telefonnummer',
                defaultValue: aerende.data.Mobiltelefonnummer ?? '',
              })}
            </div>
            <div className="w-48 pl-3">
              {sektionHoc(FbFormInput, { aerendeId: aerendePK.AerendeId })({
                id: aerendeMedarbetareKontorPropTypEnum.TELEFONNUMMER,
                label: 'Telefonnummer',
                type: 'telefonnummer',
                defaultValue: aerende.data.Telefonnummer ?? '',
              })}
            </div>
          </div>
          <div className="pb-6">
            {sektionHoc(FbFormCheckbox, { aerendeId: aerendePK.AerendeId, negateValue: true })({
              id: aerendeMedarbetareKontorPropTypEnum.MOBILTELEFONNUMMER_AER_EXTERNT_SYNLIGT,
              label: 'Dölj mobilnummer på webben',
              defaultChecked: !aerende.data.MobiltelefonnummerAerExterntSynligt,
            })}
          </div>
          <div className="pb-2">
            <FbFormInput
              id="mejladress"
              label="E-postadress"
              disabled={true}
              defaultValue={aerende.data.MejlAdress ?? ''}
              tooltip="För att ändra E-postadress, kontakta HR-support efter att kontot skapats."
              tooltipMaxwidth={18.125 * 16}
              className="w-96"
            />
            {epostHarLoepnummer() && (
              <div className="mt-2">
                <FbAlert
                  type="info"
                  text={
                    epostUtanLoepnummer() +
                    ' är redan upptagen. Användaren kommer att skapas med denna alternativa e-postadress. Adressen kan ändras av HR-support, men först efter att kontot skapats.'
                  }
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
