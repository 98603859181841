import { hideModal } from 'components/aerende/navigateAway/navigateAwayReducer';
import { AerendePK } from 'generated-models/anstallningsportalen/models/AerendePK';
import { Options, RouteObject, RoutesMap, RouteString } from 'redux-first-router';
import store, { RootState } from './store';

export enum LocationActionType {
  Home = '[location] Home',
  AerendeInformation = '[location] Aerende - information',
  AerendeChecklistor = '[location] Aerende - checklistor',
  NotFound = '@@redux-first-router/NOT_FOUND',
}

const confirmLeave = (state: object, _2: object) => {
  const rootState = state as RootState;
  const navigateAway = rootState.main.aerende.navigateAway;

  return navigateAway.fields.length > 0
    ? 'Du har gjort ändringar som inte kan sparas. Välj om du vill rätta dessa fält eller kasta dina ändringar.'
    : '';
};

export const routesMap: RoutesMap<RouteString | RouteObject> = {
  [LocationActionType.Home]: '/',
  [LocationActionType.AerendeInformation]: {
    path: '/Aerende/:aerendeId/information',
    confirmLeave,
  },
  [LocationActionType.AerendeChecklistor]: {
    path: '/Aerende/:aerendeId/checklistor',
    confirmLeave,
  },
};

export const homeRoute = () => ({ type: LocationActionType.Home });
export const aerendeInformationRoute = (aerendePK: AerendePK) => ({
  type: LocationActionType.AerendeInformation,
  payload: { aerendeId: aerendePK.AerendeId },
});
export const aerendeChecklistorRoute = (aerendePK: AerendePK) => ({
  type: LocationActionType.AerendeChecklistor,
  payload: { aerendeId: aerendePK.AerendeId },
});

export enum PageComponent {
  Home = 'Home',
  Aerende = 'Aerende',
  NotFound = 'NotFound',
}

const components: { [key in LocationActionType]: PageComponent } = {
  [LocationActionType.Home]: PageComponent.Home,
  [LocationActionType.AerendeInformation]: PageComponent.Aerende,
  [LocationActionType.AerendeChecklistor]: PageComponent.Aerende,
  [LocationActionType.NotFound]: PageComponent.NotFound,
};

export const selectPage = (state: RootState) => state.page;

export const pageReducer = (state = PageComponent.Home, action: { type: LocationActionType }) =>
  components[action.type] || state;

export const routesOptions: Options = {
  // Custom handling of navigation leave - to let the user stay on the page if there are unsaved changes
  displayConfirmLeave: (message, callback) => {
    const canLeave = (can: boolean) => {
      store.dispatch(hideModal({ clearFields: can }));
      return callback(can);
    };

    const state: RootState = store.getState();
    const fields = state.main.aerende.navigateAway.fields;
    if (fields.length > 0) {
      store.dispatch({ type: 'autosave', canLeave, message });
    } else {
      canLeave(true);
    }
  },
};
