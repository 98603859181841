/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { ActionTypVO, ActionTypVOFromJSON, ActionTypVOFromJSONTyped, ActionTypVOToJSON } from './ActionTypVO';
import {
  AerendeAktivitetStatusVO,
  AerendeAktivitetStatusVOFromJSON,
  AerendeAktivitetStatusVOFromJSONTyped,
  AerendeAktivitetStatusVOToJSON,
} from './AerendeAktivitetStatusVO';
import {
  AerendeAktivitetsmallObligatoriskaAktiviteterDTO,
  AerendeAktivitetsmallObligatoriskaAktiviteterDTOFromJSON,
  AerendeAktivitetsmallObligatoriskaAktiviteterDTOFromJSONTyped,
  AerendeAktivitetsmallObligatoriskaAktiviteterDTOToJSON,
} from './AerendeAktivitetsmallObligatoriskaAktiviteterDTO';
import {
  AerendeAktivitetsmallObligatoriskaFaeltDTO,
  AerendeAktivitetsmallObligatoriskaFaeltDTOFromJSON,
  AerendeAktivitetsmallObligatoriskaFaeltDTOFromJSONTyped,
  AerendeAktivitetsmallObligatoriskaFaeltDTOToJSON,
} from './AerendeAktivitetsmallObligatoriskaFaeltDTO';
import {
  AerendemallTypVO,
  AerendemallTypVOFromJSON,
  AerendemallTypVOFromJSONTyped,
  AerendemallTypVOToJSON,
} from './AerendemallTypVO';
import { AerendemallVO, AerendemallVOFromJSON, AerendemallVOFromJSONTyped, AerendemallVOToJSON } from './AerendemallVO';

/**
 *
 * @export
 * @interface ChecklistaListDTO
 */
export interface ChecklistaListDTO {
  /**
   *
   * @type {number}
   * @memberof ChecklistaListDTO
   */
  AerendeAktivitetsmallId: number;
  /**
   *
   * @type {string}
   * @memberof ChecklistaListDTO
   */
  Checklista: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistaListDTO
   */
  Rubrik?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistaListDTO
   */
  Titel: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistaListDTO
   */
  Innehaall: string;
  /**
   *
   * @type {ActionTypVO}
   * @memberof ChecklistaListDTO
   */
  ActionTypVO: ActionTypVO;
  /**
   *
   * @type {string}
   * @memberof ChecklistaListDTO
   */
  ActionText?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistaListDTO
   */
  ActionUrl?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistaListDTO
   */
  Irreversibel: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistaListDTO
   */
  Obligatorisk: boolean;
  /**
   *
   * @type {number}
   * @memberof ChecklistaListDTO
   */
  Sorteringsordning: number;
  /**
   *
   * @type {AerendeAktivitetStatusVO}
   * @memberof ChecklistaListDTO
   */
  StatusVO: AerendeAktivitetStatusVO;
  /**
   *
   * @type {Array<AerendeAktivitetsmallObligatoriskaFaeltDTO>}
   * @memberof ChecklistaListDTO
   */
  AerendeAktivitetsmallObligatoriskaFaelt?: Array<AerendeAktivitetsmallObligatoriskaFaeltDTO> | null;
  /**
   *
   * @type {Array<AerendeAktivitetsmallObligatoriskaAktiviteterDTO>}
   * @memberof ChecklistaListDTO
   */
  AerendeAktivitetsmallObligatoriskaAktiviteter?: Array<AerendeAktivitetsmallObligatoriskaAktiviteterDTO> | null;
  /**
   *
   * @type {AerendemallTypVO}
   * @memberof ChecklistaListDTO
   */
  AerendemallTypVO: AerendemallTypVO;
  /**
   *
   * @type {AerendemallVO}
   * @memberof ChecklistaListDTO
   */
  AerendemallVO: AerendemallVO;
}

export function ChecklistaListDTOFromJSON(json: any): ChecklistaListDTO {
  return ChecklistaListDTOFromJSONTyped(json, false);
}

export function ChecklistaListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChecklistaListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendeAktivitetsmallId: json['AerendeAktivitetsmallId'],
    Checklista: json['Checklista'],
    Rubrik: !exists(json, 'Rubrik') ? undefined : json['Rubrik'],
    Titel: json['Titel'],
    Innehaall: json['Innehaall'],
    ActionTypVO: ActionTypVOFromJSON(json['ActionTypVO']),
    ActionText: !exists(json, 'ActionText') ? undefined : json['ActionText'],
    ActionUrl: !exists(json, 'ActionUrl') ? undefined : json['ActionUrl'],
    Irreversibel: json['Irreversibel'],
    Obligatorisk: json['Obligatorisk'],
    Sorteringsordning: json['Sorteringsordning'],
    StatusVO: AerendeAktivitetStatusVOFromJSON(json['StatusVO']),
    AerendeAktivitetsmallObligatoriskaFaelt: !exists(json, 'AerendeAktivitetsmallObligatoriskaFaelt')
      ? undefined
      : json['AerendeAktivitetsmallObligatoriskaFaelt'] === null
      ? null
      : (json['AerendeAktivitetsmallObligatoriskaFaelt'] as Array<any>).map(
          AerendeAktivitetsmallObligatoriskaFaeltDTOFromJSON
        ),
    AerendeAktivitetsmallObligatoriskaAktiviteter: !exists(json, 'AerendeAktivitetsmallObligatoriskaAktiviteter')
      ? undefined
      : json['AerendeAktivitetsmallObligatoriskaAktiviteter'] === null
      ? null
      : (json['AerendeAktivitetsmallObligatoriskaAktiviteter'] as Array<any>).map(
          AerendeAktivitetsmallObligatoriskaAktiviteterDTOFromJSON
        ),
    AerendemallTypVO: AerendemallTypVOFromJSON(json['AerendemallTypVO']),
    AerendemallVO: AerendemallVOFromJSON(json['AerendemallVO']),
  };
}

export function ChecklistaListDTOToJSON(value?: ChecklistaListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendeAktivitetsmallId: value.AerendeAktivitetsmallId,
    Checklista: value.Checklista,
    Rubrik: value.Rubrik,
    Titel: value.Titel,
    Innehaall: value.Innehaall,
    ActionTypVO: ActionTypVOToJSON(value.ActionTypVO),
    ActionText: value.ActionText,
    ActionUrl: value.ActionUrl,
    Irreversibel: value.Irreversibel,
    Obligatorisk: value.Obligatorisk,
    Sorteringsordning: value.Sorteringsordning,
    StatusVO: AerendeAktivitetStatusVOToJSON(value.StatusVO),
    AerendeAktivitetsmallObligatoriskaFaelt:
      value.AerendeAktivitetsmallObligatoriskaFaelt === undefined
        ? undefined
        : value.AerendeAktivitetsmallObligatoriskaFaelt === null
        ? null
        : (value.AerendeAktivitetsmallObligatoriskaFaelt as Array<any>).map(
            AerendeAktivitetsmallObligatoriskaFaeltDTOToJSON
          ),
    AerendeAktivitetsmallObligatoriskaAktiviteter:
      value.AerendeAktivitetsmallObligatoriskaAktiviteter === undefined
        ? undefined
        : value.AerendeAktivitetsmallObligatoriskaAktiviteter === null
        ? null
        : (value.AerendeAktivitetsmallObligatoriskaAktiviteter as Array<any>).map(
            AerendeAktivitetsmallObligatoriskaAktiviteterDTOToJSON
          ),
    AerendemallTypVO: AerendemallTypVOToJSON(value.AerendemallTypVO),
    AerendemallVO: AerendemallVOToJSON(value.AerendemallVO),
  };
}
