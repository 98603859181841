/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AerendeDTO,
  AerendeDTOFromJSON,
  AerendeDTOToJSON,
  AerendeDTOQry,
  AerendeDTOQryFromJSON,
  AerendeDTOQryToJSON,
  AerendeListDTO,
  AerendeListDTOFromJSON,
  AerendeListDTOToJSON,
} from '../models';

export interface GetAerendeRequest {
  aerendeDTOQry?: AerendeDTOQry;
}

/**
 *
 */
export class AerendeQryApi extends runtime.BaseAPI {
  /**
   * Hämta ärende
   */
  async getAerendeRaw(
    requestParameters: GetAerendeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AerendeDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/AerendeQry/GetAerende`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AerendeDTOQryToJSON(requestParameters.aerendeDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => AerendeDTOFromJSON(jsonValue));
  }

  /**
   * Hämta ärende
   */
  async getAerende(requestParameters: GetAerendeRequest = {}, initOverrides?: RequestInit): Promise<AerendeDTO> {
    const response = await this.getAerendeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Hämta aktuella ärenden
   */
  async getAerendeListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AerendeListDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/AerendeQry/GetAerendeList`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(AerendeListDTOFromJSON));
  }

  /**
   * Hämta aktuella ärenden
   */
  async getAerendeList(initOverrides?: RequestInit): Promise<Array<AerendeListDTO>> {
    const response = await this.getAerendeListRaw(initOverrides);
    return await response.value();
  }
}
