import { combineReducers } from 'redux';
import aktivitetActionMeddelandeReducer from '../aktivitetAction/aktivitetActionMeddelandeReducer';
import { utfoerAktivitetCmdReducer } from '../aktivitetAction/cmdUtfoerAktivitet';
import { saveAerendeAktivitetStatusCmdReducer } from './cmdSaveAerendeAktivitetStatus';

export const aerendeAktivitetStatusReducer = combineReducers({
  status: saveAerendeAktivitetStatusCmdReducer,
  utforAktivitet: utfoerAktivitetCmdReducer,
  aktivitetActionMeddelande: aktivitetActionMeddelandeReducer,
});
