import { combineReducers, Reducer } from '@reduxjs/toolkit';
import azureADLoginTimerReducer from 'azureAD/AzureADLoginTimerReducer';
import { mainReducer } from 'components/mainReducer';
import FbOverlayReducer from 'components/overlay/FbOverlayReducer';
import { userReducer } from 'components/user/userReducer';
import { LocationState, RouteObject, RouteString } from 'redux-first-router';
import { pageReducer } from './location';
import { RootState } from './store';

export type MapState<S> = (state: RootState) => S;

// TODO: flytta/rensa alla förutom location& main

export const createRootReducer = (location: Reducer<LocationState<RouteString | RouteObject, unknown>>) =>
  combineReducers({
    location,
    page: pageReducer,
    main: mainReducer,
    azureADLoginTimer: azureADLoginTimerReducer,
    user: userReducer,
    FbOverlay: FbOverlayReducer,
  });
