import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export interface IFieldId {
  readonly id: string;
}

export interface IField extends IFieldId {
  readonly isValid: boolean;
  readonly value?: string;
  readonly action?: any;
}

export interface ICanLeave {
  canLeave?: (can: boolean) => void;
  message?: string;
  title?: string;
}

export interface NavigateAwayState extends ICanLeave {
  fields: IField[];
  showModal: boolean;
  message: string;
  ongoingTransactionCount: number;
}

const initialState: NavigateAwayState = {
  fields: [],
  showModal: false,
  message: 'Du har gjort ändringar som inte kan sparas. Välj om du vill rätta dessa fält eller kasta dina ändringar.',
  title: 'Varning',
  ongoingTransactionCount: 0,
};

export const navigateAwaySlice = createSlice({
  name: 'NavigateAway',
  initialState,
  reducers: {
    addOrUpdateField: (state, action: PayloadAction<IField>) => {
      const { payload } = action;
      const index = state.fields.findIndex(f => f.id === payload.id);

      if (index === -1) {
        state.fields.push(payload);
      } else {
        state.fields = state.fields.map(f => (f.id !== payload.id ? f : payload));
      }
    },
    removeField: (state, action: PayloadAction<IFieldId>) => {
      state.fields = state.fields.filter(f => f.id !== action.payload.id);
    },
    showModal: (state, action: PayloadAction<ICanLeave>) => {
      // Det finns fält som inte validerar, uppmärksamma användaren om det
      const { canLeave, message, title } = action.payload;
      state.showModal = true;
      state.canLeave = canLeave;
      if (message) {
        state.message = message;
      }
      if (title) {
        state.title = title;
      }
    },
    hideModal: (state, action: PayloadAction<{ clearFields?: boolean }>) => {
      state.showModal = false;
      if (action.payload.clearFields) {
        state.fields = [];
      }
    },
    increaseOngoingTransactionCount: state => {
      state.ongoingTransactionCount += 1;
    },
    decreaseOngoingTransactionCount: state => {
      state.ongoingTransactionCount = state.ongoingTransactionCount > 0 ? state.ongoingTransactionCount - 1 : 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addOrUpdateField,
  removeField,
  showModal,
  hideModal,
  increaseOngoingTransactionCount,
  decreaseOngoingTransactionCount,
} = navigateAwaySlice.actions;

export const selectNavigateAway = () => (state: RootState) => state.main.aerende.navigateAway;

export default navigateAwaySlice.reducer;
