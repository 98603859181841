/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ChangeAerendeStatusCmd,
  ChangeAerendeStatusCmdFromJSON,
  ChangeAerendeStatusCmdToJSON,
  CreateAerendeMedarbetareKontorCmd,
  CreateAerendeMedarbetareKontorCmdFromJSON,
  CreateAerendeMedarbetareKontorCmdToJSON,
  CreateAerendeServiceCmd,
  CreateAerendeServiceCmdFromJSON,
  CreateAerendeServiceCmdToJSON,
  CreateAvslutaAerendeServiceCmd,
  CreateAvslutaAerendeServiceCmdFromJSON,
  CreateAvslutaAerendeServiceCmdToJSON,
  DeleteAerendeMedarbetareKontorCmd,
  DeleteAerendeMedarbetareKontorCmdFromJSON,
  DeleteAerendeMedarbetareKontorCmdToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  SdIntegrationEvent,
  SdIntegrationEventFromJSON,
  SdIntegrationEventToJSON,
  UpdateAerendeMedarbetarePropCmd,
  UpdateAerendeMedarbetarePropCmdFromJSON,
  UpdateAerendeMedarbetarePropCmdToJSON,
} from '../models';

export interface ChangeAerendeStatusRequest {
  changeAerendeStatusCmd?: ChangeAerendeStatusCmd;
}

export interface CreateAerendeRequest {
  createAerendeServiceCmd?: CreateAerendeServiceCmd;
}

export interface CreateAerendeMedarbetareKontorRequest {
  createAerendeMedarbetareKontorCmd?: CreateAerendeMedarbetareKontorCmd;
}

export interface CreateAvslutaAerendeRequest {
  createAvslutaAerendeServiceCmd?: CreateAvslutaAerendeServiceCmd;
}

export interface DeleteAerendeMedarbetareKontorRequest {
  deleteAerendeMedarbetareKontorCmd?: DeleteAerendeMedarbetareKontorCmd;
}

export interface UpdateAerendeMedarbetarePropRequest {
  updateAerendeMedarbetarePropCmd?: UpdateAerendeMedarbetarePropCmd;
}

/**
 *
 */
export class AerendeCmdApi extends runtime.BaseAPI {
  /**
   * Ändrar aktivitetstatus på ett ärende
   */
  async changeAerendeStatusRaw(
    requestParameters: ChangeAerendeStatusRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/AerendeCmd/ChangeAerendeStatus`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ChangeAerendeStatusCmdToJSON(requestParameters.changeAerendeStatusCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   * Ändrar aktivitetstatus på ett ärende
   */
  async changeAerendeStatus(
    requestParameters: ChangeAerendeStatusRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.changeAerendeStatusRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Skapa ett nytt ärende
   */
  async createAerendeRaw(
    requestParameters: CreateAerendeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/AerendeCmd/CreateAerende`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateAerendeServiceCmdToJSON(requestParameters.createAerendeServiceCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   * Skapa ett nytt ärende
   */
  async createAerende(
    requestParameters: CreateAerendeRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.createAerendeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Lägg till en underarbetsplats, för ett ärende
   */
  async createAerendeMedarbetareKontorRaw(
    requestParameters: CreateAerendeMedarbetareKontorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/AerendeCmd/CreateAerendeMedarbetareKontor`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateAerendeMedarbetareKontorCmdToJSON(requestParameters.createAerendeMedarbetareKontorCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   * Lägg till en underarbetsplats, för ett ärende
   */
  async createAerendeMedarbetareKontor(
    requestParameters: CreateAerendeMedarbetareKontorRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.createAerendeMedarbetareKontorRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Skapa ett nytt ärende för avsluta
   */
  async createAvslutaAerendeRaw(
    requestParameters: CreateAvslutaAerendeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/AerendeCmd/CreateAvslutaAerende`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateAvslutaAerendeServiceCmdToJSON(requestParameters.createAvslutaAerendeServiceCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   * Skapa ett nytt ärende för avsluta
   */
  async createAvslutaAerende(
    requestParameters: CreateAvslutaAerendeRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.createAvslutaAerendeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Ta bort en underarbetsplats, för ett ärende
   */
  async deleteAerendeMedarbetareKontorRaw(
    requestParameters: DeleteAerendeMedarbetareKontorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/AerendeCmd/DeleteAerendeMedarbetareKontor`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeleteAerendeMedarbetareKontorCmdToJSON(requestParameters.deleteAerendeMedarbetareKontorCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   * Ta bort en underarbetsplats, för ett ärende
   */
  async deleteAerendeMedarbetareKontor(
    requestParameters: DeleteAerendeMedarbetareKontorRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.deleteAerendeMedarbetareKontorRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Uppdatera värde för dynamisk egenskap, på AerendeMedarbetare
   */
  async updateAerendeMedarbetarePropRaw(
    requestParameters: UpdateAerendeMedarbetarePropRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/AerendeCmd/UpdateAerendeMedarbetareProp`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateAerendeMedarbetarePropCmdToJSON(requestParameters.updateAerendeMedarbetarePropCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   * Uppdatera värde för dynamisk egenskap, på AerendeMedarbetare
   */
  async updateAerendeMedarbetareProp(
    requestParameters: UpdateAerendeMedarbetarePropRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.updateAerendeMedarbetarePropRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
