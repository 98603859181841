export interface KeyItems<T> {
  key: string;
  items: T[];
}

/**
 * Group a collection on the provided key
 * @param list A collection of type T
 * @param key The key to group on, which has to be a propery of T
 * @returns A collection of KeyItems<T>
 */
export function groupBy<T, S extends keyof T>(list: T[], key: S): KeyItems<T>[] {
  const items: KeyItems<T>[] = [];
  const map = new Map();

  list.forEach(item => {
    const collection = map.get(item[key]);
    if (!collection) {
      map.set(item[key], [item]);
    } else {
      collection.push(item);
    }
  });

  map.forEach((value: any[], key: string) => {
    items.push({ key: key, items: value });
  });

  return items;
}
