import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AerendeAktivitetStatusPK } from 'generated-models/anstallningsportalen';
import IAktivitetActionMeddelandeCmdIntegrationEvent from 'models/IAktivitetActionMeddelandeCmdIntegrationEvent';
import { RootState } from 'store/store';
import { IntegrationEventActionType, IntegrationEventWithDTO } from 'utils/eventDispatcher';

export interface AktivitetActionMeddelandeState {
  Meddelande?: string;
  AerendeAktivitetStatusPK?: AerendeAktivitetStatusPK;
}

const initialState: AktivitetActionMeddelandeState = {
  Meddelande: undefined,
  AerendeAktivitetStatusPK: undefined,
};

export const AktivitetActionMeddelandeSlice = createSlice({
  name: 'aktivitetActionMeddelande',
  initialState,
  reducers: {
    removeMessage: state => {
      state.Meddelande = undefined;
      state.AerendeAktivitetStatusPK = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase<
      string,
      PayloadAction<IntegrationEventWithDTO<IAktivitetActionMeddelandeCmdIntegrationEvent, AerendeAktivitetStatusPK>>
    >(IntegrationEventActionType.AktivitetActionMeddelande, (state, action) => {
      state.Meddelande = action.payload.eventData.Meddelande;
      state.AerendeAktivitetStatusPK = action.payload.eventDataKey;
    });
  },
});

export const { removeMessage } = AktivitetActionMeddelandeSlice.actions;

export const selectAktivitetActionMeddelande = (state: RootState) =>
  state.main.aerende.aerendeaktivitet.aktivitetActionMeddelande;

export default AktivitetActionMeddelandeSlice.reducer;
