import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { AerendePK } from 'generated-models/anstallningsportalen/models/AerendePK';
import { ChecklistaListDTO } from 'generated-models/anstallningsportalen/models/ChecklistaListDTO';
import { useSelector } from 'react-redux';
import { AktivitetAction } from './aktivitetAction/AktivitetAction';
import { AktivitetInnehaall } from './AktivitetInnehaall';
import { AktivitetStatus } from './aktivitetStatus/AktivitetStatus';
import { AktivitetTitel } from './AktivitetTitel';
import { selectChecklistorQry } from './qryChecklistor';

interface IAktivitetProps {
  readonly aktivitet: ChecklistaListDTO;
  readonly aerendePK: AerendePK;
}

export const Aktivitet: React.FC<IAktivitetProps> = ({ aktivitet, aerendePK }) => {
  const checklistor = useSelector(selectChecklistorQry(aerendePK));

  const aktivitetIsDone = () => {
    if (RemoteDataFunctions.hasData(checklistor)) {
      var item = checklistor.data.ChecklistaList.find(
        x => x.AerendeAktivitetsmallId === aktivitet.AerendeAktivitetsmallId
      );
      return !!item?.StatusVO?.AerendeAktivitetStatus;
    }
    return false;
  };

  return (
    <>
      <div className="border border-fb-color-grey-table-border odd:border-t-0">
        <div className="flex py-4 px-2 items-start justify-start">
          <div className="w-1/5 px-2">
            <AktivitetTitel done={aktivitetIsDone()} titel={aktivitet.Titel} />
          </div>
          <div className="w-2/5 px-2">
            <AktivitetInnehaall done={aktivitetIsDone()} innehaall={aktivitet.Innehaall} />
          </div>
          <div className="w-1/5 px-2 self-center">
            <AktivitetAction
              done={aktivitetIsDone()}
              actionTyp={aktivitet.ActionTypVO}
              actionUrl={aktivitet.ActionUrl ?? ''}
              actionText={aktivitet.ActionText ?? ''}
              aerendePK={aerendePK}
              aktivitetMallId={aktivitet.AerendeAktivitetsmallId}
              obligatoriska={{
                faelt: aktivitet.AerendeAktivitetsmallObligatoriskaFaelt,
                aktiviteter: aktivitet.AerendeAktivitetsmallObligatoriskaAktiviteter,
              }}
            />
          </div>
          <div className="w-1/5 px-2">
            <AktivitetStatus
              actionTyp={aktivitet.ActionTypVO}
              aerendePK={aerendePK}
              aktivitet={aktivitet}
              obligatoriska={{
                faelt: aktivitet.AerendeAktivitetsmallObligatoriskaFaelt,
                aktiviteter: aktivitet.AerendeAktivitetsmallObligatoriskaAktiviteter,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
