/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AktivitetStatusActionVO
 */
export interface AktivitetStatusActionVO {
  /**
   *
   * @type {number}
   * @memberof AktivitetStatusActionVO
   */
  AktivitetStatusAction?: number;
}

export function AktivitetStatusActionVOFromJSON(json: any): AktivitetStatusActionVO {
  return AktivitetStatusActionVOFromJSONTyped(json, false);
}

export function AktivitetStatusActionVOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AktivitetStatusActionVO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AktivitetStatusAction: !exists(json, 'AktivitetStatusAction') ? undefined : json['AktivitetStatusAction'],
  };
}

export function AktivitetStatusActionVOToJSON(value?: AktivitetStatusActionVO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AktivitetStatusAction: value.AktivitetStatusAction,
  };
}
