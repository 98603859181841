/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AerendeMedarbetarePropTypVO
 */
export interface AerendeMedarbetarePropTypVO {
  /**
   *
   * @type {string}
   * @memberof AerendeMedarbetarePropTypVO
   */
  PropTyp?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeMedarbetarePropTypVO
   */
  PropValue?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AerendeMedarbetarePropTypVO
   */
  readonly IsMandatory?: boolean;
}

export function AerendeMedarbetarePropTypVOFromJSON(json: any): AerendeMedarbetarePropTypVO {
  return AerendeMedarbetarePropTypVOFromJSONTyped(json, false);
}

export function AerendeMedarbetarePropTypVOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AerendeMedarbetarePropTypVO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PropTyp: !exists(json, 'PropTyp') ? undefined : json['PropTyp'],
    PropValue: !exists(json, 'PropValue') ? undefined : json['PropValue'],
    IsMandatory: !exists(json, 'IsMandatory') ? undefined : json['IsMandatory'],
  };
}

export function AerendeMedarbetarePropTypVOToJSON(value?: AerendeMedarbetarePropTypVO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PropTyp: value.PropTyp,
    PropValue: value.PropValue,
  };
}
