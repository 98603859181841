/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AerendeAktivitetsmallObligatoriskaAktiviteterDTO
 */
export interface AerendeAktivitetsmallObligatoriskaAktiviteterDTO {
  /**
   *
   * @type {number}
   * @memberof AerendeAktivitetsmallObligatoriskaAktiviteterDTO
   */
  AerendeAktivitetsmallId: number;
  /**
   *
   * @type {number}
   * @memberof AerendeAktivitetsmallObligatoriskaAktiviteterDTO
   */
  AerendeAktivitetsmallVersion: number;
  /**
   *
   * @type {number}
   * @memberof AerendeAktivitetsmallObligatoriskaAktiviteterDTO
   */
  ObligatoriskAerendeAktivitetsmallId: number;
  /**
   *
   * @type {number}
   * @memberof AerendeAktivitetsmallObligatoriskaAktiviteterDTO
   */
  ObligatoriskAerendeAktivitetsmallVersion: number;
  /**
   *
   * @type {boolean}
   * @memberof AerendeAktivitetsmallObligatoriskaAktiviteterDTO
   */
  HarVaerde: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AerendeAktivitetsmallObligatoriskaAktiviteterDTO
   */
  SaknasIAerendemall: boolean;
  /**
   *
   * @type {string}
   * @memberof AerendeAktivitetsmallObligatoriskaAktiviteterDTO
   */
  Checklista?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeAktivitetsmallObligatoriskaAktiviteterDTO
   */
  Titel?: string | null;
}

export function AerendeAktivitetsmallObligatoriskaAktiviteterDTOFromJSON(
  json: any
): AerendeAktivitetsmallObligatoriskaAktiviteterDTO {
  return AerendeAktivitetsmallObligatoriskaAktiviteterDTOFromJSONTyped(json, false);
}

export function AerendeAktivitetsmallObligatoriskaAktiviteterDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AerendeAktivitetsmallObligatoriskaAktiviteterDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendeAktivitetsmallId: json['AerendeAktivitetsmallId'],
    AerendeAktivitetsmallVersion: json['AerendeAktivitetsmallVersion'],
    ObligatoriskAerendeAktivitetsmallId: json['ObligatoriskAerendeAktivitetsmallId'],
    ObligatoriskAerendeAktivitetsmallVersion: json['ObligatoriskAerendeAktivitetsmallVersion'],
    HarVaerde: json['HarVaerde'],
    SaknasIAerendemall: json['SaknasIAerendemall'],
    Checklista: !exists(json, 'Checklista') ? undefined : json['Checklista'],
    Titel: !exists(json, 'Titel') ? undefined : json['Titel'],
  };
}

export function AerendeAktivitetsmallObligatoriskaAktiviteterDTOToJSON(
  value?: AerendeAktivitetsmallObligatoriskaAktiviteterDTO | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendeAktivitetsmallId: value.AerendeAktivitetsmallId,
    AerendeAktivitetsmallVersion: value.AerendeAktivitetsmallVersion,
    ObligatoriskAerendeAktivitetsmallId: value.ObligatoriskAerendeAktivitetsmallId,
    ObligatoriskAerendeAktivitetsmallVersion: value.ObligatoriskAerendeAktivitetsmallVersion,
    HarVaerde: value.HarVaerde,
    SaknasIAerendemall: value.SaknasIAerendemall,
    Checklista: value.Checklista,
    Titel: value.Titel,
  };
}
