import { dateToAAAAMMDD, FbFormInput } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import aerendemallTypEnum from 'domain/aerendemallTyp';
import { landEnum } from 'domain/land';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectAerendeQry } from '../../qryAerende';
import { IInformationSektionProps } from '../IInformationProps';

const namnKanEjAendrasTooltip =
  'Namn kan inte ändras. Om namnet är fel, avbryt detta ärende och skapa ett nytt, eller be HR-support ändra namn efter att medarbetarens konto skapats.';

export const Personuppgifter: FC<IInformationSektionProps> = ({ aerendePK, aerendemallTypVO }) => {
  const aerende = useSelector(selectAerendeQry(aerendePK));

  const visaTooltipFoerAerendemallTyp = aerendemallTypVO.AerendemallTyp === aerendemallTypEnum.NY;

  return (
    <>
      {RemoteDataFunctions.hasData(aerende) && (
        <div className="flex flex-wrap">
          <FbFormInput
            id="foernamn"
            label="Förnamn"
            defaultValue={aerende.data.Foernamn}
            disabled={true}
            tooltip={visaTooltipFoerAerendemallTyp ? namnKanEjAendrasTooltip : ''}
            tooltipMaxwidth={14.75 * 16}
            className="mr-6 w-48"
          />
          <FbFormInput
            id="efternamn"
            label="Efternamn"
            defaultValue={aerende.data.Efternamn}
            disabled={true}
            tooltip={visaTooltipFoerAerendemallTyp ? namnKanEjAendrasTooltip : ''}
            tooltipMaxwidth={14.75 * 16}
            className="w-48"
          />
          <div className="mt-6 basis-full">
            {aerende.data.NationalitetId === landEnum.SVERIGE ? (
              <FbFormInput
                id="personnummer"
                label="Personnummer"
                defaultValue={aerende.data.PersonnummerVO.Personnummer ?? ''}
                disabled={true}
                tooltip={
                  visaTooltipFoerAerendemallTyp
                    ? 'Personnummer kan inte ändras. Om personnumret är fel, skapa ett nytt ärende och avbryt detta ärende.'
                    : ''
                }
                tooltipMaxwidth={13.875 * 16}
                className="w-48"
              />
            ) : (
              <FbFormInput
                id="foedelsedatum"
                label="Födelsedatum"
                disabled={true}
                type="datum"
                defaultValue={aerende.data.Foedelsedatum ? dateToAAAAMMDD(aerende.data.Foedelsedatum) : ''}
                tooltip={
                  visaTooltipFoerAerendemallTyp
                    ? 'Födelsedatum kan inte ändras. Om födelsedatumet är fel, skapa ett nytt ärende och avbryt detta ärende.'
                    : ''
                }
                tooltipMaxwidth={13.875 * 16}
                className="w-48"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
