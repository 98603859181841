import { AerendePK, ChecklistaListDTO } from 'generated-models/anstallningsportalen';
import { FC } from 'react';
import { KeyItems } from 'utils/arrayUtils';
import { Aktivitet } from './Aktivitet';

interface IAktivitetGruppProps {
  readonly checklistaItems: KeyItems<ChecklistaListDTO>;
  readonly aerendePK: AerendePK;
}

export const AktivitetGrupp: FC<IAktivitetGruppProps> = ({ checklistaItems, aerendePK }) => (
  <div className="mb-4 last:mb-2">
    <h4 className="h-8">{checklistaItems.key}</h4>
    <>
      {checklistaItems.items.map(item => (
        <Aktivitet key={item.AerendeAktivitetsmallId} aktivitet={item} aerendePK={aerendePK} />
      ))}
    </>
  </div>
);
