/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  MedarbetareRollVO,
  MedarbetareRollVOFromJSON,
  MedarbetareRollVOFromJSONTyped,
  MedarbetareRollVOToJSON,
} from './MedarbetareRollVO';
import {
  PersonnummerVO,
  PersonnummerVOFromJSON,
  PersonnummerVOFromJSONTyped,
  PersonnummerVOToJSON,
} from './PersonnummerVO';

/**
 *
 * @export
 * @interface MedarbetarListDTO
 */
export interface MedarbetarListDTO {
  /**
   *
   * @type {number}
   * @memberof MedarbetarListDTO
   */
  PersonId: number;
  /**
   *
   * @type {PersonnummerVO}
   * @memberof MedarbetarListDTO
   */
  PersonnummerVO: PersonnummerVO;
  /**
   *
   * @type {string}
   * @memberof MedarbetarListDTO
   */
  Foernamn: string;
  /**
   *
   * @type {string}
   * @memberof MedarbetarListDTO
   */
  Efternamn: string;
  /**
   *
   * @type {number}
   * @memberof MedarbetarListDTO
   */
  KontorId: number;
  /**
   *
   * @type {MedarbetareRollVO}
   * @memberof MedarbetarListDTO
   */
  MedarbetareRollVO: MedarbetareRollVO;
  /**
   *
   * @type {boolean}
   * @memberof MedarbetarListDTO
   */
  JobbarPaaFleraKontor: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MedarbetarListDTO
   */
  JobbarPaaIckeBehoerigtKontor: boolean;
  /**
   *
   * @type {Date}
   * @memberof MedarbetarListDTO
   */
  Foedelsedatum?: Date | null;
  /**
   *
   * @type {number}
   * @memberof MedarbetarListDTO
   */
  BostadsMarknadId?: number | null;
  /**
   *
   * @type {number}
   * @memberof MedarbetarListDTO
   */
  NationalitetId?: number | null;
}

export function MedarbetarListDTOFromJSON(json: any): MedarbetarListDTO {
  return MedarbetarListDTOFromJSONTyped(json, false);
}

export function MedarbetarListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedarbetarListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PersonId: json['PersonId'],
    PersonnummerVO: PersonnummerVOFromJSON(json['PersonnummerVO']),
    Foernamn: json['Foernamn'],
    Efternamn: json['Efternamn'],
    KontorId: json['KontorId'],
    MedarbetareRollVO: MedarbetareRollVOFromJSON(json['MedarbetareRollVO']),
    JobbarPaaFleraKontor: json['JobbarPaaFleraKontor'],
    JobbarPaaIckeBehoerigtKontor: json['JobbarPaaIckeBehoerigtKontor'],
    Foedelsedatum: !exists(json, 'Foedelsedatum')
      ? undefined
      : json['Foedelsedatum'] === null
      ? null
      : new Date(json['Foedelsedatum']),
    BostadsMarknadId: !exists(json, 'BostadsMarknadId') ? undefined : json['BostadsMarknadId'],
    NationalitetId: !exists(json, 'NationalitetId') ? undefined : json['NationalitetId'],
  };
}

export function MedarbetarListDTOToJSON(value?: MedarbetarListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PersonId: value.PersonId,
    PersonnummerVO: PersonnummerVOToJSON(value.PersonnummerVO),
    Foernamn: value.Foernamn,
    Efternamn: value.Efternamn,
    KontorId: value.KontorId,
    MedarbetareRollVO: MedarbetareRollVOToJSON(value.MedarbetareRollVO),
    JobbarPaaFleraKontor: value.JobbarPaaFleraKontor,
    JobbarPaaIckeBehoerigtKontor: value.JobbarPaaIckeBehoerigtKontor,
    Foedelsedatum:
      value.Foedelsedatum === undefined
        ? undefined
        : value.Foedelsedatum === null
        ? null
        : value.Foedelsedatum.toISOString(),
    BostadsMarknadId: value.BostadsMarknadId,
    NationalitetId: value.NationalitetId,
  };
}
