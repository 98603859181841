import { AerendePK, ChecklistaListDTO } from 'generated-models/anstallningsportalen';
import React from 'react';
import { KeyItems } from 'utils/arrayUtils';
import { AktivitetGrupp } from './AktivitetGrupp';

interface IChecklistaProps {
  readonly checklistaItems: ChecklistaListDTO[];
  readonly aerendePK: AerendePK;
}

/**
 * Gruppera aktiviteterna till rubriken, men de som saknar rubrik ska inte grupperas ihop
 */
function groupByRubrik(list: ChecklistaListDTO[]) {
  const initialValue: KeyItems<ChecklistaListDTO>[] = [];

  var result = list.reduce((previousValue: KeyItems<ChecklistaListDTO>[], currentValue: ChecklistaListDTO) => {
    if (!currentValue.Rubrik) {
      const newItems: ChecklistaListDTO[] = [currentValue];
      previousValue.push({ key: '', items: newItems });
    } else {
      const existing = previousValue.find(pv => pv.key === currentValue.Rubrik);
      if (existing) {
        existing.items.push(currentValue);
      } else {
        const newItems: ChecklistaListDTO[] = [currentValue];
        previousValue.push({ key: currentValue.Rubrik, items: newItems });
      }
    }
    return previousValue;
  }, initialValue);

  return result;
}

export const Checklista: React.FC<IChecklistaProps> = ({ checklistaItems, aerendePK }) => (
  <>
    {groupByRubrik(checklistaItems).map((items, index) => (
      <AktivitetGrupp key={index} checklistaItems={items} aerendePK={aerendePK} />
    ))}
  </>
);

export default Checklista;
