import { ErrorMessage } from '@decernointernal/websd.shared';
import { reset, selectLastRedirectTime, timeLimitSeconds } from 'azureAD/AzureADLoginTimerReducer';
import { msalScopes, publicClientApplication } from 'azureAD/msalConfig';
import {
  decreaseOngoingTransactionCount,
  increaseOngoingTransactionCount,
} from 'components/aerende/navigateAway/navigateAwayReducer';
import { Notifikation, addNotifikation } from 'components/overlay/FbOverlayReducer';
import { FetchParams, Middleware, RequestContext, ResponseContext } from 'generated-models/anstallningsportalen';
import store from 'store/store';
import { ApiClient } from './apiClient';
import { storedispatch } from './storeDispatch';

interface ApiErrorResponse {
  readonly ErrorCode: string;
  readonly ErrorMessage: string;
  readonly ValidationErrors: ReadonlyArray<{
    readonly Entry?: string;
    readonly Property?: string;
    readonly UserMessage: string;
  }> | null;
}

interface ValidationError {
  readonly entry?: string;
  readonly property: string;
  readonly userMessage: string;
}

let errorIdCounter = 0;

const nextErrorId = () => ++errorIdCounter;

const safeJsonParse = <T = any>(text: string): T | undefined => {
  try {
    return JSON.parse(text);
  } catch (err) {
    return undefined;
  }
};

const isApiErrorResponse = (errorObject: any): errorObject is ApiErrorResponse =>
  errorObject && (errorObject.ErrorCode || errorObject.ValidationErrors);

const getApiErrorMessage = (error: ApiErrorResponse) => error.ErrorMessage || null;

const getApiValidationErrors = (error: ApiErrorResponse): ValidationError[] =>
  error.ValidationErrors
    ? error.ValidationErrors.map(
        ve =>
          ({
            entry: ve.Entry,
            property: 'Property' in ve ? ve.Property : 'PropertyName' in ve ? (ve as any).PropertyName : null,
            userMessage: ve.UserMessage,
          } as ValidationError)
      )
    : [];

const errorFormatter = (
  // tslint:disable-next-line:no-any
  error: { message?: string | any; Message?: string | any } | string | any,
  method: string,
  endpoint: string,
  status: number,
  statusText: string
): ErrorMessage => {
  const http = {
    method,
    endpoint,
    status: status,
    statusText,
  };

  if (isApiErrorResponse(error)) {
    return {
      id: nextErrorId(),
      errorCode: error.ErrorCode,
      message: getApiErrorMessage(error),
      validationErrors: getApiValidationErrors(error),
      http: http,
    };
  }
  // specialare
  else if (
    typeof error === 'object' &&
    error.Errors &&
    error.Errors[0] &&
    error.Errors[0].PropertyName === 'Personnummer'
  ) {
    return {
      id: nextErrorId(),
      errorCode: 'Personnummer',
      validationErrors: [],
      message: error.Errors[0].UserMessage,
      http: http,
    };
  } else if (
    typeof error === 'object' &&
    error.Errors &&
    error.Errors[0] &&
    error.Errors[0].PropertyName === 'NationelltIDNummer'
  ) {
    return {
      id: nextErrorId(),
      errorCode: 'NationelltIDNummer',
      validationErrors: [],
      message: error.Errors[0].UserMessage,
      http: http,
    };
  } else if (typeof error === 'object' && typeof error.message === 'string') {
    return {
      id: nextErrorId(),
      errorCode: null,
      validationErrors: [],
      message: error.message,
      http: http,
    };
  } else if (typeof error === 'object' && typeof error.Message === 'string') {
    return {
      id: nextErrorId(),
      errorCode: null,
      validationErrors: [],
      message: error.Message,
      http: http,
    };
  } else if (typeof error === 'string') {
    return {
      id: nextErrorId(),
      errorCode: null,
      validationErrors: [],
      message: error,
      http: http,
    };
  }

  return {
    id: nextErrorId(),
    errorCode: null,
    validationErrors: [],
    message: null,
    http: http,
  };
};

export const parseErrorResponse = async (error: any, method?: string): Promise<ErrorMessage> => {
  const response = error as Response;
  const requestMethod = method ? method : '';
  const input = response.url;
  let text: string;
  try {
    text = await response.text();
  } catch (texterr) {
    return errorFormatter(texterr as any, requestMethod, input, response.status, response.statusText);
  }
  const json = safeJsonParse(text);
  return errorFormatter(json || text, requestMethod, input, response.status, response.statusText);
};

export const errorMiddleware: Middleware = {
  post: async (context: ResponseContext): Promise<Response | void> => {
    if (context.response.ok || context.url.toLowerCase().includes('kibana')) {
      return context.response;
    }

    const err = await parseErrorResponse(context.response, context.init.method);
    if ([403, 409].some(x => x === err.http?.status)) {
      // FB-40338 utökat att använda Status409Conflict som möjlighet att specialhantera fel från server
      // TODO daer: We can use this enpoint to react to validatonerrors from the server
      return;
    } else {
      new ApiClient().KibanaApi.postApiError({
        apiErrorMessage: {
          Http: {
            Endpoint: context.url,
            Method: context.init.method,
            Status: context.response.statusText,
          },
          Message: err.message,
          ErrorCode: err.errorCode,
        },
      });
      const notifikation: Notifikation = {
        titel: 'Hej! Något i Anställningsportalen gick precis snett.',
        text: err.message
          ? [err.message]
          : err.validationErrors && err.validationErrors.length > 0
          ? err.validationErrors.map(v => v.userMessage)
          : undefined,
        errorCode: err.errorCode ?? undefined,
        nivaa: 'error',
      };
      storedispatch(addNotifikation(notifikation));
    }
  },
};

export const authenticateMiddleware: Middleware = {
  pre: async (context: RequestContext): Promise<void | FetchParams> => {
    const activeAccount = publicClientApplication.getAllAccounts();
    if (activeAccount) {
      const timePassedSinceRedirect = new Date().getTime() - selectLastRedirectTime(store.getState());
      if (timePassedSinceRedirect >= timeLimitSeconds * 1000) {
        await publicClientApplication.loginRedirect();
        return;
      }

      // Retrieve an access token
      try {
        const response = await publicClientApplication.acquireTokenSilent({
          account: activeAccount[0],
          scopes: msalScopes,
        });

        if (response.accessToken) {
          storedispatch(reset());
          context.init.headers = { ...context.init.headers, Authorization: 'Bearer ' + response.accessToken };
        }
      } catch (error) {}
    }

    return context;
  },
};

/**
 * Håll en räknare uppdaterad när det finns pågående transaktioner mot API´t
 * Eftersom de flesta qry använder GET kommer dessa att tolkas som transaktioner och således påverka räknaren.
 */
export const ongoingTransactionMiddleware: Middleware = {
  pre: async (context: RequestContext): Promise<void | FetchParams> => {
    if (context.init.method !== 'GET') {
      storedispatch(increaseOngoingTransactionCount());
    }
  },
  post: async (context: RequestContext): Promise<Response | void> => {
    if (context.init.method !== 'GET') {
      storedispatch(decreaseOngoingTransactionCount());
    }
  },
};
