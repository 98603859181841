import {
  clickOutside,
  FbAlert,
  FbButton,
  FbButtonState,
  FbHtmlText,
  FbModal,
  FbPopover,
} from '@decernointernal/fb-interna-komponenter';
import { Command, CommandFunctions, ErrorMessage } from '@decernointernal/websd.shared';
import { faCircle, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { actionTypEnum } from 'domain/actionTyp';
import {
  ActionTypVO,
  AerendeAktivitetStatusPK,
  AerendePK,
  UtfoerAktivitetServiceCmd,
} from 'generated-models/anstallningsportalen';
import { AerendeAktivitetsmallObligatoriskaAktiviteterDTO } from 'generated-models/anstallningsportalen/models/AerendeAktivitetsmallObligatoriskaAktiviteterDTO';
import { AerendeAktivitetsmallObligatoriskaFaeltDTO } from 'generated-models/anstallningsportalen/models/AerendeAktivitetsmallObligatoriskaFaeltDTO';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import { MailToWrapper } from '../../../MailToWrapper';
import { removeMessage, selectAktivitetActionMeddelande } from './aktivitetActionMeddelandeReducer';
import { resetErrorState, selectUtfoerAktivitetServiceCmd, utfoerAktivitetAction } from './cmdUtfoerAktivitet';

interface IAktivitetActionProps {
  readonly done: boolean;
  readonly actionTyp: ActionTypVO;
  readonly actionUrl: string;
  readonly actionText: string;
  readonly aerendePK: AerendePK;
  readonly aktivitetMallId: number;
  readonly obligatoriska: {
    faelt?: AerendeAktivitetsmallObligatoriskaFaeltDTO[] | null;
    aktiviteter?: AerendeAktivitetsmallObligatoriskaAktiviteterDTO[] | null;
  };
}

const formatFaelt = (faelt: string) => {
  switch (faelt.toLowerCase()) {
    case 'mobiltelefonnummer':
      return `Kontaktuppgifter arbete - ${faelt}`;
    default:
      return '';
  }
};

const formatAktivitet = (aktivitet: AerendeAktivitetsmallObligatoriskaAktiviteterDTO) => {
  return `${aktivitet.Checklista} - ${aktivitet.Titel}`;
};

export const AktivitetAction: React.FC<IAktivitetActionProps> = ({
  done,
  actionTyp,
  actionUrl,
  actionText,
  aerendePK,
  aktivitetMallId,
  obligatoriska,
}) => {
  const primaryKey = VOCreate<AerendeAktivitetStatusPK>({
    AerendeId: aerendePK.AerendeId,
    AerendeAktivitetsMallId: aktivitetMallId,
  });
  const utfoerAktivitetStatus = useSelector(selectUtfoerAktivitetServiceCmd(primaryKey));
  const dispatch = useAppDispatch();
  const message = useSelector(selectAktivitetActionMeddelande);
  const bekraeftaUtfoerAktivitetPopoverButtonRef = useRef<HTMLDivElement | null>(null);
  const {
    ref: utfoerIrreversibelAktivitetPopoverRef,
    isComponentVisible: utfoerIrreversibelAktivitetExpandedState,
    setIsComponentVisible: setUtfoerIrreversibelAktivitetExpandedState,
  } = clickOutside(false, [bekraeftaUtfoerAktivitetPopoverButtonRef]);

  const handleCloseInfo = () => {
    dispatch(removeMessage());
  };

  const handleCloseDanger = () => {
    dispatch(resetErrorState(primaryKey));
  };

  const utfoerAktivitet = () => {
    dispatch(
      utfoerAktivitetAction({
        AerendeAktivitetStatusPK: { AerendeId: aerendePK.AerendeId, AerendeAktivitetsMallId: aktivitetMallId },
        ActionTypVO: actionTyp,
      })
    );
  };

  function GetFbButtonWithBekraeftelsePopover(
    utfoerAktivitetStatus: Command<UtfoerAktivitetServiceCmd, ErrorMessage>,
    utfoerAktivitet: () => void,
    actionText: string,
    done: boolean
  ) {
    let buttonState: FbButtonState = 'default';
    let buttonText = actionText;
    if (CommandFunctions.isExecuting(utfoerAktivitetStatus)) {
      buttonState = 'waiting';
    } else if (CommandFunctions.isFailure(utfoerAktivitetStatus)) {
      buttonState = 'failure';
      buttonText = 'Försök igen';
    }
    return (
      <div className="relative inline-block">
        <FbButton
          buttonState={buttonState}
          className="mb-4"
          onClick={() => setUtfoerIrreversibelAktivitetExpandedState(!utfoerIrreversibelAktivitetExpandedState)}
          text={buttonText}
          type={'primary'}
          disabled={done}
        />
        <FbPopover
          header="Är du säker på att du vill fortsätta?"
          showPopover={utfoerIrreversibelAktivitetExpandedState}
          popoverSize="small"
          popoverRef={utfoerIrreversibelAktivitetPopoverRef}
        >
          <p>När du har gjort detta så kan du inte längre ändra några uppgifter här i Anställningsportalen.</p>
          <div className="flex justify-end px-4 pb-4 mt-6">
            <FbButton
              className="mr-4"
              text={'Avbryt'}
              type={'secondary'}
              buttonState={CommandFunctions.isExecuting(utfoerAktivitetStatus) ? 'waiting' : 'default'}
              onClick={() => setUtfoerIrreversibelAktivitetExpandedState(false)}
            />
            <FbButton
              text={'Ja'}
              type={'primary'}
              onClick={() => {
                utfoerAktivitet();
                setUtfoerIrreversibelAktivitetExpandedState(false);
              }}
            />
          </div>
        </FbPopover>
      </div>
    );
  }

  if (
    (obligatoriska.faelt && obligatoriska.faelt.some(of => !of.HarVaerde)) ||
    (obligatoriska.aktiviteter && obligatoriska.aktiviteter.some(of => !of.HarVaerde && !of.SaknasIAerendemall))
  ) {
    return (
      <>
        {!done && (
          <div>
            <h4>
              <FontAwesomeIcon icon={faLock} size="lg" className="text-fb-color-orange" /> Låst aktivitet
            </h4>
            {obligatoriska.faelt && obligatoriska.faelt.some(of => !of.HarVaerde) && (
              <>
                <p className="leading-tight font-sans mt-2 mb-1">Information saknas:</p>
                <ul className="font-sans text-sm list-none">
                  {obligatoriska.faelt
                    .filter(of => !of.HarVaerde)
                    .map((of, index) => {
                      return (
                        <li key={index} className="flex items-center">
                          <FontAwesomeIcon icon={faCircle} className="text-[4px] mr-2" />
                          {formatFaelt(of.Faelt)}
                        </li>
                      );
                    })}
                </ul>
              </>
            )}
            {obligatoriska.aktiviteter &&
              obligatoriska.aktiviteter.some(of => !of.HarVaerde && !of.SaknasIAerendemall) && (
                <>
                  <p className="leading-tight font-sans mt-2 mb-1">Aktiviteter att utföra:</p>
                  <ul className="font-sans text-sm list-none">
                    {obligatoriska.aktiviteter
                      .filter(of => !of.HarVaerde)
                      .map((of, index) => {
                        return (
                          <li key={index} className="flex items-center">
                            <FontAwesomeIcon icon={faCircle} className="text-[4px] mr-2" />
                            {formatAktivitet(of)}
                          </li>
                        );
                      })}
                  </ul>
                </>
              )}
          </div>
        )}
        {actionTyp.ActionTyp === actionTypEnum.BESTAELL_KONTO && (
          <div className="mt-4">
            {GetFbButtonWithBekraeftelsePopover(utfoerAktivitetStatus, utfoerAktivitet, actionText, true)}
          </div>
        )}
      </>
    );
  } else {
    switch (actionTyp.ActionTyp) {
      case actionTypEnum.URL: {
        return (
          <FbHtmlText
            className={`${done ? 'text-fb-grey-dark-2' : ''}`}
            text={`<a href=${actionUrl} target="_blank" class="${done ? 'text-fb-grey-dark-2' : ''}">${actionText}</a>`}
          />
        );
      }
      case actionTypEnum.MAILTO_KNAPP: {
        return (
          <MailToWrapper actionUrl={actionUrl} done={done}>
            <FbButton type="primary" text={actionText} icon={faEnvelope} disabled={done} />
          </MailToWrapper>
        );
      }
      case actionTypEnum.BESTAELL_KONTO: {
        return (
          <>
            {GetFbButtonWithBekraeftelsePopover(utfoerAktivitetStatus, utfoerAktivitet, actionText, done)}

            {message.AerendeAktivitetStatusPK?.AerendeAktivitetsMallId === aktivitetMallId &&
              message.AerendeAktivitetStatusPK?.AerendeId === aerendePK.AerendeId && (
                <div className="mt-2">
                  <FbModal buttonCancelText="Ok" onCancel={() => handleCloseInfo()}>
                    <FbAlert type="info" text={message.Meddelande ?? ''} />
                  </FbModal>
                </div>
              )}
            {CommandFunctions.isFailure(utfoerAktivitetStatus) && utfoerAktivitetStatus.err.http?.status === 409 && (
              <div className="mt-2">
                <FbModal buttonCancelText="Ok" onCancel={() => handleCloseDanger()}>
                  <FbAlert type="danger" text={utfoerAktivitetStatus.err.message ?? ''} />
                </FbModal>
              </div>
            )}
          </>
        );
      }
      case actionTypEnum.AVSLUTA_KONTO: {
        return <>{GetFbButtonWithBekraeftelsePopover(utfoerAktivitetStatus, utfoerAktivitet, actionText, done)}</>;
      }
      default: {
        return <p className={`${done ? 'text-fb-grey-dark-2' : ''}`}>{actionText}</p>;
      }
    }
  }
};
