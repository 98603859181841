import {
  createWebDataSingletonList,
  ErrorMessage,
  RemoteData,
  RemoteDataFunctions,
  RemoteDataSingeltonList,
  WebDataListState,
} from '@decernointernal/websd.shared';
import { LandListDTO } from 'generated-models/anstallningsportalen/models/LandListDTO';
import { AnyAction, Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction } from 'utils/eventDispatcher';
const ActionTypeLandList = '[Land] Get Landlist';

const landListQry = createWebDataSingletonList<typeof ActionTypeLandList, LandListDTO[]>(
  ActionTypeLandList,
  'Landlist'
);

type landListQryState = WebDataListState<LandListDTO[]>;

const maplandListQryState: MapState<landListQryState> = rootState => rootState.main.home.aerendeList.qryLandList;

export const selectlandListQry = () => (state: RootState) => landListQry.get(maplandListQryState(state));

export const landListQryAction = (): ThunkAction => async (dispatch, getState) => {
  const webData = landListQry.get(maplandListQryState(getState()));
  LandListInternalQryAction(webData, landListQry, dispatch);
};

const LandListInternalQryAction = async (
  webData: RemoteData<LandListDTO[], ErrorMessage>,
  webSdQry: RemoteDataSingeltonList<string, LandListDTO[], string, ErrorMessage>,
  dispatch: (action: AnyAction) => void
) => {
  if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
    return;
  }
  // Call api and handle result
  try {
    if (RemoteDataFunctions.hasData(webData)) {
      dispatch(webSdQry.updating(webData.data));
    } else {
      dispatch(webSdQry.loading());
    }

    const result = await new ApiClient().LandQryApi.getLandList({});
    dispatch(webSdQry.success(result));
  } catch (error) {
    dispatch(webSdQry.failure(await parseErrorResponse(error)));
  }
};

export const landListQryReducer: Reducer<landListQryState, IntegrationEventAction> = (state = {}, action) => {
  return landListQry.reducer(state, action);
};
