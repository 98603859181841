import { FC } from 'react';
import { Header } from '../../shared-components/Header';
import { Personnamn } from '../../shared-components/Personnamn';
import { Personnummer } from '../../shared-components/Personnummer';
import { PlaneradFoerstaArbetsdag } from '../../shared-components/PlaneradFoerstaArbetsdag';

export const AnstaellEnMedarbetareIUppdragsutbildning: FC = () => {
  return (
    <>
      <Header rubrik="Personinformation" className="mt-6" />

      <Personnummer />
      <Personnamn />
      <PlaneradFoerstaArbetsdag />
    </>
  );
};
