/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import { LandListDTO, LandListDTOFromJSON, LandListDTOToJSON } from '../models';

/**
 *
 */
export class LandQryApi extends runtime.BaseAPI {
  /**
   * Hämta alla tillgängliga länder
   */
  async getLandListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<LandListDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/LandQry/GetLandList`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(LandListDTOFromJSON));
  }

  /**
   * Hämta alla tillgängliga länder
   */
  async getLandList(initOverrides?: RequestInit): Promise<Array<LandListDTO>> {
    const response = await this.getLandListRaw(initOverrides);
    return await response.value();
  }
}
