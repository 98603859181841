import { FC } from 'react';

interface IHeader {
  readonly rubrik: string;
  readonly id?: string;
  readonly className?: string;
}

const Elem = (propsExt: any) => {
  const { className, ...props } = propsExt;
  return (
    <h4 className={className} {...props}>
      {props.children}
    </h4>
  );
};

export const Header: FC<IHeader> = ({ rubrik, id, className }) => {
  return id ? (
    <Elem id={id} className={className}>
      {rubrik}
    </Elem>
  ) : (
    <Elem className={className}>{rubrik}</Elem>
  );
};
