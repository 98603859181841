import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { AerendePK } from 'generated-models/anstallningsportalen';
import { CreateAvslutaAerendeServiceCmd } from 'generated-models/anstallningsportalen/models/CreateAvslutaAerendeServiceCmd';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventDataTypes, dispatchIntegrationEvents, parseEvent } from 'utils/eventDispatcher';
import { setCreatedAerendeId } from './uiCreatedAerendeId';
const ActionType = '[Aerende] create AvslutaAerende';

const createAvslutaAerendeCmd = createWebCommandSingletonList<typeof ActionType, CreateAvslutaAerendeServiceCmd>(
  ActionType,
  ActionType
);

export const selectCreateAvslutaAerendeCmd = (state: RootState) =>
  createAvslutaAerendeCmd.get(state.main.home.aerendeList.skapaNyttAerende.cmdCreateAvslutaAerende);

export const resetCreateAvslutaAerendeCmdState = (): ThunkAction => async dispatch => {
  dispatch(createAvslutaAerendeCmd.remove());
};

export const createAvslutaAerendeAction =
  (cmd: CreateAvslutaAerendeServiceCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectCreateAvslutaAerendeCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(createAvslutaAerendeCmd.executing());

      const result = await new ApiClient().AerendeCmdApi.createAvslutaAerende({ createAvslutaAerendeServiceCmd: cmd });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Let interested components know the id of the created "Aerende"
      const event = result.find(e => {
        return e.EventDataType === 'Aerende' ? e : undefined;
      });
      if (event) {
        const parsedEvent = parseEvent<AerendePK>(event.EventDataType as IntegrationEventDataTypes, event);
        dispatch(setCreatedAerendeId(parsedEvent.eventDataKey));
      }

      // Handle success response for command
      dispatch(createAvslutaAerendeCmd.success(cmd));
    } catch (err) {
      // Behöver vi sätta validationErrors i ErrorMessage (från websd) för att hantera direkta fel som t.ex. att personnummer är upptaget?

      dispatch(createAvslutaAerendeCmd.failure(await parseErrorResponse(err)));
    }
  };

export default createAvslutaAerendeCmd.reducer;
