import { AerendeAktivitetStatusVO } from 'generated-models/anstallningsportalen/models/AerendeAktivitetStatusVO';

export enum aerendeAktivitetStatusEnum {
  ATT_GOERA = '0',
  AVKLARAD = '1',
  KOMMER_EJ_GOERAS = '2',
}

export const getAerendeAktivitetStatusDomain = (
  vo: AerendeAktivitetStatusVO | undefined
): aerendeAktivitetStatusEnum => {
  switch (vo?.AerendeAktivitetStatus) {
    case undefined:
      return aerendeAktivitetStatusEnum.ATT_GOERA;
    case 0:
      return aerendeAktivitetStatusEnum.ATT_GOERA;
    case 1:
      return aerendeAktivitetStatusEnum.AVKLARAD;
    case 2:
      return aerendeAktivitetStatusEnum.KOMMER_EJ_GOERAS;
    default:
      throw new Error(`Fel?!! ${vo?.AerendeAktivitetStatus}`);
  }
};

export default aerendeAktivitetStatusEnum;
