/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AerendeMedarbetareKontorPK,
  AerendeMedarbetareKontorPKFromJSON,
  AerendeMedarbetareKontorPKFromJSONTyped,
  AerendeMedarbetareKontorPKToJSON,
} from './AerendeMedarbetareKontorPK';

/**
 *
 * @export
 * @interface CreateAerendeMedarbetareKontorCmd
 */
export interface CreateAerendeMedarbetareKontorCmd {
  /**
   *
   * @type {AerendeMedarbetareKontorPK}
   * @memberof CreateAerendeMedarbetareKontorCmd
   */
  PrimaryKey: AerendeMedarbetareKontorPK;
}

export function CreateAerendeMedarbetareKontorCmdFromJSON(json: any): CreateAerendeMedarbetareKontorCmd {
  return CreateAerendeMedarbetareKontorCmdFromJSONTyped(json, false);
}

export function CreateAerendeMedarbetareKontorCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateAerendeMedarbetareKontorCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PrimaryKey: AerendeMedarbetareKontorPKFromJSON(json['PrimaryKey']),
  };
}

export function CreateAerendeMedarbetareKontorCmdToJSON(value?: CreateAerendeMedarbetareKontorCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PrimaryKey: AerendeMedarbetareKontorPKToJSON(value.PrimaryKey),
  };
}
