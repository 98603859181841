import { FbButtonState, FbFormRadiobuttonGroup, FbModal } from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions } from '@decernointernal/websd.shared';
import { aerendemallTypEnum } from 'domain/aerendemallTyp';
import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { aerendeInformationRoute } from 'store/location';
import { useAppDispatch } from 'store/store';
import AnstaellEnPerson from './aerendeMallTyper/anstaellEnPerson/AnstaellEnPerson';
import AvslutaEnAnstaellning from './aerendeMallTyper/avslutaEnAnstaellning/AvslutaEnAnstaellning';
import { LaeggTillExternMedarbetare } from './aerendeMallTyper/laeggTillExternMedarbetare/LaeggTillExternMedarbetare';
import { resetCreateAerendeCmdState } from './cmdCreateAerende';
import { resetCreateAvslutaAerendeCmdState } from './cmdCreateAvslutaAerende';
import { skapaNyttAerendeStateReducer } from './skapaNyttAerendeStateReducer';
import { selectCreatedAerendeId } from './uiCreatedAerendeId';

interface ISkapaNyttAerende {
  onCancel: () => void;
}

export const SkapaNyttAerende: React.FC<ISkapaNyttAerende> = ({ onCancel }) => {
  const [aerendemallTyp, setAerendemallTyp] = React.useState<aerendemallTypEnum | null>(null);
  const [skapaNyttAerendeState, skapaNyttAerendeStateDispatch] = useReducer(skapaNyttAerendeStateReducer, {});

  const dispatch = useAppDispatch();
  const uiCreatedAerendeId = useSelector(selectCreatedAerendeId());

  useEffect(() => {
    if (
      skapaNyttAerendeState.payloadCommand &&
      CommandFunctions.isSuccess(skapaNyttAerendeState.payloadCommand) &&
      uiCreatedAerendeId.AerendePK
    ) {
      dispatch(resetCreateAerendeCmdState());
      dispatch(resetCreateAvslutaAerendeCmdState());
      dispatch(aerendeInformationRoute(uiCreatedAerendeId.AerendePK));
    } else if (skapaNyttAerendeState.forceAbort) {
      dispatch(resetCreateAerendeCmdState());
      dispatch(resetCreateAvslutaAerendeCmdState());
      onCancel();
    }
  }, [skapaNyttAerendeState, dispatch, uiCreatedAerendeId, onCancel]);

  const createNewAerende = () => {
    if (
      skapaNyttAerendeState.command &&
      skapaNyttAerendeState.isValid &&
      skapaNyttAerendeState.payloadCommand &&
      (CommandFunctions.isNotExecuted(skapaNyttAerendeState.payloadCommand) ||
        CommandFunctions.isFailure(skapaNyttAerendeState.payloadCommand))
    ) {
      skapaNyttAerendeStateDispatch({ type: 'createAerende', payload: { dispatch } });
    }
  };

  const onSkapaNyttAerende = () => {
    createNewAerende();
  };

  const onCloseModal = () => {
    dispatch(resetCreateAerendeCmdState());
    dispatch(resetCreateAvslutaAerendeCmdState());
    onCancel();
  };

  const getButtonStateFraanCommandStatus = (): FbButtonState => {
    switch (skapaNyttAerendeState.payloadCommand?.status) {
      case 'Executing':
        return 'waiting';
      case 'Failure':
        return 'failure';
      case 'Success':
        return 'success';
      default:
        return 'default';
    }
  };

  return (
    <FbModal
      titel="Skapa nytt ärende"
      buttonConfirmText="Skapa ärende"
      buttonCancelText="Avbryt"
      onConfirm={onSkapaNyttAerende}
      onCancel={onCloseModal}
      disabled={!skapaNyttAerendeState || !skapaNyttAerendeState.isValid}
      confirmButtonState={getButtonStateFraanCommandStatus()}
    >
      <div className="pr-4">
        <FbFormRadiobuttonGroup
          groupname="aerendetypgrupp"
          label="Vad ska du göra?"
          className="mb-6"
          onChange={(value: string) => {
            setAerendemallTyp(parseInt(value));
          }}
          radioButtons={[
            {
              id: aerendemallTypEnum.NY.toString(),
              value: aerendemallTypEnum.NY.toString(),
              label: 'Anställa ny medarbetare',
            },
            {
              id: aerendemallTypEnum.EXTERN_MEDARBETARE.toString(),
              value: aerendemallTypEnum.EXTERN_MEDARBETARE.toString(),
              label: 'Lägga till extern medarbetare',
            },
            {
              id: aerendemallTypEnum.AVSLUT.toString(),
              value: aerendemallTypEnum.AVSLUT.toString(),
              label: 'Avsluta anställning',
            },
          ]}
        />
        {aerendemallTyp === aerendemallTypEnum.NY && (
          <AnstaellEnPerson skapaNyttAerendeStateDispatch={skapaNyttAerendeStateDispatch} />
        )}
        {aerendemallTyp === aerendemallTypEnum.EXTERN_MEDARBETARE && <LaeggTillExternMedarbetare />}
        {aerendemallTyp === aerendemallTypEnum.AVSLUT && (
          <AvslutaEnAnstaellning skapaNyttAerendeStateDispatch={skapaNyttAerendeStateDispatch} />
        )}
      </div>
    </FbModal>
  );
};

export default SkapaNyttAerende;
