import {
  createWebDataSingletonList,
  ErrorMessage,
  RemoteData,
  RemoteDataFunctions,
  RemoteDataSingeltonList,
  WebDataListState,
} from '@decernointernal/websd.shared';
import { MaeklarKedjaDTO } from 'generated-models/anstallningsportalen';
import { AnyAction } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
const ActionTypeMaeklarkedjaList = '[maeklarkedja] Get maeklarkedjalist';

const maeklarkedjaListQry = createWebDataSingletonList<typeof ActionTypeMaeklarkedjaList, MaeklarKedjaDTO[]>(
  ActionTypeMaeklarkedjaList,
  'maeklarkedjalist'
);

type MaeklarKedjaListQryState = WebDataListState<MaeklarKedjaDTO[]>;

const mapMaeklarkedjalistQryState: MapState<MaeklarKedjaListQryState> = rootState =>
  rootState.main.aerende.maeklarkedjalist;

export const selectMaeklarkedjalistQry = () => (state: RootState) =>
  maeklarkedjaListQry.get(mapMaeklarkedjalistQryState(state));

export const maeklarkedjaListQryAction = (): ThunkAction => async (dispatch, getState) => {
  const webData = maeklarkedjaListQry.get(mapMaeklarkedjalistQryState(getState()));
  maeklarkedjaListInternalQryAction(webData, maeklarkedjaListQry, dispatch);
};

export const maeklarkedjaQryReducer = maeklarkedjaListQry.reducer;

const maeklarkedjaListInternalQryAction = async (
  webData: RemoteData<MaeklarKedjaDTO[], ErrorMessage>,
  webSdQry: RemoteDataSingeltonList<string, MaeklarKedjaDTO[], string, ErrorMessage>,
  dispatch: (action: AnyAction) => void
) => {
  if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
    return;
  }
  // Call api and handle result
  try {
    if (RemoteDataFunctions.hasData(webData)) {
      dispatch(webSdQry.updating(webData.data));
    } else {
      dispatch(webSdQry.loading());
    }

    const result = await new ApiClient().MaeklarKedjaQryApi.getMaeklarkedjor({});
    dispatch(webSdQry.success(result));
  } catch (error) {
    dispatch(webSdQry.failure(await parseErrorResponse(error)));
  }
};
