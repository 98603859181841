import { dateToAAAAMMDD, FbFormInput } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectAerendeQry } from '../../qryAerende';
import { IInformationSektionProps } from '../IInformationProps';

const formatDateAsYYYYMMDDHHmm = (date: Date) => {
  var aammdd = dateToAAAAMMDD(date);

  var hours = date.getHours();
  var minutes = date.getMinutes();

  return `${aammdd} ${hours <= 9 ? '0' : ''}${hours}:${minutes <= 9 ? '0' : ''}${minutes}`;
};

export const Om: FC<IInformationSektionProps> = ({ aerendePK }) => {
  const aerende = useSelector(selectAerendeQry(aerendePK));

  return (
    <>
      {RemoteDataFunctions.hasData(aerende) && (
        <>
          <div className="pb-6">
            <FbFormInput
              id="skapatAv"
              label="Skapat av"
              disabled={true}
              className="w-96"
              defaultValue={aerende.data.BestaellareMejladress ?? ''}
            />
          </div>
          <div className="pb-6">
            <FbFormInput
              id="skapatDatum"
              label="Skapat den"
              disabled={true}
              className="w-96"
              defaultValue={formatDateAsYYYYMMDDHHmm(aerende.data.Skapatdatum)}
            />
          </div>
          <div className="pb-6">
            <FbFormInput
              id="skickatTillHRSystemet"
              label="Skickat till HR-systemet"
              disabled={true}
              className="w-96"
              defaultValue={
                aerende.data.SkickatTillHRSystemetDatum
                  ? formatDateAsYYYYMMDDHHmm(aerende.data.SkickatTillHRSystemetDatum)
                  : 'Nej'
              }
            />
          </div>
        </>
      )}
    </>
  );
};
