import {
  ActionUnion,
  createAction,
  RemoteDataFunctions,
  RemoteDataStatus,
  SdKeys,
  WebData,
} from '@decernointernal/websd.shared';
import IAerendeAktivitetStatus from 'components/aerende/checklista/aktivitetStatus/IAerendeAktivitetStatus';
import {
  AerendeMedarbetareKontorPK,
  AerendePK,
  SdDbOperation,
  SdIntegrationEvent,
} from 'generated-models/anstallningsportalen/models';
import { AerendeAktivitetStatusPK } from 'generated-models/anstallningsportalen/models/AerendeAktivitetStatusPK';
import IAktivitetActionMeddelandeCmdIntegrationEvent from 'models/IAktivitetActionMeddelandeCmdIntegrationEvent';
import ICreateAerendeMedarbetareKontorCmdIntegrationEvent from 'models/ICreateAerendeMedarbetareKontorCmdIntegrationEvent';
import IUpdateAerendeMedarbetarePropCmdIntegrationEvent from 'models/IUpdateAerendeMedarbetarePropCmdIntegrationEvent';
import { Dispatch } from 'redux';

/**
 * This is our definition of which integration events the client handles at the moment
 */
export enum IntegrationEventActionType {
  Aerende = '[integration-event] Aerende',
  AerendeMedarbetare = '[integration-event] AerendeMedarbetare',
  UpdateAerendeMedarbetareProp = '[integration-event] UpdateAerendeMedarbetareProp',
  AerendeAktivitetStatus = '[integration-event] AerendeAktivitetStatus',
  DeleteAerendeMedarbetareKontor = '[integration-event] AerendeMedarbetareKontor - delete',
  CreateAerendeMedarbetareKontor = '[integration-event] AerendeMedarbetareKontor - create',
  AktivitetActionMeddelande = '[integration-event] AktivitetActionMeddelande',
}

export enum IntegrationEventDataTypes {
  Aerende = 'Aerende',
  UpdateAerendeMedarbetareProp = 'UpdateAerendeMedarbetareProp',
  AerendeMedarbetare = 'AerendeMedarbetare',
  AerendeAktivitetStatus = 'AerendeAktivitetStatus',
  AerendeMedarbetareKontor = 'AerendeMedarbetareKontor',
  CreateAerendeMedarbetareKontor = 'CreateAerendeMedarbetareKontor',
  AktivitetActionMeddelande = 'AktivitetActionMeddelande',
}

type IntegrationEvent<EventDataKeyType> = {
  eventDataType: string;
  eventDataKey: EventDataKeyType;
  operation: SdDbOperation;
};

export type IntegrationEventWithDTO<EventDataType, EventKeyDataType> = {
  eventDataType: string;
  eventData: EventDataType;
  eventDataKey: EventKeyDataType;
  operation: SdDbOperation;
};

export function parseEvent<EventKeyDataType extends object>(
  eventDataType: IntegrationEventDataTypes,
  event: SdIntegrationEvent
): IntegrationEvent<EventKeyDataType> {
  const eventDataKey = SdKeys.keyToValue<EventKeyDataType>(event.EventDataKey);
  return { eventDataType, eventDataKey, operation: event.Operation };
}

/**
 * Use to parse events which are transformed into DTO:s for the client and the result can therefore be directly
 * applied to the redux store
 * @param eventDataType
 * @param event
 * @returns
 */
export function parseEventWithDTO<EventDataType extends object, EventKeyDataType extends object>(
  eventDataType: IntegrationEventDataTypes,
  event: SdIntegrationEvent
): IntegrationEventWithDTO<EventDataType, EventKeyDataType> {
  const eventData = event.EventData ? JSON.parse(event.EventData) : undefined;
  const eventDataKey = SdKeys.keyToValue<EventKeyDataType>(event.EventDataKey);
  return { eventDataType: eventDataType, eventData, eventDataKey, operation: event.Operation };
}

/**
 * Update this with new integration events which the client need to react on
 */
const integrationEventAction = (_: string, eventDataType: IntegrationEventDataTypes, event: SdIntegrationEvent) => {
  switch (eventDataType) {
    case 'Aerende':
      return createAction(IntegrationEventActionType.Aerende, parseEvent<AerendePK>(eventDataType, event));
    case 'AerendeMedarbetare':
      return createAction(IntegrationEventActionType.AerendeMedarbetare, parseEvent<AerendePK>(eventDataType, event));
    case 'UpdateAerendeMedarbetareProp':
      return createAction(
        IntegrationEventActionType.UpdateAerendeMedarbetareProp,
        parseEventWithDTO<IUpdateAerendeMedarbetarePropCmdIntegrationEvent, AerendePK>(eventDataType, event)
      );
    case 'AerendeMedarbetareKontor':
      if (event.Operation === SdDbOperation.Delete) {
        return createAction(
          IntegrationEventActionType.DeleteAerendeMedarbetareKontor,
          parseEvent<AerendeMedarbetareKontorPK>(eventDataType, event)
        );
      } else {
        return createAction('undefined');
      }
    case 'CreateAerendeMedarbetareKontor':
      return createAction(
        IntegrationEventActionType.CreateAerendeMedarbetareKontor,
        parseEventWithDTO<ICreateAerendeMedarbetareKontorCmdIntegrationEvent, AerendeMedarbetareKontorPK>(
          eventDataType,
          event
        )
      );
    case 'AerendeAktivitetStatus':
      return createAction(
        IntegrationEventActionType.AerendeAktivitetStatus,
        parseEventWithDTO<IAerendeAktivitetStatus, AerendeAktivitetStatusPK>(eventDataType, event)
      );
    case 'AktivitetActionMeddelande':
      return createAction(
        IntegrationEventActionType.AktivitetActionMeddelande,
        parseEventWithDTO<IAktivitetActionMeddelandeCmdIntegrationEvent, AerendeAktivitetStatusPK>(eventDataType, event)
      );
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unregognized EventDataType: '${eventDataType}'`);
      return createAction('undefined');
  }
};

/**
 * Dispatch events returned from a command to invalidate queries
 * @param dispatch reference to dispatch function from redux
 * @param serializedEvents the list of serialized events
 */
export const dispatchIntegrationEvents = (
  dispatch: Dispatch,
  serializedEvents: ReadonlyArray<SdIntegrationEvent>
): void => {
  const eventActions = serializedEvents.map(event =>
    integrationEventAction(event.EventDataType, event.EventDataType as IntegrationEventDataTypes, event)
  );
  eventActions.filter(x => x.type !== 'undefined').map(dispatch);
};

export type IntegrationEventAction = ActionUnion<typeof integrationEventAction>;

/**
 * Sets a webdata slice to stale to show that data needs to be refetched
 * @param webData
 * @returns
 */
export function webDataSetStale<DT>(webData: WebData<DT>): WebData<DT> {
  if (
    !RemoteDataFunctions.hasData(webData) ||
    RemoteDataFunctions.isStale(webData) ||
    RemoteDataFunctions.isUpdating(webData)
  ) {
    return webData;
  }
  return {
    ...webData,
    status: RemoteDataStatus.Stale,
  };
}

export const createCustomIntegrationEvent = <T>(
  eventDataType: IntegrationEventDataTypes,
  eventData: T,
  eventDataKey: object,
  eventKeyDataType: string,
  operation: SdDbOperation = SdDbOperation.Unchanged,
  pointInTime: Date = new Date()
): SdIntegrationEvent => {
  return {
    EventDataType: eventDataType,
    EventData: JSON.stringify(eventData),
    EventDataKey: JSON.stringify(eventDataKey),
    EventKeyDataType: eventKeyDataType,
    Operation: operation,
    PointInTime: pointInTime,
  };
};
