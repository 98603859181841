import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ApiClient } from 'utils/apiClient';
import { logClientComponentErrorAsRedBalloon } from 'utils/loggingUtils';

/**
 * Configuration for Msal.js
 */
const config: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_AD_AUTHORITY,
    redirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URI,
  },
  system: {
    iframeHashTimeout: 30000,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            logClientComponentErrorAsRedBalloon('AzureAD: msalConfig.ts', 'AzureAD Error: ' + JSON.stringify(message));
            break;
          case LogLevel.Warning:
            new ApiClient().KibanaApi.postClientComponentError({
              clientComponentErrorMessage: {
                ComponentName: 'AzureAD: msalConfig.ts',
                Message: 'AzureAD Warning: ' + JSON.stringify(message),
              },
            });
            break;
          case LogLevel.Info:
            break;
          case LogLevel.Trace:
            break;
          case LogLevel.Verbose:
            break;
          default:
            break;
        }
      },
    },
  },
};

export const publicClientApplication = new PublicClientApplication(config);
export const msalScopes = [process.env.REACT_APP_AZURE_AD_SCOPE];
