import { FbFormRadiobuttonGroup } from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions } from '@decernointernal/websd.shared';
import { actionTypEnum } from 'domain/actionTyp';
import { aerendeAktivitetActionEnum } from 'domain/aerendeAktivitetAction';
import aerendeAktivitetStatusEnum, { getAerendeAktivitetStatusDomain } from 'domain/aerendeAktivitetStatus';
import { ActionTypVO, AerendePK, ChecklistaListDTO } from 'generated-models/anstallningsportalen';
import { AerendeAktivitetStatusVO } from 'generated-models/anstallningsportalen/models/AerendeAktivitetStatusVO';
import { AerendeAktivitetsmallObligatoriskaAktiviteterDTO } from 'generated-models/anstallningsportalen/models/AerendeAktivitetsmallObligatoriskaAktiviteterDTO';
import { AerendeAktivitetsmallObligatoriskaFaeltDTO } from 'generated-models/anstallningsportalen/models/AerendeAktivitetsmallObligatoriskaFaeltDTO';
import { AktivitetStatusActionVO } from 'generated-models/anstallningsportalen/models/AktivitetStatusActionVO';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import {
  saveAerendeAktivitetStatusAction,
  selectsaveAerendeAktivitetStatusServiceCmd,
} from './cmdSaveAerendeAktivitetStatus';

interface IAktivitetStatusProps {
  readonly actionTyp: ActionTypVO;
  readonly aerendePK: AerendePK;
  readonly aktivitet: ChecklistaListDTO;
  readonly obligatoriska: {
    faelt?: AerendeAktivitetsmallObligatoriskaFaeltDTO[] | null;
    aktiviteter?: AerendeAktivitetsmallObligatoriskaAktiviteterDTO[] | null;
  };
}

export const AktivitetStatus: FC<IAktivitetStatusProps> = ({ actionTyp, aerendePK, aktivitet, obligatoriska }) => {
  const dispatch = useAppDispatch();
  const aerendeAktivitetsmallId = aktivitet.AerendeAktivitetsmallId;
  const aktivitetStatus = getAerendeAktivitetStatusDomain(aktivitet.StatusVO);
  const aerAttGoera = aktivitetStatus === aerendeAktivitetStatusEnum.ATT_GOERA;
  const saveAerendeAktivitetStatusServiceCmd = useSelector(selectsaveAerendeAktivitetStatusServiceCmd);
  const finnsObligatorisktEjIfylltFaeltEllerEjAvklaradAktivitet =
    (obligatoriska.faelt && obligatoriska.faelt.some(f => !f.HarVaerde)) ||
    (obligatoriska.aktiviteter && obligatoriska.aktiviteter.some(a => !a.HarVaerde && !a.SaknasIAerendemall));

  const saveChecklistActivity = (value: string, aerendeAktivitetsmallId: number, aerendePK: AerendePK) => {
    dispatch(
      saveAerendeAktivitetStatusAction({
        AerendeAktivitetStatusPK: { AerendeId: aerendePK.AerendeId, AerendeAktivitetsMallId: aerendeAktivitetsmallId },
        AerendeAktivitetStatusVO: VOCreate<AerendeAktivitetStatusVO>({
          AerendeAktivitetStatus: value === aerendeAktivitetStatusEnum.ATT_GOERA ? null : +value,
        }),
        AktivitetStatusActionVO: getActionType(
          getAerendeAktivitetStatusDomain(
            aktivitet.StatusVO ??
              VOCreate<AerendeAktivitetStatusVO>({
                AerendeAktivitetStatus: parseInt(aerendeAktivitetStatusEnum.ATT_GOERA),
              })
          ),
          getAerendeAktivitetStatusDomain(VOCreate<AerendeAktivitetStatusVO>({ AerendeAktivitetStatus: +value }))
        ),
      })
    );
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <FbFormRadiobuttonGroup
        disabled={CommandFunctions.isExecuting(saveAerendeAktivitetStatusServiceCmd)}
        groupname={`aktivitetstatus-${aerendeAktivitetsmallId}`}
        radioButtons={[
          {
            id: `0-${aerendeAktivitetsmallId}`,
            value: '',
            label: 'Att göra',
            disabled:
              (aktivitet.Irreversibel && !aerAttGoera) || !!finnsObligatorisktEjIfylltFaeltEllerEjAvklaradAktivitet,
            defaultChecked: aktivitetStatus === aerendeAktivitetStatusEnum.ATT_GOERA,
            onChange: () => {
              saveChecklistActivity(aerendeAktivitetStatusEnum.ATT_GOERA, aerendeAktivitetsmallId, aerendePK);
            },
          },
          {
            id: `1-${aerendeAktivitetsmallId}`,
            value: '',
            label: 'Avklarad',
            disabled:
              (aktivitet.Irreversibel && !aerAttGoera) ||
              aktivitet.ActionTypVO.ActionTyp === actionTypEnum.BESTAELL_KONTO ||
              aktivitet.ActionTypVO.ActionTyp === actionTypEnum.AVSLUTA_KONTO ||
              !!finnsObligatorisktEjIfylltFaeltEllerEjAvklaradAktivitet,
            defaultChecked: aktivitetStatus === aerendeAktivitetStatusEnum.AVKLARAD,
            onChange: () => {
              saveChecklistActivity(aerendeAktivitetStatusEnum.AVKLARAD, aerendeAktivitetsmallId, aerendePK);
            },
          },
          {
            id: `2-${aerendeAktivitetsmallId}`,
            value: '',
            label: 'Kommer ej göras',
            disabled:
              aktivitet.Obligatorisk ||
              (aktivitet.Irreversibel && !aerAttGoera) ||
              !!finnsObligatorisktEjIfylltFaeltEllerEjAvklaradAktivitet,
            defaultChecked: aktivitetStatus === aerendeAktivitetStatusEnum.KOMMER_EJ_GOERAS,
            onChange: () => {
              saveChecklistActivity(aerendeAktivitetStatusEnum.KOMMER_EJ_GOERAS, aerendeAktivitetsmallId, aerendePK);
            },
          },
        ]}
      />
    </div>
  );
};

function getActionType(currentStatus: aerendeAktivitetStatusEnum, newStatus: aerendeAktivitetStatusEnum) {
  if (currentStatus === aerendeAktivitetStatusEnum.ATT_GOERA) {
    return VOCreate<AktivitetStatusActionVO>({ AktivitetStatusAction: aerendeAktivitetActionEnum.CREATE });
  }
  if (
    currentStatus === aerendeAktivitetStatusEnum.AVKLARAD ||
    currentStatus === aerendeAktivitetStatusEnum.KOMMER_EJ_GOERAS
  ) {
    if (newStatus === aerendeAktivitetStatusEnum.ATT_GOERA) {
      return VOCreate<AktivitetStatusActionVO>({ AktivitetStatusAction: aerendeAktivitetActionEnum.DELETE });
    }
    return VOCreate<AktivitetStatusActionVO>({ AktivitetStatusAction: aerendeAktivitetActionEnum.UPDATE });
  }
  throw new Error('Fel vid byte av status.');
}
