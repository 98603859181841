/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AerendeChecklistorDTO,
  AerendeChecklistorDTOFromJSON,
  AerendeChecklistorDTOToJSON,
  ChecklistaDTOQry,
  ChecklistaDTOQryFromJSON,
  ChecklistaDTOQryToJSON,
} from '../models';

export interface GetChecklistorRequest {
  checklistaDTOQry?: ChecklistaDTOQry;
}

/**
 *
 */
export class ChecklistaQryApi extends runtime.BaseAPI {
  /**
   * Hämta Checklistor
   */
  async getChecklistorRaw(
    requestParameters: GetChecklistorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AerendeChecklistorDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://57cff08f-eddb-4a10-bfa2-ad1244495155/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/ChecklistaQry/GetChecklistor`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ChecklistaDTOQryToJSON(requestParameters.checklistaDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => AerendeChecklistorDTOFromJSON(jsonValue));
  }

  /**
   * Hämta Checklistor
   */
  async getChecklistor(
    requestParameters: GetChecklistorRequest = {},
    initOverrides?: RequestInit
  ): Promise<AerendeChecklistorDTO> {
    const response = await this.getChecklistorRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
