import {
  FbAlert,
  FbFormDropdown,
  FbFormDropdownValue,
  FbSpinner,
  scrollToElement,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { getUserQryAction, selectUserQry } from 'components/user/qryUser';
import { aerendeMallNyAnstaellning } from 'domain/aerendeMall';
import { bostadsMarknadEnum } from 'domain/bostadsMarknad';
import { kontorTypEnum } from 'domain/kontorTypEnum';
import { landEnum } from 'domain/land';
import { medarbetareRollEnum } from 'domain/medarbetareRoll';
import { AerendemallVO, PersonnummerVO } from 'generated-models/anstallningsportalen';
import { MedarbetareRollVO } from 'generated-models/anstallningsportalen/models/MedarbetareRollVO';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import INyttAerende from '../../INyttAerende';
import { createAerendeAction, selectCreateAerendeCmd } from '../../cmdCreateAerende';
import { NyttAerendeContext } from '../../context/NyttAerendeContext';
import { Header } from '../../shared-components/Header';
import FormInputWrapper from '../FormInputWrapper';
import ISkapaNyttAerendeStateDispatchProps from '../ISkapaNyttAerendeStateDispatchProps';
import { AnstaellEnBlivandeFastighetsmaeklare } from './AnstaellEnBlivandeFastighetsmaeklare';
import { AnstaellEnFastighetsmaeklare } from './AnstaellEnFastighetsmaeklare';
import { AnstaellEnHKMedarbetare } from './AnstaellEnHKMedarbetare';
import { AnstaellEnKoordinator } from './AnstaellEnKoordinator';
import { AnstaellEnMaeklarpraktikant } from './AnstaellEnMaeklarpraktikant';
import { AnstaellEnMedarbetareIUppdragsutbildning } from './AnstaellEnMedarbetareIUppdragsutbildning';
import { AnstaellEnUtlandsperson } from './AnstaellEnUtlandsperson';

interface IAnstaellEnPerson extends ISkapaNyttAerendeStateDispatchProps {}

export const AnstaellEnPerson: React.FC<IAnstaellEnPerson> = ({ skapaNyttAerendeStateDispatch }) => {
  const [nyttAerendeState, setNyttAerendeState] = useState<INyttAerende>({
    aerendeMall: VOCreate<AerendemallVO>({ AerendemallId: aerendeMallNyAnstaellning.ONBOARDING_AV_FASTIGHETSMAEKLARE }),
    aerFaerdigutbildad: false,
    foernamn: '',
    efternamn: '',
    personnummer: '',
    medarbetareroll: VOCreate<MedarbetareRollVO>({ MedarbetareRoll: null }),
    valideraInteFoedelsedatum: false,
  });
  const [showRollInfo, setShowRollInfo] = useState(true);
  var userHeader = useSelector(selectUserQry());
  const createAerende = useSelector(selectCreateAerendeCmd);
  const dispatch = useAppDispatch();

  useEffect(() => {
    skapaNyttAerendeStateDispatch({
      type: 'setCreateAerendeCommandStatus',
      payload: { payloadCommand: createAerende },
    });
  }, [skapaNyttAerendeStateDispatch, createAerende]);

  const arbetsplatser = useMemo((): FbFormDropdownValue[] => {
    var ap: FbFormDropdownValue[] = [];

    if (RemoteDataFunctions.hasData(userHeader)) {
      userHeader.data.Kontor.forEach(k => {
        if (k.KontorId && k.KontorNamn) {
          ap.push({ id: k.KontorId, value: k.KontorNamn });
        }
      });
    }

    return ap.sort((a, b) => a.value.localeCompare(b.value));
  }, [userHeader]);

  const bostadsMarknadId = useMemo((): number | null | undefined => {
    if (RemoteDataFunctions.hasData(userHeader)) {
      return userHeader.data.Kontor.find(k => k.KontorId === nyttAerendeState.huvudarbetsplatsId)?.BostadsMarknadId;
    }

    return undefined;
  }, [nyttAerendeState.huvudarbetsplatsId, userHeader]);

  const kontorTyp = useMemo((): number | null | undefined => {
    if (RemoteDataFunctions.hasData(userHeader)) {
      return userHeader.data.Kontor.find(k => k.KontorId === nyttAerendeState.huvudarbetsplatsId)?.KontorsTyp;
    }

    return undefined;
  }, [nyttAerendeState.huvudarbetsplatsId, userHeader]);

  const aerAvdelningVald = useMemo((): boolean => {
    return !!nyttAerendeState.huvudarbetsplatsId && kontorTyp === kontorTypEnum.Avdelning;
  }, [nyttAerendeState.huvudarbetsplatsId, kontorTyp]);

  useEffect(() => {
    setNyttAerendeState(prevstate => {
      return {
        ...prevstate,
        huvudarbetsplatsId:
          arbetsplatser.length === 1 && arbetsplatser[0].id ? (arbetsplatser[0].id as number) : undefined,
      };
    });
  }, [setNyttAerendeState, arbetsplatser]);

  useEffect(() => {
    skapaNyttAerendeStateDispatch({
      type: 'setCreateAerendeCommandAndAction',
      payload: {
        commandAction: createAerendeAction,
        command:
          nyttAerendeState &&
          nyttAerendeState.huvudarbetsplatsId &&
          nyttAerendeState.foernamn &&
          nyttAerendeState.efternamn &&
          nyttAerendeState.planeradFoerstaArbetsdag &&
          ((bostadsMarknadId === bostadsMarknadEnum.SVERIGE &&
            nyttAerendeState.personnummer &&
            nyttAerendeState.medarbetareroll) ||
            (bostadsMarknadId === bostadsMarknadEnum.UTLAND &&
              nyttAerendeState.nationelltIDNummer &&
              nyttAerendeState.nationalitetId &&
              ((nyttAerendeState.nationalitetId === landEnum.SVERIGE && nyttAerendeState.personnummer) ||
                (nyttAerendeState.nationalitetId !== landEnum.SVERIGE &&
                  nyttAerendeState.koen &&
                  nyttAerendeState.koen.Koen))) ||
            aerAvdelningVald)
            ? {
                AerendemallVO: nyttAerendeState.aerendeMall,
                KontorId: nyttAerendeState.huvudarbetsplatsId,
                Foernamn: nyttAerendeState.foernamn,
                Efternamn: nyttAerendeState.efternamn,
                Personnummer: VOCreate<PersonnummerVO>({
                  Personnummer: nyttAerendeState.personnummer ? nyttAerendeState.personnummer : null,
                }),
                MedarbetareRollVO:
                  nyttAerendeState.medarbetareroll.MedarbetareRoll !== -1
                    ? nyttAerendeState.medarbetareroll
                    : VOCreate<MedarbetareRollVO>({ MedarbetareRoll: null }),
                Startdatum: nyttAerendeState.planeradFoerstaArbetsdag,
                NationalitetId: nyttAerendeState.nationalitetId,
                Koen: nyttAerendeState.koen,
                Foedelsedatum: nyttAerendeState.foedelsedatum,
                NationelltIDNummer: nyttAerendeState.nationelltIDNummer,
              }
            : undefined,
      },
    });

    /*
      Vi vill att sparningen ska göras en gång till om användaren valt att skapa användaren, 
      trots varningen om att det redan finns minst en annan person med samma födelsedatum
     */
    if (nyttAerendeState.valideraInteFoedelsedatum && CommandFunctions.isFailure(createAerende)) {
      skapaNyttAerendeStateDispatch({ type: 'createAerende', payload: { dispatch } });
    }
  }, [
    nyttAerendeState,
    aerAvdelningVald,
    skapaNyttAerendeStateDispatch,
    arbetsplatser,
    bostadsMarknadId,
    createAerende,
    dispatch,
  ]);

  useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(userHeader) || RemoteDataFunctions.isStale(userHeader)) {
      dispatch(getUserQryAction());
    }
  }, [userHeader, dispatch]);

  useEffect(() => {
    let aerendeMallId = aerendeMallNyAnstaellning.ONBOARDING_AV_FASTIGHETSMAEKLARE;

    switch (nyttAerendeState.medarbetareroll?.MedarbetareRoll) {
      case medarbetareRollEnum.FEI_MEDARBETARE: {
        aerendeMallId = aerendeMallNyAnstaellning.FEI_MEDARBETARE;
        break;
      }
      case medarbetareRollEnum.BLIVANDEMAEKLARE: {
        aerendeMallId = aerendeMallNyAnstaellning.ONBOARDING_AV_BLIVANDE_MAEKLARE;
        break;
      }
      case medarbetareRollEnum.MAEKLARPRAKTIKANT: {
        aerendeMallId = aerendeMallNyAnstaellning.ONBOARDING_AV_MAEKLARPRAKTIKANT;
        break;
      }
      case medarbetareRollEnum.MAEKLARASSISTENT: {
        aerendeMallId = aerendeMallNyAnstaellning.ONBOARDING_AV_KOORDINATOR;
        break;
      }
      case medarbetareRollEnum.HK_MEDARBETARE: {
        aerendeMallId = aerendeMallNyAnstaellning.ONBOARDING_AV_HK_MEDARBETARE;
        break;
      }
      case medarbetareRollEnum.SAELJARE:
      case medarbetareRollEnum.INNESAELJARE:
      case -1: {
        aerendeMallId = aerendeMallNyAnstaellning.NYANSTAELLNING_UTLAND;
        break;
      }
      default: {
        break;
      }
    }

    setNyttAerendeState(prevState => {
      return { ...prevState, aerendeMall: VOCreate<AerendemallVO>({ AerendemallId: aerendeMallId }) };
    });
  }, [nyttAerendeState.medarbetareroll]);

  useEffect(() => {
    setNyttAerendeState(prevState => {
      return {
        ...prevState,
        medarbetareroll: VOCreate<MedarbetareRollVO>({
          MedarbetareRoll: aerAvdelningVald ? medarbetareRollEnum.HK_MEDARBETARE : null,
        }),
      };
    });
  }, [nyttAerendeState.huvudarbetsplatsId, aerAvdelningVald]);

  useEffect(() => {
    if (nyttAerendeState.medarbetareroll) {
      setShowRollInfo(false);
    } else if (nyttAerendeState.huvudarbetsplatsId) {
      setShowRollInfo(true);
    }
    return;
  }, [nyttAerendeState.huvudarbetsplatsId, nyttAerendeState.medarbetareroll]);

  const getRoller = (): FbFormDropdownValue[] => {
    const roller: FbFormDropdownValue[] = [];

    // Avdelning = de som saknar bostadsmarknad
    // Filtrera bort alla roller förutom HK-medarbetare, på avdelningar
    // Filtrera bort HK-medarbetare, på kontor
    if (nyttAerendeState.huvudarbetsplatsId && RemoteDataFunctions.hasData(userHeader)) {
      if (!aerAvdelningVald) {
        const bostadsMarknadId = userHeader.data.Kontor.find(
          k => k.KontorId === nyttAerendeState.huvudarbetsplatsId
        )?.BostadsMarknadId;
        if (bostadsMarknadId === bostadsMarknadEnum.UTLAND) {
          roller.push(
            { id: medarbetareRollEnum.SAELJARE, value: 'Säljare' },
            { id: medarbetareRollEnum.INNESAELJARE, value: 'Intagare' },
            { id: -1, value: 'Övrigt' }
          );
        } else {
          roller.push(
            { id: medarbetareRollEnum.FASTIGHETSMAEKLARE, value: 'Fastighetsmäklare' },
            { id: medarbetareRollEnum.FEI_MEDARBETARE, value: 'Medarbetare i uppdragsutbildning' },
            { id: medarbetareRollEnum.BLIVANDEMAEKLARE, value: 'Blivande mäklare' },
            { id: medarbetareRollEnum.MAEKLARPRAKTIKANT, value: 'Mäklarpraktikant' },
            { id: medarbetareRollEnum.MAEKLARASSISTENT, value: 'Koordinator' }
          );
        }
      } else {
        roller.push({ id: medarbetareRollEnum.HK_MEDARBETARE, value: 'HK-medarbetare' });
      }
    }

    return roller;
  };

  return (
    <>
      {!RemoteDataFunctions.hasData(userHeader) && <FbSpinner size="medium" />}
      {RemoteDataFunctions.hasData(userHeader) && (
        <div className="mb-8">
          <Header rubrik="Anställning" id="anstallning" />
          <div className="fb-paragraph-lvl-2 my-2">
            <FbAlert type="info" text="Roll och huvudarbetsplats kan inte ändras efter att ärendet skapats" />
          </div>
          <FormInputWrapper>
            <FbFormDropdown
              id="huvudarbetsplats"
              label="Huvudarbetsplats"
              tooltipPosition="above"
              required={true}
              values={arbetsplatser}
              defaultId={arbetsplatser.length === 1 ? arbetsplatser[0].id : undefined}
              onFocus={() => {
                if (!nyttAerendeState?.huvudarbetsplatsId) {
                  scrollToElement('anstallning', 0, undefined, 0, 'modalScrollContainer');
                }
              }}
              onBlur={(value?: FbFormDropdownValue) => {
                setNyttAerendeState(prevState => {
                  return {
                    ...prevState,
                    huvudarbetsplatsId: value?.id ? (value.id as number) : undefined,
                  };
                });
              }}
              expandScroll={true}
            />
          </FormInputWrapper>

          {nyttAerendeState?.huvudarbetsplatsId && (
            <NyttAerendeContext.Provider value={{ nyttAerendeState, setNyttAerendeState }}>
              <>
                <div className="flex items-end mb-2">
                  <div className="w-1/2">
                    <FbFormDropdown
                      className="inline-block"
                      id="roll"
                      label="Roll"
                      tooltipPosition="above"
                      required={true}
                      defaultId={
                        nyttAerendeState.medarbetareroll && nyttAerendeState.medarbetareroll.MedarbetareRoll
                          ? nyttAerendeState.medarbetareroll.MedarbetareRoll
                          : undefined
                      }
                      values={getRoller()}
                      onBlur={(value?: FbFormDropdownValue) => {
                        setNyttAerendeState(prevState => {
                          return {
                            ...prevState,
                            medarbetareroll: VOCreate<MedarbetareRollVO>({
                              MedarbetareRoll: value && value.id ? (value.id as number) : null,
                            }),
                          };
                        });
                      }}
                    />
                  </div>
                  {nyttAerendeState.medarbetareroll && !showRollInfo && !aerAvdelningVald && (
                    <a
                      className="ml-4 text-sm"
                      role="button"
                      href="/"
                      onClick={e => {
                        setShowRollInfo(true);
                        e.preventDefault();
                      }}
                    >
                      Läs mer om roller
                    </a>
                  )}
                </div>

                {showRollInfo && !aerAvdelningVald && (
                  <div className="fb-paragraph-lvl-2 mt-4 mb-2">
                    <FbAlert type="info" onDismiss={() => setShowRollInfo(false)}>
                      <div className="flex flex-col gap-4">
                        <h4>Välj rätt roll</h4>

                        {bostadsMarknadId === bostadsMarknadEnum.UTLAND ? (
                          <>
                            <p className="text-sm text-fb-color-typography font-normal leading-tight">
                              <span className="font-bold leading-tight">Säljare – </span>utlandsmäklare eller
                              certifierad utlandsmäklare godkänd av FB Academy, kandidat som sköter kundkontakten med
                              köpkund.
                            </p>
                            <p className="text-sm text-fb-color-typography font-normal leading-tight">
                              <span className="font-bold leading-tight">Intagare – </span> intagare eller certifierad
                              utlandsmäklare godkänd av FB Academy, kandidat som sköter kundkontakten med säljkund.
                            </p>
                            <p className="text-sm text-fb-color-typography font-normal leading-tight">
                              <span className="font-bold leading-tight">Övrigt – </span> kandidat som har hand om after
                              sale, fotograf, koordinator, receptionist, jurist, ekonom o.s.v.
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="text-sm text-fb-color-typography font-normal leading-tight">
                              <span className="font-bold leading-tight">Fastighetsmäklare – </span>endast en kandidat
                              som sedan tidigare är registrerad som Fastighetsmäklare
                            </p>

                            <p className="text-sm text-fb-color-typography font-normal leading-tight">
                              <span className="font-bold leading-tight">Medarbetare i uppdragsutbildning – </span>
                              mäklarpraktikant som studerar via Högskolan Väst eller FEI, uppdragsutbildningen
                            </p>

                            <p className="text-sm text-fb-color-typography font-normal leading-tight">
                              <span className="font-bold leading-tight">Blivande mäklare – </span>
                              endast en kandidat som är godkänd på alla tentor och skall skicka in sin ansökan om
                              registrering, ansvarsförsäkring ska tecknas
                            </p>

                            <p className="text-sm text-fb-color-typography font-normal leading-tight">
                              <span className="font-bold leading-tight">Mäklarpraktikant – </span>Studerar på högskola
                            </p>

                            <p className="text-sm text-fb-color-typography font-normal leading-tight">
                              <span className="font-bold leading-tight">Koordinator – </span>
                              alla andra roller. Titel kan ändras via HR-support efter att kontot skapats.
                            </p>
                          </>
                        )}
                      </div>
                    </FbAlert>
                  </div>
                )}

                {nyttAerendeState.medarbetareroll?.MedarbetareRoll === medarbetareRollEnum.FASTIGHETSMAEKLARE && (
                  <AnstaellEnFastighetsmaeklare />
                )}
                {nyttAerendeState.medarbetareroll?.MedarbetareRoll === medarbetareRollEnum.BLIVANDEMAEKLARE && (
                  <AnstaellEnBlivandeFastighetsmaeklare />
                )}
                {nyttAerendeState.medarbetareroll?.MedarbetareRoll === medarbetareRollEnum.FEI_MEDARBETARE && (
                  <AnstaellEnMedarbetareIUppdragsutbildning />
                )}
                {nyttAerendeState.medarbetareroll?.MedarbetareRoll === medarbetareRollEnum.MAEKLARPRAKTIKANT && (
                  <AnstaellEnMaeklarpraktikant />
                )}
                {nyttAerendeState.medarbetareroll?.MedarbetareRoll === medarbetareRollEnum.MAEKLARASSISTENT && (
                  <AnstaellEnKoordinator />
                )}
                {nyttAerendeState.medarbetareroll?.MedarbetareRoll === medarbetareRollEnum.HK_MEDARBETARE && (
                  <AnstaellEnHKMedarbetare />
                )}
                {bostadsMarknadId === bostadsMarknadEnum.UTLAND && <AnstaellEnUtlandsperson />}
              </>
            </NyttAerendeContext.Provider>
          )}
        </div>
      )}
    </>
  );
};

export default AnstaellEnPerson;
