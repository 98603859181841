/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AerendeAktivitetStatusVO
 */
export interface AerendeAktivitetStatusVO {
  /**
   *
   * @type {number}
   * @memberof AerendeAktivitetStatusVO
   */
  AerendeAktivitetStatus?: number | null;
}

export function AerendeAktivitetStatusVOFromJSON(json: any): AerendeAktivitetStatusVO {
  return AerendeAktivitetStatusVOFromJSONTyped(json, false);
}

export function AerendeAktivitetStatusVOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AerendeAktivitetStatusVO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendeAktivitetStatus: !exists(json, 'AerendeAktivitetStatus') ? undefined : json['AerendeAktivitetStatus'],
  };
}

export function AerendeAktivitetStatusVOToJSON(value?: AerendeAktivitetStatusVO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendeAktivitetStatus: value.AerendeAktivitetStatus,
  };
}
