/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { AerendemallVO, AerendemallVOFromJSON, AerendemallVOFromJSONTyped, AerendemallVOToJSON } from './AerendemallVO';

/**
 *
 * @export
 * @interface CreateAvslutaAerendeServiceCmd
 */
export interface CreateAvslutaAerendeServiceCmd {
  /**
   *
   * @type {AerendemallVO}
   * @memberof CreateAvslutaAerendeServiceCmd
   */
  AerendemallVO: AerendemallVO;
  /**
   *
   * @type {Date}
   * @memberof CreateAvslutaAerendeServiceCmd
   */
  Slutdatum: Date;
  /**
   *
   * @type {number}
   * @memberof CreateAvslutaAerendeServiceCmd
   */
  PersonId: number;
}

export function CreateAvslutaAerendeServiceCmdFromJSON(json: any): CreateAvslutaAerendeServiceCmd {
  return CreateAvslutaAerendeServiceCmdFromJSONTyped(json, false);
}

export function CreateAvslutaAerendeServiceCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateAvslutaAerendeServiceCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendemallVO: AerendemallVOFromJSON(json['AerendemallVO']),
    Slutdatum: new Date(json['Slutdatum']),
    PersonId: json['PersonId'],
  };
}

export function CreateAvslutaAerendeServiceCmdToJSON(value?: CreateAvslutaAerendeServiceCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendemallVO: AerendemallVOToJSON(value.AerendemallVO),
    Slutdatum: value.Slutdatum.toISOString(),
    PersonId: value.PersonId,
  };
}
