/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MedarbetareRollVO
 */
export interface MedarbetareRollVO {
  /**
   *
   * @type {number}
   * @memberof MedarbetareRollVO
   */
  MedarbetareRoll?: number | null;
}

export function MedarbetareRollVOFromJSON(json: any): MedarbetareRollVO {
  return MedarbetareRollVOFromJSONTyped(json, false);
}

export function MedarbetareRollVOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedarbetareRollVO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    MedarbetareRoll: !exists(json, 'MedarbetareRoll') ? undefined : json['MedarbetareRoll'],
  };
}

export function MedarbetareRollVOToJSON(value?: MedarbetareRollVO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    MedarbetareRoll: value.MedarbetareRoll,
  };
}
