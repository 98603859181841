export const MailToWrapper: React.FC<{ actionUrl: string; done: boolean; children?: React.ReactNode }> = ({
  actionUrl,
  done,
  children,
}) =>
  done ? (
    <>{children}</>
  ) : (
    <a href={actionUrl} className="hover:no-underline">
      {children}
    </a>
  );
