import { combineReducers } from 'redux';
import { aerendeListQryReducer } from './qryAerendeList';
import { landListQryReducer } from './skapaNyttAerende/aerendeMallTyper/anstaellEnPerson/qryLandList';
import { medarbetarListQryReducer } from './skapaNyttAerende/aerendeMallTyper/qryMedarbetarList';
import { skapaNyttAerendeReducer } from './skapaNyttAerende/skapaNyttAerendeReducer';
import uiCreatedAerendeId from './skapaNyttAerende/uiCreatedAerendeId';

export const aerendeListReducer = combineReducers({
  qryAerendeList: aerendeListQryReducer,
  skapaNyttAerende: skapaNyttAerendeReducer,
  uiCreatedAerendeId: uiCreatedAerendeId,
  qryMedarbetarList: medarbetarListQryReducer,
  qryLandList: landListQryReducer,
});
