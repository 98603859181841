import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { msalScopes } from './msalConfig';

/**
 * This component will make sure the user is logged in and login the user if necessary
 */
export const AzureADLogin: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { accounts, instance, inProgress } = useMsal();
  const [triggeredRedirect, setTriggerRedirect] = useState(false);
  useEffect(() => {
    if (triggeredRedirect) {
      instance.loginRedirect({ scopes: msalScopes });
    }
    // This disable is need since this hook should ONLY be trigged when triggeredRedirect
    // is set to true. This is because the MsalProvider component will otherwise trigger the
    // login redirect twice, because it has not yet updated the inProgress status.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredRedirect]);

  if ((!accounts || accounts.length === 0) && inProgress === InteractionStatus.None && !triggeredRedirect) {
    // First time the user logs in (no account exists), we need to trigger a redirect for the user
    setTriggerRedirect(true);
  }

  return (
    <>
      {/* If the user is logged in, use SilentLogin instead */}
      {accounts && accounts.length > 0 && inProgress === InteractionStatus.None && (
        <SilentLogin user={accounts[0].username} />
      )}
      {children}
    </>
  );
};

const SilentLogin: React.FC<{ user: string }> = ({ user }) => {
  const { login, error } = useMsalAuthentication(InteractionType.Silent, {
    // LoginHint is needed for silent login
    loginHint: user,
    scopes: msalScopes,
  });

  useEffect(() => {
    if (error) {
      // If the silent login fails, do a redirect login
      login(InteractionType.Redirect);
    }
  }, [error, login]);

  return null;
};
