import { FbFormInput } from '@decernointernal/fb-interna-komponenter';
import { FC } from 'react';
import FormInputWrapper from '../aerendeMallTyper/FormInputWrapper';
import { NyttAerendeContext } from '../context/NyttAerendeContext';

export const Personnamn: FC = () => {
  return (
    <NyttAerendeContext.Consumer>
      {context =>
        context && (
          <>
            <FormInputWrapper>
              <FbFormInput
                id="foernamn"
                label="Förnamn"
                required={true}
                defaultValue={context.nyttAerendeState.foernamn}
                minLength={2}
                maxLength={40}
                trim={true}
                onBlur={(value: string | undefined) => {
                  context.setNyttAerendeState(prevState => {
                    return { ...prevState, foernamn: value ?? '' };
                  });
                }}
              />
            </FormInputWrapper>

            <FormInputWrapper>
              <FbFormInput
                id="efternamn"
                label="Efternamn"
                required={true}
                defaultValue={context.nyttAerendeState.efternamn}
                minLength={2}
                maxLength={40}
                trim={true}
                onBlur={(value: string | undefined) => {
                  context.setNyttAerendeState(prevState => {
                    return { ...prevState, efternamn: value ?? '' };
                  });
                }}
              />
            </FormInputWrapper>
          </>
        )
      }
    </NyttAerendeContext.Consumer>
  );
};
