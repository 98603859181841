import { CommandFunctions, createWebCommandList, WebCommandListState } from '@decernointernal/websd.shared';
import { AerendePK } from 'generated-models/anstallningsportalen';
import { ChangeAerendeStatusCmd } from 'generated-models/anstallningsportalen/models/ChangeAerendeStatusCmd';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Aerende] change AerendeStatus';

type UpdateAerendeMedarbetarePropCmdState = WebCommandListState<ChangeAerendeStatusCmd>;

const changeAerendeStatusCmd = createWebCommandList<typeof ActionType, ChangeAerendeStatusCmd>(
  ActionType,
  k => k.PrimaryKey
);

export const selectChangeAerendeStatusCmd = (primaryKey: AerendePK) => (state: RootState) =>
  changeAerendeStatusCmd.get(map(state), primaryKey);

const map: MapState<UpdateAerendeMedarbetarePropCmdState> = rootState => rootState.main.aerende.changeAerendeStatus;

export const changeAerendeStatusAction =
  (cmd: ChangeAerendeStatusCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = changeAerendeStatusCmd.get(map(state), { PrimaryKey: cmd.PrimaryKey });
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(changeAerendeStatusCmd.executing(cmd));

      const result = await new ApiClient().AerendeCmdApi.changeAerendeStatus({ changeAerendeStatusCmd: cmd });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(changeAerendeStatusCmd.success(cmd));
    } catch (err) {
      // Behöver vi sätta validationErrors i ErrorMessage (från websd) för att hantera direkta fel som t.ex. att personnummer är upptaget?

      dispatch(changeAerendeStatusCmd.failure(cmd, await parseErrorResponse(err)));
    }
  };

export const removeChangeAerendeStatusAction =
  (cmd: ChangeAerendeStatusCmd): ThunkAction =>
  async dispatch => {
    // Remove command from commandList
    dispatch(changeAerendeStatusCmd.remove(cmd));
  };

export const changeAerendeStatusReducer = changeAerendeStatusCmd.reducer;
