import { FbAlert, FbFormInput } from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions } from '@decernointernal/websd.shared';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import FormInputWrapper from '../aerendeMallTyper/FormInputWrapper';
import { selectCreateAerendeCmd, setCreateAerendeCmdNotAsked } from '../cmdCreateAerende';
import { NyttAerendeContext } from '../context/NyttAerendeContext';

export const Personnummer: FC = () => {
  const createAerende = useSelector(selectCreateAerendeCmd);
  const dispatch = useAppDispatch();

  return (
    <NyttAerendeContext.Consumer>
      {context =>
        context && (
          <>
            <div className="fb-paragraph-lvl-2 my-2">
              <FbAlert type="info" text="Personnummer kan inte ändras efter att ärendet skapats" />
            </div>
            <FormInputWrapper>
              <FbFormInput
                id="personnummer"
                label="Personnummer"
                type="personnummer"
                defaultValue={context.nyttAerendeState.personnummer}
                validationFailedTooltip="ååååmmdd-XXXX"
                required={true}
                customValidationExpression={/^(\d{8}-?\d{4})?$/}
                onBlur={(value: string | undefined, isValid: boolean) => {
                  if (isValid && CommandFunctions.isFailure(createAerende)) {
                    dispatch(setCreateAerendeCmdNotAsked());
                  }
                  context.setNyttAerendeState(prevState => {
                    return { ...prevState, personnummer: value ?? '' };
                  });
                }}
              />
              {CommandFunctions.isFailure(createAerende) && createAerende.err.errorCode === 'Personnummer' && (
                <div className="mt-2">
                  <FbAlert type="danger" text={createAerende.err.message ?? ''} />
                </div>
              )}
            </FormInputWrapper>
          </>
        )
      }
    </NyttAerendeContext.Consumer>
  );
};
