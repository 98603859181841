/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface KontorDTO
 */
export interface KontorDTO {
  /**
   *
   * @type {number}
   * @memberof KontorDTO
   */
  KontorId?: number;
  /**
   *
   * @type {string}
   * @memberof KontorDTO
   */
  KontorNamn?: string | null;
  /**
   *
   * @type {number}
   * @memberof KontorDTO
   */
  BostadsMarknadId?: number | null;
  /**
   *
   * @type {number}
   * @memberof KontorDTO
   */
  KontorsTyp?: number;
}

export function KontorDTOFromJSON(json: any): KontorDTO {
  return KontorDTOFromJSONTyped(json, false);
}

export function KontorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): KontorDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    KontorId: !exists(json, 'KontorId') ? undefined : json['KontorId'],
    KontorNamn: !exists(json, 'KontorNamn') ? undefined : json['KontorNamn'],
    BostadsMarknadId: !exists(json, 'BostadsMarknadId') ? undefined : json['BostadsMarknadId'],
    KontorsTyp: !exists(json, 'KontorsTyp') ? undefined : json['KontorsTyp'],
  };
}

export function KontorDTOToJSON(value?: KontorDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    KontorId: value.KontorId,
    KontorNamn: value.KontorNamn,
    BostadsMarknadId: value.BostadsMarknadId,
    KontorsTyp: value.KontorsTyp,
  };
}
