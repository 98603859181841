import { FbAlert } from '@decernointernal/fb-interna-komponenter';
import { FC } from 'react';

export const LaeggTillExternMedarbetare: FC = () => {
  return (
    <FbAlert type="info">
      <span className="text-sm text-fb-color-typography">
        Kontakta&nbsp;
        <a href="mailto:hrsupport%40fastighetsbyran.se?subject=Ny%20extern%20medarbetare&body=Hej%20HR-support%2C%20%0A%0AJag%20skulle%20vilja%20l%C3%A4gga%20upp%20en%20ny%20extern%20medarbetare%20enligt%20f%C3%B6ljande%20uppgifter%3A%0A%0AKontor%3A%20%0AF%C3%B6rsta%20dag%20p%C3%A5%20kontoret%3A%0AF%C3%B6rnamn%3A%0AEfternamn%3A%0APersonnummer%3A%20%0AMobilnummer%3A%0ATitel%3A%20%0AMejladress%3A%0AVisas%20p%C3%A5%20webben%20%28ja%2Fnej%29%3A%20%0AProfilbild%20%28bifogas%20om%20%C3%B6nskas%29">
          HR-support
        </a>
        &nbsp;för att lägga till en extern medarbetare.
      </span>
    </FbAlert>
  );
};
