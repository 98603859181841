/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { AerendePK, AerendePKFromJSON, AerendePKFromJSONTyped, AerendePKToJSON } from './AerendePK';

/**
 *
 * @export
 * @interface AerendeMedarbetareKontorListDTO
 */
export interface AerendeMedarbetareKontorListDTO {
  /**
   *
   * @type {AerendePK}
   * @memberof AerendeMedarbetareKontorListDTO
   */
  AerendePK: AerendePK;
  /**
   *
   * @type {number}
   * @memberof AerendeMedarbetareKontorListDTO
   */
  KontorId: number;
  /**
   *
   * @type {string}
   * @memberof AerendeMedarbetareKontorListDTO
   */
  Kontorsnamn: string;
}

export function AerendeMedarbetareKontorListDTOFromJSON(json: any): AerendeMedarbetareKontorListDTO {
  return AerendeMedarbetareKontorListDTOFromJSONTyped(json, false);
}

export function AerendeMedarbetareKontorListDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AerendeMedarbetareKontorListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendePK: AerendePKFromJSON(json['AerendePK']),
    KontorId: json['KontorId'],
    Kontorsnamn: json['Kontorsnamn'],
  };
}

export function AerendeMedarbetareKontorListDTOToJSON(value?: AerendeMedarbetareKontorListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendePK: AerendePKToJSON(value.AerendePK),
    KontorId: value.KontorId,
    Kontorsnamn: value.Kontorsnamn,
  };
}
