import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export interface TimerState {
  value: number;
  lastRedirectTime: number;
}

const initialState: TimerState = {
  value: 0,
  lastRedirectTime: new Date().getTime(),
};

export const azureADLoginTimerSlice = createSlice({
  name: 'AzureAdLoginTimer',
  initialState,
  reducers: {
    incrementTenMinutes: state => {
      state.value += 1 * 60 * 10;
    },
    reset: state => {
      state.value = 0;
    },
  },
});

// 5 hours of inactivity allowed
export const timeLimitSeconds = 60 * 60 * 5;

// Action creators are generated for each case reducer function
export const { incrementTenMinutes, reset } = azureADLoginTimerSlice.actions;

export const selectSecondsLeft = (state: RootState) => state.azureADLoginTimer.value;
export const selectLastRedirectTime = (state: RootState) => state.azureADLoginTimer.lastRedirectTime;

export default azureADLoginTimerSlice.reducer;
