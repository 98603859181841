import {
  FbAlert,
  FbFormDropdown,
  FbFormDropdownValue,
  FbFormInput,
  FbSpinner,
  isValidDate,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { koenEnum } from 'domain/koen';
import { landEnum } from 'domain/land';
import { KoenVO } from 'generated-models/anstallningsportalen';
import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import { selectCreateAerendeCmd } from '../../cmdCreateAerende';
import { NyttAerendeContext } from '../../context/NyttAerendeContext';
import { Header } from '../../shared-components/Header';
import { Personnamn } from '../../shared-components/Personnamn';
import { Personnummer } from '../../shared-components/Personnummer';
import { PlaneradFoerstaArbetsdag } from '../../shared-components/PlaneradFoerstaArbetsdag';
import FormInputWrapper from '../FormInputWrapper';
import { landListQryAction, selectlandListQry } from './qryLandList';

export const AnstaellEnUtlandsperson: FC = () => {
  var landList = useSelector(selectlandListQry());
  const createAerende = useSelector(selectCreateAerendeCmd);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(landList) || RemoteDataFunctions.isStale(landList)) {
      dispatch(landListQryAction());
    }
  }, [landList, dispatch]);

  const sorteradeLaender = useMemo((): FbFormDropdownValue[] => {
    const laender: FbFormDropdownValue[] = [];

    if (RemoteDataFunctions.hasData(landList)) {
      laender.push({ id: landEnum.SVERIGE, value: 'Sverige' });
      laender.push({ id: landEnum.SPANIEN, value: 'Spanien' });
      laender.push({ id: landEnum.PORTUGAL, value: 'Portugal' });

      landList.data
        .filter(
          l =>
            !!l.LandPK &&
            l.LandPK.LandId !== landEnum.SVERIGE &&
            l.LandPK.LandId !== landEnum.SPANIEN &&
            l.LandPK.LandId !== landEnum.PORTUGAL
        )
        .forEach(l => {
          if (l.LandPK.LandId) {
            laender.push({ id: l.LandPK.LandId, value: l.LandNamn });
          }
        });
    }

    return laender;
  }, [landList]);

  return (
    <NyttAerendeContext.Consumer>
      {context =>
        context && (
          <>
            {RemoteDataFunctions.isLoading(landList) && <FbSpinner size="medium" />}
            {context.nyttAerendeState.medarbetareroll.MedarbetareRoll && RemoteDataFunctions.hasData(landList) && (
              <>
                <Header rubrik="Personinformation" className="mt-6" />
                <FormInputWrapper>
                  <FbFormDropdown
                    id="nationalitet"
                    defaultId={context.nyttAerendeState.nationalitetId}
                    label="Nationalitet"
                    required={true}
                    values={sorteradeLaender}
                    onBlur={(value?: FbFormDropdownValue) => {
                      context.setNyttAerendeState(prevState => {
                        return {
                          ...prevState,
                          nationalitetId: value?.id ? (value.id as number) : undefined,
                        };
                      });
                    }}
                    expandScroll={true}
                  />
                </FormInputWrapper>

                {context.nyttAerendeState.nationalitetId && (
                  <>
                    {context.nyttAerendeState.nationalitetId === landEnum.SVERIGE ? (
                      <>
                        <Personnummer />
                      </>
                    ) : (
                      <>
                        <div className="fb-paragraph-lvl-2 my-2">
                          <FbAlert type="info" text="Födelsedatum kan inte ändras efter att ärendet skapats." />
                        </div>

                        <FormInputWrapper>
                          <FbFormInput
                            id="foedelsedatum"
                            label="Födelsedatum"
                            required={false}
                            type="datum"
                            validationFailedTooltip="åååå-mm-dd"
                            defaultValue={
                              context.nyttAerendeState.foedelsedatum ? context.nyttAerendeState.foedelsedatum : ''
                            }
                            trim={true}
                            onBlur={(value: string | undefined) => {
                              context.setNyttAerendeState(prevState => {
                                return {
                                  ...prevState,
                                  foedelsedatum: value && isValidDate(value) ? value : undefined,
                                };
                              });
                            }}
                          />
                        </FormInputWrapper>
                      </>
                    )}
                    <FormInputWrapper>
                      <FbFormInput
                        id="nationelltID"
                        label="Nationellt ID-nummer"
                        required={true}
                        defaultValue={
                          context.nyttAerendeState.nationelltIDNummer ? context.nyttAerendeState.nationelltIDNummer : ''
                        }
                        trim={true}
                        onBlur={(value: string | undefined) => {
                          context.setNyttAerendeState(prevState => {
                            return {
                              ...prevState,
                              nationelltIDNummer: value ? value : undefined,
                            };
                          });
                        }}
                      />
                      {CommandFunctions.isFailure(createAerende) &&
                        createAerende.err.errorCode === 'NationelltIDNummer' && (
                          <div className="mt-2">
                            <FbAlert type="danger" text={createAerende.err.message ?? ''} />
                          </div>
                        )}
                    </FormInputWrapper>

                    {context.nyttAerendeState.nationalitetId !== landEnum.SVERIGE && (
                      <FormInputWrapper>
                        <FbFormDropdown
                          id="koen"
                          label="Kön"
                          required={true}
                          values={[
                            { id: koenEnum.MAN, value: 'Man' },
                            { id: koenEnum.KVINNA, value: 'Kvinna' },
                          ]}
                          defaultId={
                            context.nyttAerendeState.koen?.Koen ? context.nyttAerendeState.koen.Koen : undefined
                          }
                          onChange={(value: FbFormDropdownValue | undefined) => {
                            context.setNyttAerendeState(prevState => {
                              return {
                                ...prevState,
                                koen: VOCreate<KoenVO>({
                                  Koen: value?.id === koenEnum.MAN ? 'M' : value?.id === koenEnum.KVINNA ? 'K' : null,
                                }),
                              };
                            });
                          }}
                        />
                      </FormInputWrapper>
                    )}

                    <Personnamn />
                    <PlaneradFoerstaArbetsdag />
                  </>
                )}
              </>
            )}
          </>
        )
      }
    </NyttAerendeContext.Consumer>
  );
};
