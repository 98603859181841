export enum aerendeMedarbetareKontorPropTypEnum {
  STARTDATUM_ANSTAELLNING = 'Startdatum',
  SLUTDATUM_ANSTAELLNING = 'Slutdatum',
  HAR_MEDARBETAREN_EN_GILTIG_ANSVARSFOERSAEKRING = 'HarGiltigAnsvarsfoersaekring',
  STARTDATUM_ANSVARSFOERSAEKRING = 'StartdatumAnsvarsfoersaekring',
  SLUTDATUM_ANSVARSFOERSAEKRING = 'SlutdatumAnsvarsfoersaekring',
  MOBILTELEFONNUMMER = 'Mobiltelefonnummer',
  MOBILTELEFONNUMMER_AER_EXTERNT_SYNLIGT = 'MobiltelefonnummerAerExterntSynligt',
  TELEFONNUMMER = 'Telefonnummer',
  MOBILTELEFONNUMMER_PRIVAT = 'MobiltelefonnummerPrivat',
  GATUADRESS = 'GatuAdress',
  MEJLADRESS_PRIVAT = 'MejladressPrivat',
  POSTNUMMER = 'Postnummer',
  POSTORT = 'Postort',
  VISA_INTE_PAA_KONTORETSSIDA = 'VisaIntePaaKontoretsSida',
  REKRYTERADFRAAN = 'RekryteradFraan',
}
