import { SdKeys } from '@decernointernal/websd.shared';

export const NullVO = {};

/**
 * Checks if it is a NullVO
 * @param vo The value object to check
 * @returns if is is a NullVO
 */
export const IsNullVO = (vo: any): boolean =>
  (typeof vo === 'string' ? vo === '{}' : Object.keys(vo).length === 0) ||
  Object.keys(vo).filter(key => vo[key] === undefined).length === Object.keys(vo).length;

/**
 * Creates a new value object of the specified type
 * @param values the values in the value object
 * @returns The value object
 */
export const VOCreate = <TKey extends object>(values: TKey): TKey => SdKeys.keyToValue<TKey>(values);
