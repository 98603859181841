import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { AerendePK } from 'generated-models/anstallningsportalen';
import { CreateAerendeServiceCmd } from 'generated-models/anstallningsportalen/models/CreateAerendeServiceCmd';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventDataTypes, dispatchIntegrationEvents, parseEvent } from 'utils/eventDispatcher';
import { setCreatedAerendeId } from './uiCreatedAerendeId';
const ActionType = '[Aerende] create Aerende';

const createAerendeCmd = createWebCommandSingletonList<typeof ActionType, CreateAerendeServiceCmd>(
  ActionType,
  ActionType
);

export const selectCreateAerendeCmd = (state: RootState) =>
  createAerendeCmd.get(state.main.home.aerendeList.skapaNyttAerende.cmdCreateAerende);

export const resetCreateAerendeCmdState = (): ThunkAction => async dispatch => {
  dispatch(createAerendeCmd.remove());
};
export const setCreateAerendeCmdNotAsked = (): ThunkAction => async dispatch => {
  dispatch(createAerendeCmd.notExecuted());
};

export const createAerendeAction =
  (cmd: CreateAerendeServiceCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectCreateAerendeCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(createAerendeCmd.executing());

      const result = await new ApiClient().AerendeCmdApi.createAerende({ createAerendeServiceCmd: cmd });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Let interested components know the id of the created "Aerende"
      const event = result.find(e => {
        return e.EventDataType === 'Aerende' ? e : undefined;
      });
      if (event) {
        const parsedEvent = parseEvent<AerendePK>(event.EventDataType as IntegrationEventDataTypes, event);
        dispatch(setCreatedAerendeId(parsedEvent.eventDataKey));
      }

      // Handle success response for command
      dispatch(createAerendeCmd.success(cmd));
    } catch (err) {
      // Behöver vi sätta validationErrors i ErrorMessage (från websd) för att hantera direkta fel som t.ex. att personnummer är upptaget?

      dispatch(createAerendeCmd.failure(await parseErrorResponse(err)));
    }
  };

export default createAerendeCmd.reducer;
