import { FbFormInput } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { aerendeMedarbetareKontorPropTypEnum } from 'domain/aerendeMedarbetareKontorPropTypDomain';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectAerendeQry } from '../../qryAerende';
import { IInformationSektionProps } from '../IInformationProps';
import { sektionHoc } from './sektionHoc';

export const KontaktuppgifterPrivat: FC<IInformationSektionProps> = ({ aerendePK }) => {
  const aerende = useSelector(selectAerendeQry(aerendePK));

  return (
    <>
      {RemoteDataFunctions.hasData(aerende) && (
        <>
          <h5 className="pb-5">Här anger du medarbetarens privata mobiltelefonnummer och hemadress.</h5>
          <div className="pb-5">
            {sektionHoc(FbFormInput, { aerendeId: aerendePK.AerendeId })({
              id: aerendeMedarbetareKontorPropTypEnum.MOBILTELEFONNUMMER_PRIVAT,
              label: 'Mobilnummer',
              type: 'telefonnummer',
              defaultValue: aerende.data.MobiltelefonnummerPrivat ?? '',
              className: 'w-48',
            })}
          </div>
          <div className="pb-3">
            {sektionHoc(FbFormInput, { aerendeId: aerendePK.AerendeId })({
              id: aerendeMedarbetareKontorPropTypEnum.MEJLADRESS_PRIVAT,
              label: 'Mejladress',
              defaultValue: aerende.data.MejladressPrivat ?? '',
              className: 'w-96',
            })}
          </div>
        </>
      )}
    </>
  );
};
