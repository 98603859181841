import { AerendemallTypVO } from 'generated-models/anstallningsportalen';

export enum aerendemallTypEnum {
  EXTERN_MEDARBETARE = -1,
  NY = 0,
  AVSLUT = 1,
}

export const getAerendeMallTypDomain = (vo: AerendemallTypVO): aerendemallTypEnum | null => {
  switch (vo.AerendemallTyp) {
    case -1:
      return aerendemallTypEnum.EXTERN_MEDARBETARE;
    case 0:
      return aerendemallTypEnum.NY;
    case 1:
      return aerendemallTypEnum.AVSLUT;
    default:
      return null;
  }
};

export default aerendemallTypEnum;
