/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { KontorDTO, KontorDTOFromJSON, KontorDTOFromJSONTyped, KontorDTOToJSON } from './KontorDTO';

/**
 *
 * @export
 * @interface UserHeaderDTO
 */
export interface UserHeaderDTO {
  /**
   *
   * @type {number}
   * @memberof UserHeaderDTO
   */
  MotivId: number;
  /**
   *
   * @type {string}
   * @memberof UserHeaderDTO
   */
  Name: string;
  /**
   *
   * @type {string}
   * @memberof UserHeaderDTO
   */
  Role: string;
  /**
   *
   * @type {Array<KontorDTO>}
   * @memberof UserHeaderDTO
   */
  Kontor: Array<KontorDTO>;
}

export function UserHeaderDTOFromJSON(json: any): UserHeaderDTO {
  return UserHeaderDTOFromJSONTyped(json, false);
}

export function UserHeaderDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserHeaderDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    MotivId: json['MotivId'],
    Name: json['Name'],
    Role: json['Role'],
    Kontor: (json['Kontor'] as Array<any>).map(KontorDTOFromJSON),
  };
}

export function UserHeaderDTOToJSON(value?: UserHeaderDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    MotivId: value.MotivId,
    Name: value.Name,
    Role: value.Role,
    Kontor: (value.Kontor as Array<any>).map(KontorDTOToJSON),
  };
}
