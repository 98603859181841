export enum medarbetareRollEnum {
  KONTORSCHEF = 2,
  IT_ANSVARIG = 4,
  FASTIGHETSMAEKLARE = 5,
  MAEKLARASSISTENT = 6,
  MAEKLARPRAKTIKANT = 7,
  BLIVANDEMAEKLARE = 8,
  HK_MEDARBETARE = 9,
  SAELJARE = 10,
  INNESAELJARE = 11,
  FEI_MEDARBETARE = 13,
}
