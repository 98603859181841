import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { SaveAerendeAktivitetStatusServiceCmd } from 'generated-models/anstallningsportalen/models/SaveAerendeAktivitetStatusServiceCmd';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';

const ActionType = '[Aerende] save aerende aktivitet status';

const saveAerendeAktivitetStatusCmd = createWebCommandSingletonList<
  typeof ActionType,
  SaveAerendeAktivitetStatusServiceCmd
>(ActionType, ActionType);

export const selectsaveAerendeAktivitetStatusServiceCmd = (state: RootState) =>
  saveAerendeAktivitetStatusCmd.get(state.main.aerende.aerendeaktivitet.status);

export const saveAerendeAktivitetStatusAction =
  (cmd: SaveAerendeAktivitetStatusServiceCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectsaveAerendeAktivitetStatusServiceCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(saveAerendeAktivitetStatusCmd.executing());

      const result = await new ApiClient().AerendeAktivitetApi.saveAerendeAktivitetStatus({
        saveAerendeAktivitetStatusServiceCmd: cmd,
      });
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(saveAerendeAktivitetStatusCmd.success(cmd));
    } catch (error) {}
  };

export const saveAerendeAktivitetStatusCmdReducer = saveAerendeAktivitetStatusCmd.reducer;
