import { FbCollapsible, FbSpinner } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { AerendePK } from 'generated-models/anstallningsportalen';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { groupBy } from 'utils/arrayUtils';
import { AntalKvarvarandeAktiviteter } from './AntalKvarvarandeAktiviteter';
import Checklista from './Checklista';
import { checklistorQryAction, selectChecklistorQry } from './qryChecklistor';

export const Checklistor: React.FC<{ aerendePK: AerendePK }> = ({ aerendePK }) => {
  const dispatch = useAppDispatch();
  const checklistor = useSelector(selectChecklistorQry(aerendePK));

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(checklistor) || RemoteDataFunctions.isStale(checklistor)) {
      dispatch(checklistorQryAction(aerendePK));
    }
  }, [checklistor, aerendePK, dispatch]);

  if (!RemoteDataFunctions.hasData(checklistor)) {
    return (
      <div className="w-full p-8 text-center">
        <FbSpinner size="large" />
      </div>
    );
  }

  return (
    <div className="overflow-y-scroll h-[calc(100vh-160px)]">
      {RemoteDataFunctions.hasData(checklistor) &&
        groupBy(checklistor.data.ChecklistaList, 'Checklista').map((cl, index) => (
          <div key={index} className="p-4 even:py-0" data-testid="checklistor-test-id">
            <FbCollapsible
              headingTitle={cl.key}
              isDefaultCollapsed={false}
              isCollapsible={true}
              headingMiddleSlotComponent={<AntalKvarvarandeAktiviteter checklistaItems={cl.items} />}
            >
              <Checklista checklistaItems={cl.items} aerendePK={aerendePK} />
            </FbCollapsible>
          </div>
        ))}
    </div>
  );
};
