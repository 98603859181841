import { FbDatepicker } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { selectAerendeQry } from 'components/aerende/qryAerende';
import { selectUserQry } from 'components/user/qryUser';
import { aerendeMedarbetareKontorPropTypEnum } from 'domain/aerendeMedarbetareKontorPropTypDomain';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { IInformationSektionProps } from '../../IInformationProps';
import { sektionHoc } from '../sektionHoc';

export const Avsluta: FC<IInformationSektionProps> = ({ aerendePK }) => {
  const aerende = useSelector(selectAerendeQry(aerendePK));
  const user = useSelector(selectUserQry());

  return (
    <>
      {RemoteDataFunctions.hasData(aerende) && RemoteDataFunctions.hasData(user) && (
        <>
          <div className="pb-4 w-96 -mt-5">
            {sektionHoc(FbDatepicker, { aerendeId: aerendePK.AerendeId })({
              id: aerendeMedarbetareKontorPropTypEnum.SLUTDATUM_ANSTAELLNING,
              label: 'Slutdatum anställning',
              required: true,
              startDate: aerende.data.Slutdatum ?? undefined,
              validationFailedTooltip: 'Felaktigt format. Ange datum på formatet 2025-12-24',
            })}
          </div>
        </>
      )}
    </>
  );
};

export default Avsluta;
