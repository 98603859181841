/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AerendeMedarbetarePropTypVO,
  AerendeMedarbetarePropTypVOFromJSON,
  AerendeMedarbetarePropTypVOFromJSONTyped,
  AerendeMedarbetarePropTypVOToJSON,
} from './AerendeMedarbetarePropTypVO';
import { AerendePK, AerendePKFromJSON, AerendePKFromJSONTyped, AerendePKToJSON } from './AerendePK';

/**
 *
 * @export
 * @interface UpdateAerendeMedarbetarePropCmd
 */
export interface UpdateAerendeMedarbetarePropCmd {
  /**
   *
   * @type {AerendeMedarbetarePropTypVO}
   * @memberof UpdateAerendeMedarbetarePropCmd
   */
  PropVO: AerendeMedarbetarePropTypVO;
  /**
   *
   * @type {AerendePK}
   * @memberof UpdateAerendeMedarbetarePropCmd
   */
  PrimaryKey: AerendePK;
}

export function UpdateAerendeMedarbetarePropCmdFromJSON(json: any): UpdateAerendeMedarbetarePropCmd {
  return UpdateAerendeMedarbetarePropCmdFromJSONTyped(json, false);
}

export function UpdateAerendeMedarbetarePropCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateAerendeMedarbetarePropCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PropVO: AerendeMedarbetarePropTypVOFromJSON(json['PropVO']),
    PrimaryKey: AerendePKFromJSON(json['PrimaryKey']),
  };
}

export function UpdateAerendeMedarbetarePropCmdToJSON(value?: UpdateAerendeMedarbetarePropCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PropVO: AerendeMedarbetarePropTypVOToJSON(value.PropVO),
    PrimaryKey: AerendePKToJSON(value.PrimaryKey),
  };
}
