import * as ErrorStackParser from 'error-stack-parser'; // Must be importad with '* as' as the the typing is wrong and not telling us that the parsing functions uses 'this'
import {
  AerendeAktivitetCmdApi,
  ChecklistaQryApi,
  Configuration,
  KibanaApi,
  LandQryApi,
  MaeklarKedjaQryApi,
  MedarbetarQryApi,
  UserQryApi,
} from 'generated-models/anstallningsportalen';
import { AerendeCmdApi } from 'generated-models/anstallningsportalen/apis/AerendeCmdApi';
import { AerendeQryApi } from 'generated-models/anstallningsportalen/apis/AerendeQryApi';
import { ClientCrashStackframe } from 'generated-models/anstallningsportalen/models';
import { authenticateMiddleware, errorMiddleware, ongoingTransactionMiddleware } from './apiUtils';

class ApiClientBase {
  protected _config: Configuration;

  constructor() {
    this._config = new Configuration({
      basePath: process.env.REACT_APP_BACKEND_API_URL,
      middleware: [authenticateMiddleware, errorMiddleware, ongoingTransactionMiddleware],
    });
  }
}

/**
 * Expand this ApiClient with each new controller added on the server. Note that the generate command must be runned first, otherwise
 * the new api will not be available.
 */
export class ApiClient extends ApiClientBase {
  public KibanaApi = new KibanaApi(this._config);
  public UserQryApi = new UserQryApi(this._config);
  public AerendeQryApi = new AerendeQryApi(this._config);
  public AerendeCmdApi = new AerendeCmdApi(this._config);
  public ChecklistApi = new ChecklistaQryApi(this._config);
  public AerendeAktivitetApi = new AerendeAktivitetCmdApi(this._config);
  public MedarbetarQryApi = new MedarbetarQryApi(this._config);
  public LandQryApi = new LandQryApi(this._config);
  public MaeklarKedjaQryApi = new MaeklarKedjaQryApi(this._config);
}

export const sendClientCrashReport = (
  error: Error,
  errorCode: string,
  componentStack?: string,
  currentUrl?: string,
  userAgentInfo?: Navigator
) => {
  const userAgentInfoObject = {
    userAgent: userAgentInfo?.userAgent,
    vendor: userAgentInfo?.vendor,
  };

  return new ApiClient().KibanaApi.postClientCrashError({
    clientCrashErrorMessage: {
      Message: error.message,
      Stackframes: ErrorStackParser.parse(error) as ClientCrashStackframe[],
      CurrentUrl: currentUrl,
      ComponentStack: componentStack,
      UserAgentInfo: userAgentInfo ? JSON.stringify(userAgentInfoObject) : undefined,
      ErrorCode: errorCode,
    },
  });
};
