/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AerendeAktivitetsmallObligatoriskaFaeltDTO
 */
export interface AerendeAktivitetsmallObligatoriskaFaeltDTO {
  /**
   *
   * @type {number}
   * @memberof AerendeAktivitetsmallObligatoriskaFaeltDTO
   */
  AerendeAktivitetsmallId: number;
  /**
   *
   * @type {number}
   * @memberof AerendeAktivitetsmallObligatoriskaFaeltDTO
   */
  Version: number;
  /**
   *
   * @type {string}
   * @memberof AerendeAktivitetsmallObligatoriskaFaeltDTO
   */
  Faelt: string;
  /**
   *
   * @type {boolean}
   * @memberof AerendeAktivitetsmallObligatoriskaFaeltDTO
   */
  HarVaerde: boolean;
}

export function AerendeAktivitetsmallObligatoriskaFaeltDTOFromJSON(
  json: any
): AerendeAktivitetsmallObligatoriskaFaeltDTO {
  return AerendeAktivitetsmallObligatoriskaFaeltDTOFromJSONTyped(json, false);
}

export function AerendeAktivitetsmallObligatoriskaFaeltDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AerendeAktivitetsmallObligatoriskaFaeltDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendeAktivitetsmallId: json['AerendeAktivitetsmallId'],
    Version: json['Version'],
    Faelt: json['Faelt'],
    HarVaerde: json['HarVaerde'],
  };
}

export function AerendeAktivitetsmallObligatoriskaFaeltDTOToJSON(
  value?: AerendeAktivitetsmallObligatoriskaFaeltDTO | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendeAktivitetsmallId: value.AerendeAktivitetsmallId,
    Version: value.Version,
    Faelt: value.Faelt,
    HarVaerde: value.HarVaerde,
  };
}
