import { FC } from 'react';
import { NyttAerendeContext } from '../../context/NyttAerendeContext';
import { Header } from '../../shared-components/Header';
import { Personnamn } from '../../shared-components/Personnamn';
import { Personnummer } from '../../shared-components/Personnummer';
import { PlaneradFoerstaArbetsdag } from '../../shared-components/PlaneradFoerstaArbetsdag';

export const AnstaellEnHKMedarbetare: FC = () => {
  return (
    <NyttAerendeContext.Consumer>
      {context =>
        context && (
          <>
            <Header rubrik="Personinformation" className="mt-6" />

            <Personnummer />
            <Personnamn />
            <PlaneradFoerstaArbetsdag />
          </>
        )
      }
    </NyttAerendeContext.Consumer>
  );
};
