import {
  dateToAAAAMMDD,
  FbDatepicker,
  FbFormDropdown,
  FbFormDropdownValue,
  FbFormInput,
  FbFormRadiobuttonGroup,
  FbFormTextarea,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions } from '@decernointernal/websd.shared';
import { addOrUpdateField, removeField } from 'components/aerende/navigateAway/navigateAwayReducer';
import { AerendePK } from 'generated-models/anstallningsportalen';
import { AerendeMedarbetarePropTypVO } from 'generated-models/anstallningsportalen/models/AerendeMedarbetarePropTypVO';
import { ComponentType } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkAction } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import {
  selectUpdateAerendeMedarbetarePropCmd,
  updateAerendeMedarbetarePropCmdAction,
} from './cmdUpdateAerendeMedarbetareProp';

export interface ISektionHocProps {
  readonly aerendeId: number | null | undefined;
  readonly negateValue?: boolean;
}

export interface IRequiredProps {
  readonly label: string;
  readonly id: string;
  readonly disabled?: boolean;
  readonly defaultValue?: string | number;
  readonly startDate?: Date | undefined;
}

/**
 * A Higher-order-component(HOC) that wraps the Form-component and performs updates against the API.
 */
export function sektionHoc<T>(WrappedComponent: ComponentType<T>, hocProps: ISektionHocProps) {
  return (props: Omit<T & IRequiredProps, 'onBlur' | 'onChange'>) => {
    const dispatch = useDispatch();
    const updateAerendeMedarbetareProp = useSelector(
      selectUpdateAerendeMedarbetarePropCmd({ AerendeId: hocProps.aerendeId, PropTyp: props.id })
    );

    const createCmd = (value: string | undefined): ThunkAction => {
      return updateAerendeMedarbetarePropCmdAction({
        PrimaryKey: VOCreate<AerendePK>({ AerendeId: hocProps.aerendeId }),
        PropVO: VOCreate<AerendeMedarbetarePropTypVO>({ PropTyp: props.id, PropValue: value }),
      });
    };

    const dispatchAction = (action: any): void => {
      if (!CommandFunctions.isExecuting(updateAerendeMedarbetareProp)) {
        dispatch(action);
      }
    };

    const addOrUpdateNavigateAwayField = (isValid: boolean, action?: any) => {
      dispatch(addOrUpdateField({ id: props.id, isValid, action }));
    };

    const removeNavigateAwayField = () => {
      dispatch(removeField({ id: props.id }));
    };

    const hasChanged = (defaultValue?: string | Date | number, value?: string | Date | number) => {
      return defaultValue !== value;
    };

    let componentSpecificProps = {};

    switch (WrappedComponent.name) {
      case FbFormInput.name:
        componentSpecificProps = {
          onInstantChange: (value: string, isValid: boolean) => {
            if (hasChanged(props.defaultValue, value)) {
              addOrUpdateNavigateAwayField(isValid, isValid ? createCmd(value) : undefined);
            } else {
              removeNavigateAwayField();
            }
          },
          onBlur: (value: string | undefined, isValid: boolean) => {
            if (isValid && hasChanged(props.defaultValue, value)) {
              dispatchAction(createCmd(value));
              removeNavigateAwayField();
            }
          },
        };
        break;
      case FbFormTextarea.name:
        componentSpecificProps = {
          onInstantChange: (value: string, isValid: boolean) => {
            if (hasChanged(props.defaultValue, value)) {
              addOrUpdateNavigateAwayField(isValid, isValid ? createCmd(value) : undefined);
            } else {
              removeNavigateAwayField();
            }
          },
          onBlur: (value: string | undefined) => {
            if (hasChanged(props.defaultValue, value)) {
              dispatchAction(createCmd(value));
              removeNavigateAwayField();
            }
          },
        };
        break;
      case FbFormDropdown.name:
        componentSpecificProps = {
          onChange: (value?: FbFormDropdownValue) => {
            if (hasChanged(props.defaultValue, value?.id)) {
              dispatchAction(createCmd(value?.id.toString()));
            } else {
              removeNavigateAwayField();
            }
          },
          onBlur: (value?: FbFormDropdownValue) => {
            if (hasChanged(props.defaultValue, value?.id)) {
              addOrUpdateNavigateAwayField(true, createCmd(value?.id.toString()));
            } else {
              removeNavigateAwayField();
            }
          },
        };
        break;
      case FbFormRadiobuttonGroup.name:
        componentSpecificProps = { onChange: (_: string, value: string) => dispatchAction(createCmd(value)) };
        break;
      case FbDatepicker.name:
        componentSpecificProps = {
          onInstantChange: (value: string, isValid: boolean) => {
            if (hasChanged(props.startDate ? dateToAAAAMMDD(props.startDate) : '', value)) {
              addOrUpdateNavigateAwayField(isValid, isValid ? createCmd(value) : undefined);
            } else {
              removeNavigateAwayField();
            }
          },
          onChange: (date: Date | null) => {
            if (date) {
              const convertedDate = dateToAAAAMMDD(date);
              if (hasChanged(props.startDate ? dateToAAAAMMDD(props.startDate) : '', convertedDate)) {
                dispatchAction(createCmd(convertedDate));
                removeNavigateAwayField();
              }
            }
          },
        };
        break;
      default:
        componentSpecificProps = {
          onChange: (_: string, checked: boolean) =>
            dispatchAction(createCmd(`${hocProps.negateValue ? !checked : checked}`)),
        };
    }

    return (
      <WrappedComponent
        {...(props as T)}
        disabled={CommandFunctions.isExecuting(updateAerendeMedarbetareProp) || props.disabled}
        {...componentSpecificProps}
      />
    );
  };
}
