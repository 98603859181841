import { IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { AzureADLogin } from 'azureAD/AzureADLogin';
import { AzureADLoginTimer } from 'azureAD/AzureADLoginTimer';
import { HeaderWrapper } from 'components/HeaderWrapper';
import React from 'react';
import { SwitchPage } from 'SwitchPage';
import { logClientCrashReport } from 'utils/loggingUtils';

interface AppProps {
  readonly msalInstance: IPublicClientApplication;
}

export default class App extends React.Component<AppProps> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (error) {
      error.message = '(client) ' + error.message;
      logClientCrashReport(error, errorInfo.componentStack, window.location.pathname, navigator);
    } else if (!error) {
      logClientCrashReport(
        new Error(
          `(client) componentDidCatch: Error is null errorInfo.componentStack: ${JSON.stringify(
            errorInfo.componentStack
          )}`
        )
      );
    }
  }

  render() {
    return (
      <MsalProvider instance={this.props.msalInstance}>
        <AzureADLoginTimer />
        <AzureADLogin>
          <AuthenticatedTemplate>
            <div className="bg-orange-50 flex flex-col">
              <HeaderWrapper instance={this.props.msalInstance} />
              <SwitchPage />
            </div>
          </AuthenticatedTemplate>
        </AzureADLogin>
      </MsalProvider>
    );
  }
}
