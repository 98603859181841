import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AerendePK } from 'generated-models/anstallningsportalen';
import { RootState } from 'store/store';

export interface CreatedAerendeIdState {
  AerendePK?: AerendePK;
}

const initialState: CreatedAerendeIdState = {
  AerendePK: undefined,
};

export const CreatedAerendeIdSlice = createSlice({
  name: 'CreatedAerendeId',
  initialState,
  reducers: {
    setCreatedAerendeId: (state, action: PayloadAction<AerendePK>) => {
      state.AerendePK = action.payload;
    },
    removeCreatedAerendeId: state => {
      state.AerendePK = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCreatedAerendeId, removeCreatedAerendeId } = CreatedAerendeIdSlice.actions;

export const selectCreatedAerendeId = () => (state: RootState) => state.main.home.aerendeList.uiCreatedAerendeId;

export default CreatedAerendeIdSlice.reducer;
