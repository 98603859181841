/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SdDbOperation {
  Insert = 'Insert',
  Update = 'Update',
  Delete = 'Delete',
  Unchanged = 'Unchanged',
}

export function SdDbOperationFromJSON(json: any): SdDbOperation {
  return SdDbOperationFromJSONTyped(json, false);
}

export function SdDbOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdDbOperation {
  return json as SdDbOperation;
}

export function SdDbOperationToJSON(value?: SdDbOperation | null): any {
  return value as any;
}
