/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AerendemallVO
 */
export interface AerendemallVO {
  /**
   *
   * @type {number}
   * @memberof AerendemallVO
   */
  AerendemallId?: number | null;
}

export function AerendemallVOFromJSON(json: any): AerendemallVO {
  return AerendemallVOFromJSONTyped(json, false);
}

export function AerendemallVOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AerendemallVO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendemallId: !exists(json, 'AerendemallId') ? undefined : json['AerendemallId'],
  };
}

export function AerendemallVOToJSON(value?: AerendemallVO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendemallId: value.AerendemallId,
  };
}
