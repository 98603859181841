import { addNotifikation } from 'components/overlay/FbOverlayReducer';
import store from 'store/store';
import { ApiClient, sendClientCrashReport } from './apiClient';

export const logClientComponentErrorAsRedBalloon = (componentName: string, message: string) => {
  const errorCode = getErrorCode();

  new ApiClient().KibanaApi.postClientComponentError({
    clientComponentErrorMessage: {
      ComponentName: componentName,
      Message: message,
      ErrorCode: errorCode,
    },
  });
  store.dispatch(
    addNotifikation({
      titel: 'Hej! Något i Anställningsportalen gick precis snett.',
      text: [message],
      errorCode: errorCode,
      nivaa: 'error',
    })
  );
};

export const logClientCrashReport = (
  error: Error,
  componentStack?: string,
  currentUrl?: string,
  userAgentInfo?: Navigator
) => {
  const errorCode = getErrorCode();

  sendClientCrashReport(error, errorCode, componentStack, currentUrl, userAgentInfo);
  store.dispatch(
    addNotifikation({
      titel: 'Hej! Något i Anställningsportalen gick precis snett.',
      text: [
        'Du borde ladda om Anställningsportalen. Blir det alltid fel när du gör samma sak borde du kontakta support.',
      ],
      errorCode: errorCode,
      nivaa: 'error',
    })
  );
};

const getErrorCode = (): string => {
  return 'B' + Math.floor(Math.random() * 2147483647).toString();
};
