/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { AerendePK, AerendePKFromJSON, AerendePKFromJSONTyped, AerendePKToJSON } from './AerendePK';
import {
  AerendeStatusVO,
  AerendeStatusVOFromJSON,
  AerendeStatusVOFromJSONTyped,
  AerendeStatusVOToJSON,
} from './AerendeStatusVO';
import {
  AerendemallTypVO,
  AerendemallTypVOFromJSON,
  AerendemallTypVOFromJSONTyped,
  AerendemallTypVOToJSON,
} from './AerendemallTypVO';

/**
 *
 * @export
 * @interface AerendeListDTO
 */
export interface AerendeListDTO {
  /**
   *
   * @type {AerendePK}
   * @memberof AerendeListDTO
   */
  AerendePK: AerendePK;
  /**
   *
   * @type {string}
   * @memberof AerendeListDTO
   */
  Namn: string;
  /**
   *
   * @type {string}
   * @memberof AerendeListDTO
   */
  RollNamn?: string | null;
  /**
   *
   * @type {string}
   * @memberof AerendeListDTO
   */
  Kontorsnamn: string;
  /**
   *
   * @type {Date}
   * @memberof AerendeListDTO
   */
  Skapatdatum: Date;
  /**
   *
   * @type {Date}
   * @memberof AerendeListDTO
   */
  Startdatum?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof AerendeListDTO
   */
  Slutdatum?: Date | null;
  /**
   *
   * @type {number}
   * @memberof AerendeListDTO
   */
  AntalAktiviteterKvar: number;
  /**
   *
   * @type {AerendeStatusVO}
   * @memberof AerendeListDTO
   */
  AerendeStatusVO: AerendeStatusVO;
  /**
   *
   * @type {AerendemallTypVO}
   * @memberof AerendeListDTO
   */
  AerendemallTypVO: AerendemallTypVO;
  /**
   *
   * @type {string}
   * @memberof AerendeListDTO
   */
  AerendemallNamn: string;
  /**
   *
   * @type {string}
   * @memberof AerendeListDTO
   */
  BestaellareMejladress?: string | null;
  /**
   *
   * @type {Date}
   * @memberof AerendeListDTO
   */
  SkickatTillHRSystemetDatum?: Date | null;
}

export function AerendeListDTOFromJSON(json: any): AerendeListDTO {
  return AerendeListDTOFromJSONTyped(json, false);
}

export function AerendeListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AerendeListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendePK: AerendePKFromJSON(json['AerendePK']),
    Namn: json['Namn'],
    RollNamn: !exists(json, 'RollNamn') ? undefined : json['RollNamn'],
    Kontorsnamn: json['Kontorsnamn'],
    Skapatdatum: new Date(json['Skapatdatum']),
    Startdatum: !exists(json, 'Startdatum')
      ? undefined
      : json['Startdatum'] === null
      ? null
      : new Date(json['Startdatum']),
    Slutdatum: !exists(json, 'Slutdatum') ? undefined : json['Slutdatum'] === null ? null : new Date(json['Slutdatum']),
    AntalAktiviteterKvar: json['AntalAktiviteterKvar'],
    AerendeStatusVO: AerendeStatusVOFromJSON(json['AerendeStatusVO']),
    AerendemallTypVO: AerendemallTypVOFromJSON(json['AerendemallTypVO']),
    AerendemallNamn: json['AerendemallNamn'],
    BestaellareMejladress: !exists(json, 'BestaellareMejladress') ? undefined : json['BestaellareMejladress'],
    SkickatTillHRSystemetDatum: !exists(json, 'SkickatTillHRSystemetDatum')
      ? undefined
      : json['SkickatTillHRSystemetDatum'] === null
      ? null
      : new Date(json['SkickatTillHRSystemetDatum']),
  };
}

export function AerendeListDTOToJSON(value?: AerendeListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendePK: AerendePKToJSON(value.AerendePK),
    Namn: value.Namn,
    RollNamn: value.RollNamn,
    Kontorsnamn: value.Kontorsnamn,
    Skapatdatum: value.Skapatdatum.toISOString(),
    Startdatum:
      value.Startdatum === undefined ? undefined : value.Startdatum === null ? null : value.Startdatum.toISOString(),
    Slutdatum:
      value.Slutdatum === undefined ? undefined : value.Slutdatum === null ? null : value.Slutdatum.toISOString(),
    AntalAktiviteterKvar: value.AntalAktiviteterKvar,
    AerendeStatusVO: AerendeStatusVOToJSON(value.AerendeStatusVO),
    AerendemallTypVO: AerendemallTypVOToJSON(value.AerendemallTypVO),
    AerendemallNamn: value.AerendemallNamn,
    BestaellareMejladress: value.BestaellareMejladress,
    SkickatTillHRSystemetDatum:
      value.SkickatTillHRSystemetDatum === undefined
        ? undefined
        : value.SkickatTillHRSystemetDatum === null
        ? null
        : value.SkickatTillHRSystemetDatum.toISOString(),
  };
}
