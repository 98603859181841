/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ApiErrorMessageHttp,
  ApiErrorMessageHttpFromJSON,
  ApiErrorMessageHttpFromJSONTyped,
  ApiErrorMessageHttpToJSON,
} from './ApiErrorMessageHttp';

/**
 *
 * @export
 * @interface ApiErrorMessage
 */
export interface ApiErrorMessage {
  /**
   *
   * @type {number}
   * @memberof ApiErrorMessage
   */
  Id?: number;
  /**
   *
   * @type {string}
   * @memberof ApiErrorMessage
   */
  Message?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiErrorMessage
   */
  ErrorCode?: string | null;
  /**
   *
   * @type {ApiErrorMessageHttp}
   * @memberof ApiErrorMessage
   */
  Http?: ApiErrorMessageHttp;
}

export function ApiErrorMessageFromJSON(json: any): ApiErrorMessage {
  return ApiErrorMessageFromJSONTyped(json, false);
}

export function ApiErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiErrorMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Id: !exists(json, 'Id') ? undefined : json['Id'],
    Message: !exists(json, 'Message') ? undefined : json['Message'],
    ErrorCode: !exists(json, 'ErrorCode') ? undefined : json['ErrorCode'],
    Http: !exists(json, 'Http') ? undefined : ApiErrorMessageHttpFromJSON(json['Http']),
  };
}

export function ApiErrorMessageToJSON(value?: ApiErrorMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Id: value.Id,
    Message: value.Message,
    ErrorCode: value.ErrorCode,
    Http: ApiErrorMessageHttpToJSON(value.Http),
  };
}
