/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AerendeAktivitetStatusPK,
  AerendeAktivitetStatusPKFromJSON,
  AerendeAktivitetStatusPKFromJSONTyped,
  AerendeAktivitetStatusPKToJSON,
} from './AerendeAktivitetStatusPK';
import {
  AerendeAktivitetStatusVO,
  AerendeAktivitetStatusVOFromJSON,
  AerendeAktivitetStatusVOFromJSONTyped,
  AerendeAktivitetStatusVOToJSON,
} from './AerendeAktivitetStatusVO';
import {
  AktivitetStatusActionVO,
  AktivitetStatusActionVOFromJSON,
  AktivitetStatusActionVOFromJSONTyped,
  AktivitetStatusActionVOToJSON,
} from './AktivitetStatusActionVO';

/**
 *
 * @export
 * @interface SaveAerendeAktivitetStatusServiceCmd
 */
export interface SaveAerendeAktivitetStatusServiceCmd {
  /**
   *
   * @type {AerendeAktivitetStatusPK}
   * @memberof SaveAerendeAktivitetStatusServiceCmd
   */
  AerendeAktivitetStatusPK: AerendeAktivitetStatusPK;
  /**
   *
   * @type {AerendeAktivitetStatusVO}
   * @memberof SaveAerendeAktivitetStatusServiceCmd
   */
  AerendeAktivitetStatusVO: AerendeAktivitetStatusVO;
  /**
   *
   * @type {AktivitetStatusActionVO}
   * @memberof SaveAerendeAktivitetStatusServiceCmd
   */
  AktivitetStatusActionVO: AktivitetStatusActionVO;
}

export function SaveAerendeAktivitetStatusServiceCmdFromJSON(json: any): SaveAerendeAktivitetStatusServiceCmd {
  return SaveAerendeAktivitetStatusServiceCmdFromJSONTyped(json, false);
}

export function SaveAerendeAktivitetStatusServiceCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SaveAerendeAktivitetStatusServiceCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendeAktivitetStatusPK: AerendeAktivitetStatusPKFromJSON(json['AerendeAktivitetStatusPK']),
    AerendeAktivitetStatusVO: AerendeAktivitetStatusVOFromJSON(json['AerendeAktivitetStatusVO']),
    AktivitetStatusActionVO: AktivitetStatusActionVOFromJSON(json['AktivitetStatusActionVO']),
  };
}

export function SaveAerendeAktivitetStatusServiceCmdToJSON(value?: SaveAerendeAktivitetStatusServiceCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendeAktivitetStatusPK: AerendeAktivitetStatusPKToJSON(value.AerendeAktivitetStatusPK),
    AerendeAktivitetStatusVO: AerendeAktivitetStatusVOToJSON(value.AerendeAktivitetStatusVO),
    AktivitetStatusActionVO: AktivitetStatusActionVOToJSON(value.AktivitetStatusActionVO),
  };
}
