/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ClientCrashStackframe,
  ClientCrashStackframeFromJSON,
  ClientCrashStackframeFromJSONTyped,
  ClientCrashStackframeToJSON,
} from './ClientCrashStackframe';

/**
 *
 * @export
 * @interface ClientCrashErrorMessage
 */
export interface ClientCrashErrorMessage {
  /**
   *
   * @type {string}
   * @memberof ClientCrashErrorMessage
   */
  Message?: string | null;
  /**
   *
   * @type {Array<ClientCrashStackframe>}
   * @memberof ClientCrashErrorMessage
   */
  Stackframes?: Array<ClientCrashStackframe> | null;
  /**
   *
   * @type {string}
   * @memberof ClientCrashErrorMessage
   */
  CurrentUrl?: string | null;
  /**
   *
   * @type {string}
   * @memberof ClientCrashErrorMessage
   */
  ComponentStack?: string | null;
  /**
   *
   * @type {string}
   * @memberof ClientCrashErrorMessage
   */
  UserAgentInfo?: string | null;
  /**
   *
   * @type {string}
   * @memberof ClientCrashErrorMessage
   */
  ErrorCode?: string | null;
}

export function ClientCrashErrorMessageFromJSON(json: any): ClientCrashErrorMessage {
  return ClientCrashErrorMessageFromJSONTyped(json, false);
}

export function ClientCrashErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientCrashErrorMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Message: !exists(json, 'Message') ? undefined : json['Message'],
    Stackframes: !exists(json, 'Stackframes')
      ? undefined
      : json['Stackframes'] === null
      ? null
      : (json['Stackframes'] as Array<any>).map(ClientCrashStackframeFromJSON),
    CurrentUrl: !exists(json, 'CurrentUrl') ? undefined : json['CurrentUrl'],
    ComponentStack: !exists(json, 'ComponentStack') ? undefined : json['ComponentStack'],
    UserAgentInfo: !exists(json, 'UserAgentInfo') ? undefined : json['UserAgentInfo'],
    ErrorCode: !exists(json, 'ErrorCode') ? undefined : json['ErrorCode'],
  };
}

export function ClientCrashErrorMessageToJSON(value?: ClientCrashErrorMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Message: value.Message,
    Stackframes:
      value.Stackframes === undefined
        ? undefined
        : value.Stackframes === null
        ? null
        : (value.Stackframes as Array<any>).map(ClientCrashStackframeToJSON),
    CurrentUrl: value.CurrentUrl,
    ComponentStack: value.ComponentStack,
    UserAgentInfo: value.UserAgentInfo,
    ErrorCode: value.ErrorCode,
  };
}
