import {
  CommandFunctions,
  createWebCommandList,
  RemoteDataFunctions,
  WebCommandListState,
} from '@decernointernal/websd.shared';
import { selectUserQry } from 'components/user/qryUser';
import { AerendeMedarbetareKontorPK } from 'generated-models/anstallningsportalen/models/AerendeMedarbetareKontorPK';
import { CreateAerendeMedarbetareKontorCmd } from 'generated-models/anstallningsportalen/models/CreateAerendeMedarbetareKontorCmd';
import ICreateAerendeMedarbetareKontorCmdIntegrationEvent from 'models/ICreateAerendeMedarbetareKontorCmdIntegrationEvent';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import {
  createCustomIntegrationEvent,
  dispatchIntegrationEvents,
  IntegrationEventDataTypes,
  parseEvent,
} from 'utils/eventDispatcher';
const ActionType = '[Aerende] create AerendeMedarbetareKontor';

const createAerendeMedarbetareKontorCmd = createWebCommandList<typeof ActionType, CreateAerendeMedarbetareKontorCmd>(
  ActionType,
  k => k.PrimaryKey
);

type CreateAerendeMedarbetareKontorCmdState = WebCommandListState<CreateAerendeMedarbetareKontorCmd>;

const map: MapState<CreateAerendeMedarbetareKontorCmdState> = rootState =>
  rootState.main.aerende.aerendeMedarbetare.createAerendeMedarbetareKontor;

export const selectCreateAerendeMedarbetareKontorCmd = (primaryKey: AerendeMedarbetareKontorPK) => (state: RootState) =>
  createAerendeMedarbetareKontorCmd.get(map(state), primaryKey);

export const createAerendeMedarbetareKontorCmdAction =
  (cmd: CreateAerendeMedarbetareKontorCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = createAerendeMedarbetareKontorCmd.get(map(state), cmd.PrimaryKey);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(createAerendeMedarbetareKontorCmd.executing(cmd));

      const result = await new ApiClient().AerendeCmdApi.createAerendeMedarbetareKontor({
        createAerendeMedarbetareKontorCmd: cmd,
      });

      // Create a custom integration event that includes also 'kontorsnamn'
      const event = result.find(e => {
        return e.EventDataType === IntegrationEventDataTypes.AerendeMedarbetareKontor ? e : undefined;
      });
      if (event) {
        const user = selectUserQry()(getState());
        if (RemoteDataFunctions.hasData(user)) {
          const parsedEvent = parseEvent<AerendeMedarbetareKontorPK>(
            event.EventDataType as IntegrationEventDataTypes,
            event
          );
          const prop: ICreateAerendeMedarbetareKontorCmdIntegrationEvent = {
            kontorsnamn: user.data.Kontor.find(k => k.KontorId === parsedEvent.eventDataKey.KontorId)?.KontorNamn ?? '',
          };

          const customEvent = createCustomIntegrationEvent<ICreateAerendeMedarbetareKontorCmdIntegrationEvent>(
            IntegrationEventDataTypes.CreateAerendeMedarbetareKontor,
            prop,
            parsedEvent.eventDataKey,
            'AerendeMedarbetareKontorPK'
          );

          // Create custom integration event
          result.push(customEvent);
        }
      }

      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(createAerendeMedarbetareKontorCmd.success(cmd));
    } catch (err) {
      dispatch(createAerendeMedarbetareKontorCmd.failure(cmd, await parseErrorResponse(err)));
    }
  };

export const removeAerendeMedarbetareKontorCmdAction =
  (cmd: CreateAerendeMedarbetareKontorCmd): ThunkAction =>
  async dispatch => {
    // Remove command from commandList
    dispatch(createAerendeMedarbetareKontorCmd.remove(cmd));
  };

export const createAerendeMedarbetareKontorReducer = createAerendeMedarbetareKontorCmd.reducer;
