/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AerendemallTypVO
 */
export interface AerendemallTypVO {
  /**
   *
   * @type {number}
   * @memberof AerendemallTypVO
   */
  AerendemallTyp?: number | null;
}

export function AerendemallTypVOFromJSON(json: any): AerendemallTypVO {
  return AerendemallTypVOFromJSONTyped(json, false);
}

export function AerendemallTypVOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AerendemallTypVO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendemallTyp: !exists(json, 'AerendemallTyp') ? undefined : json['AerendemallTyp'],
  };
}

export function AerendemallTypVOToJSON(value?: AerendemallTypVO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendemallTyp: value.AerendemallTyp,
  };
}
