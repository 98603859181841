/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { AerendemallVO, AerendemallVOFromJSON, AerendemallVOFromJSONTyped, AerendemallVOToJSON } from './AerendemallVO';
import { KoenVO, KoenVOFromJSON, KoenVOFromJSONTyped, KoenVOToJSON } from './KoenVO';
import {
  MedarbetareRollVO,
  MedarbetareRollVOFromJSON,
  MedarbetareRollVOFromJSONTyped,
  MedarbetareRollVOToJSON,
} from './MedarbetareRollVO';
import {
  PersonnummerVO,
  PersonnummerVOFromJSON,
  PersonnummerVOFromJSONTyped,
  PersonnummerVOToJSON,
} from './PersonnummerVO';

/**
 *
 * @export
 * @interface CreateAerendeServiceCmd
 */
export interface CreateAerendeServiceCmd {
  /**
   *
   * @type {AerendemallVO}
   * @memberof CreateAerendeServiceCmd
   */
  AerendemallVO: AerendemallVO;
  /**
   *
   * @type {Date}
   * @memberof CreateAerendeServiceCmd
   */
  Startdatum: Date;
  /**
   *
   * @type {Date}
   * @memberof CreateAerendeServiceCmd
   */
  Slutdatum?: Date | null;
  /**
   *
   * @type {string}
   * @memberof CreateAerendeServiceCmd
   */
  Foernamn: string;
  /**
   *
   * @type {string}
   * @memberof CreateAerendeServiceCmd
   */
  Efternamn: string;
  /**
   *
   * @type {PersonnummerVO}
   * @memberof CreateAerendeServiceCmd
   */
  Personnummer?: PersonnummerVO;
  /**
   *
   * @type {MedarbetareRollVO}
   * @memberof CreateAerendeServiceCmd
   */
  MedarbetareRollVO: MedarbetareRollVO;
  /**
   *
   * @type {KoenVO}
   * @memberof CreateAerendeServiceCmd
   */
  Koen?: KoenVO;
  /**
   *
   * @type {number}
   * @memberof CreateAerendeServiceCmd
   */
  NationalitetId?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateAerendeServiceCmd
   */
  Foedelsedatum?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateAerendeServiceCmd
   */
  KontorId: number;
  /**
   *
   * @type {string}
   * @memberof CreateAerendeServiceCmd
   */
  NationelltIDNummer?: string | null;
}

export function CreateAerendeServiceCmdFromJSON(json: any): CreateAerendeServiceCmd {
  return CreateAerendeServiceCmdFromJSONTyped(json, false);
}

export function CreateAerendeServiceCmdFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAerendeServiceCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendemallVO: AerendemallVOFromJSON(json['AerendemallVO']),
    Startdatum: new Date(json['Startdatum']),
    Slutdatum: !exists(json, 'Slutdatum') ? undefined : json['Slutdatum'] === null ? null : new Date(json['Slutdatum']),
    Foernamn: json['Foernamn'],
    Efternamn: json['Efternamn'],
    Personnummer: !exists(json, 'Personnummer') ? undefined : PersonnummerVOFromJSON(json['Personnummer']),
    MedarbetareRollVO: MedarbetareRollVOFromJSON(json['MedarbetareRollVO']),
    Koen: !exists(json, 'Koen') ? undefined : KoenVOFromJSON(json['Koen']),
    NationalitetId: !exists(json, 'NationalitetId') ? undefined : json['NationalitetId'],
    Foedelsedatum: !exists(json, 'Foedelsedatum') ? undefined : json['Foedelsedatum'],
    KontorId: json['KontorId'],
    NationelltIDNummer: !exists(json, 'NationelltIDNummer') ? undefined : json['NationelltIDNummer'],
  };
}

export function CreateAerendeServiceCmdToJSON(value?: CreateAerendeServiceCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendemallVO: AerendemallVOToJSON(value.AerendemallVO),
    Startdatum: value.Startdatum.toISOString(),
    Slutdatum:
      value.Slutdatum === undefined ? undefined : value.Slutdatum === null ? null : value.Slutdatum.toISOString(),
    Foernamn: value.Foernamn,
    Efternamn: value.Efternamn,
    Personnummer: PersonnummerVOToJSON(value.Personnummer),
    MedarbetareRollVO: MedarbetareRollVOToJSON(value.MedarbetareRollVO),
    Koen: KoenVOToJSON(value.Koen),
    NationalitetId: value.NationalitetId,
    Foedelsedatum: value.Foedelsedatum,
    KontorId: value.KontorId,
    NationelltIDNummer: value.NationelltIDNummer,
  };
}
