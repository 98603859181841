import { FbButton, FbCollapsible, FbSpinner } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { MailToWrapper } from 'components/MailToWrapper';
import React from 'react';
import { useSelector } from 'react-redux';
import InstruktionsfilmIkon from 'resources/instruktionsfilmIkon.png';
import { selectUserQry } from '../user/qryUser';
import { AerendeList } from './aerendeList/AerendeList';

export const Home: React.FC = () => {
  const userQry = useSelector(selectUserQry());

  const testaMejlAction =
    'mailto:?subject=Testar%20mejl%20fr%C3%A5n%20Anst%C3%A4llningsportalen&body=Detta%20mejl%20har%20skapats%20av%20Anst%C3%A4llningportalen.%20Det%20%C3%B6ppnas%20automatiskt%20i%20det%20mejlprogram%20som%20%C3%A4r%20inst%C3%A4llt%20som%20standard%20p%C3%A5%20din%20dator.%0AB%C3%A4st%20%C3%A4r%20om%20mejlet%20%C3%B6ppnas%20i%20Outlook.%20Du%20kan%20%C3%A4ndra%20den%20inst%C3%A4llningen%20sj%C3%A4lv.%20P%C3%A5%20Byr%C3%A5l%C3%A5dan%20finns%20en%20guide.%0AF%C3%B6r%20att%20f%C3%A5%20med%20din%20signatur%20st%C3%A4ller%20du%20mark%C3%B6ren%20l%C3%A4ngst%20ner%20i%20detta%20mejl%20och%20klickar%20p%C3%A5%20Signatur%20i%20sektionen%20Ta%20med%20ovanf%C3%B6r.';
  if (!RemoteDataFunctions.hasData(userQry)) {
    return (
      <div className="w-full p-8 text-center">
        <FbSpinner size="large" />
      </div>
    );
  }

  return RemoteDataFunctions.hasData(userQry) ? (
    <>
      <div className="p-4 overflow-y-auto h-screen">
        <FbCollapsible
          headingTitle="Välkommen till Anställningsportalen"
          isDefaultCollapsed={false}
          isCollapsible={true}
        >
          <div className="grid grid-cols-3 gap-4">
            <div className="w-full max-w-[22.5rem]">
              <p>
                I sektionen nedan kan du skapa nytt ärende och se ditt kontors pågående ärenden.
                <br />
                <br />
                Har du frågor kan du vända dig till HR-support:
                <br />
                <br />
                <a href="mailto:hrsupport@fastighetsbyran.se">Mejla till HR-support</a>
              </p>
            </div>
            <div className="w-5/12">
              <a
                href="https://byraladan.fastighetsbyran.se/contentassets/1e3ac66ba14641dabe4de5841ea99235/introduktion-ap.mp4"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="länk till instruktionsfilm" src={InstruktionsfilmIkon}></img>
              </a>
            </div>
            <div className="w-full max-w-[22.5rem]">
              <h3 className="mb-2">Hjälpmedel</h3>
              <a
                href="https://byraladan.fastighetsbyran.se/verktyg/anstallningsportalen/mejlinstallningar/"
                target="_blank"
                rel="noreferrer"
              >
                Guide för mejlinställning
              </a>
              <div className="mt-3">
                <MailToWrapper actionUrl={testaMejlAction} done={false}>
                  <FbButton type="primary" text="Testa mejlinställning" icon={faEnvelope} />
                </MailToWrapper>
              </div>
            </div>
          </div>
        </FbCollapsible>
        <AerendeList />
      </div>
    </>
  ) : (
    <></>
  );
};
