/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { AerendePK, AerendePKFromJSON, AerendePKFromJSONTyped, AerendePKToJSON } from './AerendePK';
import {
  ChecklistaListDTO,
  ChecklistaListDTOFromJSON,
  ChecklistaListDTOFromJSONTyped,
  ChecklistaListDTOToJSON,
} from './ChecklistaListDTO';

/**
 *
 * @export
 * @interface AerendeChecklistorDTO
 */
export interface AerendeChecklistorDTO {
  /**
   *
   * @type {AerendePK}
   * @memberof AerendeChecklistorDTO
   */
  AerendePK: AerendePK;
  /**
   *
   * @type {Array<ChecklistaListDTO>}
   * @memberof AerendeChecklistorDTO
   */
  ChecklistaList: Array<ChecklistaListDTO>;
}

export function AerendeChecklistorDTOFromJSON(json: any): AerendeChecklistorDTO {
  return AerendeChecklistorDTOFromJSONTyped(json, false);
}

export function AerendeChecklistorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AerendeChecklistorDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AerendePK: AerendePKFromJSON(json['AerendePK']),
    ChecklistaList: (json['ChecklistaList'] as Array<any>).map(ChecklistaListDTOFromJSON),
  };
}

export function AerendeChecklistorDTOToJSON(value?: AerendeChecklistorDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AerendePK: AerendePKToJSON(value.AerendePK),
    ChecklistaList: (value.ChecklistaList as Array<any>).map(ChecklistaListDTOToJSON),
  };
}
