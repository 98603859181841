/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { LandPK, LandPKFromJSON, LandPKFromJSONTyped, LandPKToJSON } from './LandPK';

/**
 *
 * @export
 * @interface LandListDTO
 */
export interface LandListDTO {
  /**
   *
   * @type {LandPK}
   * @memberof LandListDTO
   */
  LandPK: LandPK;
  /**
   *
   * @type {string}
   * @memberof LandListDTO
   */
  LandNamn: string;
}

export function LandListDTOFromJSON(json: any): LandListDTO {
  return LandListDTOFromJSONTyped(json, false);
}

export function LandListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    LandPK: LandPKFromJSON(json['LandPK']),
    LandNamn: json['LandNamn'],
  };
}

export function LandListDTOToJSON(value?: LandListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    LandPK: LandPKToJSON(value.LandPK),
    LandNamn: value.LandNamn,
  };
}
