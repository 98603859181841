export enum aerendeMallNyAnstaellning {
  ONBOARDING_AV_FASTIGHETSMAEKLARE = 1,
  ONBOARDING_AV_BLIVANDE_MAEKLARE = 3,
  FEI_MEDARBETARE = 4,
  ONBOARDING_AV_KOORDINATOR = 5,
  ONBOARDING_AV_MAEKLARPRAKTIKANT = 6,
  ONBOARDING_AV_HK_MEDARBETARE = 7,
  NYANSTAELLNING_UTLAND = 30,
}

export enum aerendeMallAvslut {
  MEDARBETARE_SLUTAR = 2,
  OEVRIGA_SLUTAR = 20,
  UTLAND_SLUTAR = 40,
}
