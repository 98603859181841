import {
  createWebDataSingletonList,
  ErrorMessage,
  RemoteData,
  RemoteDataFunctions,
  RemoteDataSingeltonList,
  WebDataListState,
} from '@decernointernal/websd.shared';
import { AerendeListDTO } from 'generated-models/anstallningsportalen/models/AerendeListDTO';
import { AnyAction, Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType, webDataSetStale } from 'utils/eventDispatcher';
const ActionTypeAerendeList = '[aerende] Get aerendelist';

const aerendeListQry = createWebDataSingletonList<typeof ActionTypeAerendeList, AerendeListDTO[]>(
  ActionTypeAerendeList,
  'aerendelist'
);

type AerendeListQryState = WebDataListState<AerendeListDTO[]>;

const mapAerendeListQryState: MapState<AerendeListQryState> = rootState =>
  rootState.main.home.aerendeList.qryAerendeList;

export const selectAerendeListQry = () => (state: RootState) => aerendeListQry.get(mapAerendeListQryState(state));

export const aerendeListQryAction = (): ThunkAction => async (dispatch, getState) => {
  const webData = aerendeListQry.get(mapAerendeListQryState(getState()));
  aerendeListInternalQryAction(webData, aerendeListQry, dispatch);
};

// export const aerendeListQryReducer = aerendeListQry.reducer;

const aerendeListInternalQryAction = async (
  webData: RemoteData<AerendeListDTO[], ErrorMessage>,
  webSdQry: RemoteDataSingeltonList<string, AerendeListDTO[], string, ErrorMessage>,
  dispatch: (action: AnyAction) => void
) => {
  if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
    return;
  }
  // Call api and handle result
  try {
    if (RemoteDataFunctions.hasData(webData)) {
      dispatch(webSdQry.updating(webData.data));
    } else {
      dispatch(webSdQry.loading());
    }

    const result = await new ApiClient().AerendeQryApi.getAerendeList({});
    dispatch(webSdQry.success(result));
  } catch (error) {
    dispatch(webSdQry.failure(await parseErrorResponse(error)));
  }
};

export const aerendeListQryReducer: Reducer<AerendeListQryState, IntegrationEventAction> = (state = {}, action) => {
  switch (action.type) {
    case IntegrationEventActionType.Aerende:
    case IntegrationEventActionType.AerendeAktivitetStatus: {
      const webData = aerendeListQry.get(state);
      return {
        ...state,
        aerendelist: webDataSetStale(webData),
      };
    }
  }
  return aerendeListQry.reducer(state, action);
};
