import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Roles } from 'components/user/Roles';
import React from 'react';

interface HeaderExpandedProps {
  logoutOnClick: () => void;
  readonly name: string;
  readonly role: string;
}

export const HeaderExpanded: React.FC<HeaderExpandedProps> = ({ logoutOnClick, name, role }) => {
  return (
    <div className={'absolute top-10 w-64 z-50' + (role !== Roles.ANVAENADRE ? ' left-11' : ' left-2')}>
      <div
        className="
          shadow border border-fb-color-grey-medium-dark relative rounded-sm
          before:border-solid before:border-transparent before:content-[''] before:h-0 before:w-0 before:absolute before:pointer-events-none before:border-8  before:bottom-full before:border-b-fb-color-grey-medium-dark before:left-[7px]
          after:border-solid after:border-transparent after:content-[''] after:h-0 after:w-0 after:absolute after:pointer-events-none after:border-[7px] after:bottom-full after:border-b-fb-color-grey-light after:left-2
          "
      >
        <div className="bg-fb-color-grey-light h-14 pl-2 py-2 border-b border-b-fb-color-grey-medium-dark rounded-t-sm">
          <p className="text-xs-increased">Inloggad som</p>
          <p className="font-bold text-xs-increased">{name}</p>
        </div>
        <div
          className="bg-fb-color-white h-8 p-2 flex items-center hover:bg-fb-color-blue-light cursor-pointer rounded-b-sm"
          onClick={() => logoutOnClick()}
        >
          <p className="text-xs-increased" data-testid="headerExpandedLogout-test-id">
            Logga ut
          </p>
          <FontAwesomeIcon className="absolute right-2" size="xs" icon={faRightFromBracket} />
        </div>
      </div>
    </div>
  );
};
