import {
  createWebDataList,
  ErrorMessage,
  RemoteData,
  RemoteDataFunctions,
  RemoteDataList,
  RemoteDataStatus,
  SdKeys,
  WebDataListState,
} from '@decernointernal/websd.shared';
import aerendeAktivitetStatusEnum, { getAerendeAktivitetStatusDomain } from 'domain/aerendeAktivitetStatus';
import { AerendePK, SdDbOperation } from 'generated-models/anstallningsportalen/models';
import { AerendeChecklistorDTO } from 'generated-models/anstallningsportalen/models/AerendeChecklistorDTO';
import { AnyAction, Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { deepClone } from 'utils/deepCloneUtils';
import { IntegrationEventAction, IntegrationEventActionType, webDataSetStale } from 'utils/eventDispatcher';
import { VOCreate } from 'utils/ValueObjectHelpers';
const ActionTypeChecklistor = '[checklista] Get Checklistor';

const checklistorQry = createWebDataList<typeof ActionTypeChecklistor, AerendeChecklistorDTO, AerendePK>(
  ActionTypeChecklistor,
  x => x.AerendePK
);

type AerendeChecklistorState = WebDataListState<AerendeChecklistorDTO>;

const mapChecklistorQryState: MapState<AerendeChecklistorState> = rootState =>
  rootState.main.aerende.checklista.checklistorFoerAerende;

export const selectChecklistorQry = (aerendeId: AerendePK) => (state: RootState) => {
  return checklistorQry.get(mapChecklistorQryState(state), aerendeId);
};

export const checklistorQryAction =
  (aerendeId: AerendePK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = checklistorQry.get(mapChecklistorQryState(getState()), aerendeId);
    checklistorInternalQryAction(webData, checklistorQry, aerendeId, dispatch);
  };

const checklistorInternalQryAction = async (
  webData: RemoteData<AerendeChecklistorDTO, ErrorMessage>,
  webSdQry: RemoteDataList<string, AerendeChecklistorDTO, AerendePK, ErrorMessage>,
  aerendeId: AerendePK,
  dispatch: (action: AnyAction) => void
) => {
  if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
    return;
  }
  // Call api and handle result
  try {
    if (RemoteDataFunctions.hasData(webData)) {
      dispatch(webSdQry.updating(webData.data));
    } else {
      dispatch(webSdQry.loading(aerendeId));
    }

    const result = await new ApiClient().ChecklistApi.getChecklistor({ checklistaDTOQry: aerendeId });
    dispatch(webSdQry.success(result));
  } catch (error) {
    dispatch(webSdQry.failure(aerendeId, await parseErrorResponse(error)));
  }
};

// only update state for the affected checklista items status
export const checklistorQryReducer: Reducer<AerendeChecklistorState, IntegrationEventAction> = (state = {}, action) => {
  switch (action.type) {
    case IntegrationEventActionType.AerendeAktivitetStatus: {
      const aerendePK = VOCreate<AerendePK>({ AerendeId: action.payload.eventData.AerendeId });
      const webData = checklistorQry.get(state, aerendePK);
      if (RemoteDataFunctions.hasData(webData)) {
        //deep clone object from state, becasue we do not want to alter state directly
        const clone: AerendeChecklistorDTO = deepClone(webData.data);
        const currentChecklista = clone.ChecklistaList.find(
          cl => cl.AerendeAktivitetsmallId === action.payload.eventData.AerendeAktivitetsmallId
        );
        if (currentChecklista) {
          if (action.payload.operation === SdDbOperation.Delete) {
            currentChecklista.StatusVO = {};
          } else if (action.payload.eventData.Status) {
            currentChecklista.StatusVO = action.payload.eventData.Status;
          }

          // Uppdatera HarVaerde på obligatoriska aktiviteter, där aktuell aerendeaktivitet återfinns
          clone.ChecklistaList.filter(
            cl =>
              cl.AerendeAktivitetsmallObligatoriskaAktiviteter &&
              cl.AerendeAktivitetsmallObligatoriskaAktiviteter.filter(
                oa => oa.ObligatoriskAerendeAktivitetsmallId === action.payload.eventData.AerendeAktivitetsmallId
              ).forEach(oa => {
                oa.HarVaerde =
                  action.payload.operation === SdDbOperation.Delete
                    ? false
                    : getAerendeAktivitetStatusDomain(action.payload.eventData.Status) ===
                      aerendeAktivitetStatusEnum.AVKLARAD;
              })
          );
        }
        return {
          ...state,
          [SdKeys.keyToString(aerendePK)]: {
            data: clone,
            status: RemoteDataStatus.Success,
          },
        };
      }

      return { ...state };
    }
    case IntegrationEventActionType.AerendeMedarbetare: {
      const aerendePK = VOCreate<AerendePK>({ AerendeId: action.payload.eventDataKey.AerendeId });
      const webData = checklistorQry.get(state, aerendePK);
      if (RemoteDataFunctions.hasData(webData)) {
        return {
          ...state,
          [SdKeys.keyToString(aerendePK)]: webDataSetStale(webData),
        };
      }
      return { ...state };
    }
  }
  return checklistorQry.reducer(state, action);
};
