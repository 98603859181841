import { CommandFunctions, createWebCommandList, WebCommandListState } from '@decernointernal/websd.shared';
import { AerendeMedarbetareKontorPK } from 'generated-models/anstallningsportalen/models/AerendeMedarbetareKontorPK';
import { DeleteAerendeMedarbetareKontorCmd } from 'generated-models/anstallningsportalen/models/DeleteAerendeMedarbetareKontorCmd';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Aerende] delete AerendeMedarbetareKontor';

const deleteAerendeMedarbetareKontorCmd = createWebCommandList<typeof ActionType, DeleteAerendeMedarbetareKontorCmd>(
  ActionType,
  k => k.PrimaryKey
);

type DeleteAerendeMedarbetareKontorCmdState = WebCommandListState<DeleteAerendeMedarbetareKontorCmd>;

const map: MapState<DeleteAerendeMedarbetareKontorCmdState> = rootState =>
  rootState.main.aerende.aerendeMedarbetare.deleteAerendeMedarbetareKontor;

export const selectDeleteAerendeMedarbetareKontorCmd = (state: RootState) => (pk: AerendeMedarbetareKontorPK) =>
  deleteAerendeMedarbetareKontorCmd.get(map(state), pk);

export const deleteAerendeMedarbetareKontorCmdAction =
  (cmd: DeleteAerendeMedarbetareKontorCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = deleteAerendeMedarbetareKontorCmd.get(map(state), cmd.PrimaryKey);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(deleteAerendeMedarbetareKontorCmd.executing(cmd));

      const result = await new ApiClient().AerendeCmdApi.deleteAerendeMedarbetareKontor({
        deleteAerendeMedarbetareKontorCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(deleteAerendeMedarbetareKontorCmd.success(cmd));
      // Remove command from commandList
      dispatch(deleteAerendeMedarbetareKontorCmd.remove(cmd));
    } catch (err) {
      dispatch(deleteAerendeMedarbetareKontorCmd.failure(cmd, await parseErrorResponse(err)));
    }
  };

export const deleteAerendeMedarbetareKontorReducer = deleteAerendeMedarbetareKontorCmd.reducer;
