import { combineReducers } from 'redux';
import { aerendeAktivitetStatusReducer } from './checklista/aktivitetStatus/aerendeAktivitetStatusReducer';
import { checklistaReducer } from './checklista/checklistaReducer';
import { changeAerendeStatusReducer } from './cmdChangeAerendeStatus';
import { maeklarkedjaQryReducer } from './information/qryMaeklarkedja';
import { aerendeMedarbetareReducer } from './information/sektioner/aerendeMedarbetareReducer';
import navigateAwayReducer from './navigateAway/navigateAwayReducer';
import { aerendeQryReducer } from './qryAerende';

export const aerendeReducer = combineReducers({
  qryAerende: aerendeQryReducer,
  changeAerendeStatus: changeAerendeStatusReducer,
  checklista: checklistaReducer,
  aerendeaktivitet: aerendeAktivitetStatusReducer,
  aerendeMedarbetare: aerendeMedarbetareReducer,
  navigateAway: navigateAwayReducer,
  maeklarkedjalist: maeklarkedjaQryReducer,
});
