import { createContext } from 'react';
import INyttAerende from '../INyttAerende';

export interface INyttAerendeContext {
  readonly nyttAerendeState: INyttAerende;
  readonly setNyttAerendeState: React.Dispatch<React.SetStateAction<INyttAerende>>;
}

export const NyttAerendeContext = createContext<INyttAerendeContext | undefined>(undefined);
NyttAerendeContext.displayName = 'NyttAerendeContext';
