import { FbTabs, FbTabsType } from '@decernointernal/fb-interna-komponenter';
import { Checklistor } from 'components/aerende/checklista/Checklistor';
import { AerendePK } from 'generated-models/anstallningsportalen';
import { useDispatch, useSelector } from 'react-redux';
import { LocationActionType, aerendeChecklistorRoute, aerendeInformationRoute } from 'store/location';
import { RootState } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import { AerendeHeader } from './AerendeHeader';
import Information from './information/Information';

export const Aerende: React.FC = () => {
  const payload = useSelector((state: RootState) => state.location.payload);
  const location = useSelector((state: RootState) => state.location);
  const dispatch = useDispatch();

  const aerendePK = VOCreate<AerendePK>({
    AerendeId: parseInt(payload.aerendeId),
  });

  const changeRoute = (index: number) => {
    if (index === 0) {
      dispatch(aerendeInformationRoute(aerendePK));
    } else if (index === 1) {
      dispatch(aerendeChecklistorRoute(aerendePK));
    }
  };

  const getTabs = () => {
    const tabs: FbTabsType[] = [
      {
        headingTitle: 'Information',
        content: <Information aerendePK={aerendePK} />,
        onClick: changeRoute,
      },
      {
        headingTitle: 'Checklistor',
        content: <Checklistor aerendePK={aerendePK} />,
        onClick: changeRoute,
      },
    ];
    return tabs;
  };

  return (
    <>
      <AerendeHeader aerendePK={aerendePK} />
      <FbTabs
        tabs={getTabs()}
        colorScheme={'primary'}
        startIndex={location.type === LocationActionType.AerendeChecklistor ? 1 : 0}
        tabsMenuClassName="shadow-md"
      />
    </>
  );
};
