/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Anstallningsportalen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0+720cc5264c9b8a3f279c3e9f997b39a4e00e0251
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LandPK
 */
export interface LandPK {
  /**
   *
   * @type {number}
   * @memberof LandPK
   */
  LandId?: number | null;
}

export function LandPKFromJSON(json: any): LandPK {
  return LandPKFromJSONTyped(json, false);
}

export function LandPKFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandPK {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    LandId: !exists(json, 'LandId') ? undefined : json['LandId'],
  };
}

export function LandPKToJSON(value?: LandPK | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    LandId: value.LandId,
  };
}
