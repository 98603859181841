import { Action } from '@reduxjs/toolkit';
import { ICanLeave, showModal } from 'components/aerende/navigateAway/navigateAwayReducer';
import store, { RootState } from 'store/store';

export function navigateAwayMiddleware() {
  return (next: any) => (action: Action) => {
    switch (action.type) {
      case 'autosave':
        const state: RootState = store.getState();
        const fields = state.main.aerende.navigateAway.fields;
        if (fields.length > 0) {
          const iCanLeave = action as ICanLeave;

          // Spara alla fält som validerar
          fields
            .filter(f => f.isValid && !!f.action)
            .forEach(f => {
              if (f.action) {
                store.dispatch(f.action);
              }
            });

          if (fields.some(f => !f.isValid)) {
            // Visa modal om det är fält som inte validerar
            store.dispatch(showModal({ canLeave: iCanLeave.canLeave, message: iCanLeave.message }));
          } else if (iCanLeave.canLeave) {
            // Anropa för att route-ändringen ska dispatchas
            iCanLeave.canLeave(true);
          }
        }
    }
    return next(action);
  };
}
