import { NotifikationNivaa } from '@decernointernal/fb-interna-komponenter';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export interface Notifikation {
  titel?: string;
  text?: string[];
  errorCode?: string;
  nivaa: NotifikationNivaa;
}

export interface OverlayState {
  NotifikationList: Notifikation[];
}

const initialState: OverlayState = {
  NotifikationList: [],
};

export const FbOverlaySlice = createSlice({
  name: 'FbOverlay',
  initialState,
  reducers: {
    addNotifikation: (state, action: PayloadAction<Notifikation>) => {
      state.NotifikationList.unshift(action.payload);
    },
    removeNotifikation: (state, action: PayloadAction<number>) => {
      state.NotifikationList.splice(action.payload, 1);
    },
  },
});

// Action creators are generated for each case reducer function
export const { addNotifikation, removeNotifikation } = FbOverlaySlice.actions;

export const selectNotifikationList = (state: RootState) => state.FbOverlay.NotifikationList;

export default FbOverlaySlice.reducer;
