import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import aerendemallTypEnum from 'domain/aerendemallTyp';
import { AerendemallTypVO } from 'generated-models/anstallningsportalen';
import { FC } from 'react';

interface IInstruktionProps {
  readonly aerendemallTyp: AerendemallTypVO;
}

export const Instruktion: FC<IInstruktionProps> = ({ aerendemallTyp }) => {
  const texter =
    aerendemallTyp.AerendemallTyp === aerendemallTypEnum.NY
      ? [
          'De uppgifter du fyller i här skickas in i HR-systemet när du beställer kontot',
          'De flesta fält är frivilliga att fylla i',
          'Du kan växla mellan flikarna Information och Checklistor',
          'Du kan fylla på mer senare',
          'Allting sparas automatiskt',
        ]
      : aerendemallTyp.AerendemallTyp === aerendemallTypEnum.AVSLUT
      ? [
          'De uppgifter du fyller i här skickas in i HR-systemet när du bekräftar medarbetarens sista arbetsdag',
          'Du kan växla mellan flikarna Information och Checklistor',
          'Allting sparas automatiskt',
        ]
      : [];
  return (
    <div className="">
      <h3>Bra att veta!</h3>
      <ul className="font-sans text-sm list-none">
        {texter.map((text, index) => (
          <li key={index} className="flex items-center m-2">
            <FontAwesomeIcon icon={faCircle} className="text-[4px] mr-2" />
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
};
