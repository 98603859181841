import { clickOutside } from '@decernointernal/fb-interna-komponenter';
import React from 'react';
import { useDispatch } from 'react-redux';
import Fallbackbild from 'resources/Fallbackbild.svg';
import { homeRoute } from 'store/location';
import { HeaderExpanded } from './headerExpanded/HeaderExpanded';

interface HeaderProps {
  logoutOnClick: () => void;
  readonly name?: string;
  readonly avatarImageUrl?: string;
  readonly role?: string;
}

export const Header: React.FC<HeaderProps> = ({ logoutOnClick, avatarImageUrl, name, role }) => {
  const iconRef = React.useRef(null) as React.MutableRefObject<any | null>;
  const dispatch = useDispatch();

  const {
    ref: headerRef,
    isComponentVisible: isHeaderExpanded,
    setIsComponentVisible: setIsHeaderExpanded,
  } = clickOutside(false, [iconRef]);

  return (
    <>
      <div className="bg-fb-color-white w-full h-10 sticky top-0 shadow-md z-[1000]">
        {role && name && (
          <div className="flex">
            <div className="py-1 pl-2" ref={headerRef}>
              <img
                src={avatarImageUrl ?? Fallbackbild}
                alt={''}
                className={'h-8 w-8 rounded-2xl cursor-pointer'}
                onClick={() => setIsHeaderExpanded(!isHeaderExpanded)}
                data-testid="header-test-id"
              ></img>
              {isHeaderExpanded && role && <HeaderExpanded logoutOnClick={logoutOnClick} name={name} role={role} />}
            </div>
            <h4 className="flex items-center pl-2 cursor-pointer" onClick={() => dispatch(homeRoute())}>
              Anställningsportalen
            </h4>
          </div>
        )}
      </div>
    </>
  );
};
