import { combineReducers } from 'redux';
import { createAerendeMedarbetareKontorReducer } from './cmdCreateAerendeMedarbetareKontor';
import { deleteAerendeMedarbetareKontorReducer } from './cmdDeleteAerendeMedarbetareKontor';
import { updateAerendeMedarbetarePropReducer } from './cmdUpdateAerendeMedarbetareProp';

export const aerendeMedarbetareReducer = combineReducers({
  createAerendeMedarbetareKontor: createAerendeMedarbetareKontorReducer,
  deleteAerendeMedarbetareKontor: deleteAerendeMedarbetareKontorReducer,
  updateAerendeMedarbetareProp: updateAerendeMedarbetarePropReducer,
});
